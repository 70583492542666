const prettifyPrompt = (prompt: string): string => {
  return prompt
    .replaceAll('>', '&gt;')
    .replaceAll('<', '&lt;')
    .replaceAll('\n', '<br />')
    .replaceAll(/&lt;\S*?[^_]&gt;/g, (str) => `<span style="color: red;">${str}</span>`)
    .replaceAll(/&lt;!--[\S\s]*?--&gt;/g, (str) => `<span style="color: grey;">${str}</span>`)
    .replaceAll(/https[^\n\r<\s,]+/g, (str) => `<a href=${str}>${str}</a>`)
    .replaceAll(/#+\s[^\n\r<]+/g, (str) => `<span style="font-weight: bold;">${str}</span>`);
};

export default prettifyPrompt;
