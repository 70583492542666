import * as React from 'react';

import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Typography from '@mui/material/Typography';

type SimpleMetricsProps = {
  type: string;
  data: number;
  percentage?: boolean;
};

const SimpleMetrics: React.FC<SimpleMetricsProps> = ({ type, data, percentage }) => {
  let displayData;
  if (data < 0) {
    displayData = 'No hay datos en el período';
  } else if (data % 1 !== 0 && !percentage) {
    displayData = data.toFixed(2);
  } else if (data % 1 !== 0 && percentage) {
    displayData = `${(data * 100).toFixed(1)}%`;
  } else {
    displayData = data;
  }

  return (
    <Card>
      <CardContent>
        <Typography component="h2" variant="h6" color="text.primary">
          {type}
        </Typography>
        <br />
        <Typography textAlign="center" sx={{ color: 'text.secondary', fontSize: '50px', fontWeight: 'medium' }}>
          {displayData}
        </Typography>
      </CardContent>
    </Card>
  );
};

SimpleMetrics.defaultProps = {
  percentage: false,
};

export default SimpleMetrics;
