import {
  AccessTime,
  AccountTree,
  AccountBalance,
  AccountBalanceWallet,
  AccountCircle,
  Add,
  AdminPanelSettings,
  Apartment,
  ArrowBackIosNew,
  ArrowCircleRight,
  ArrowForwardIos,
  Article,
  AttachMoney,
  Attractions,
  Cached,
  Calculate,
  Check,
  ChildCare,
  Close,
  ContactMail,
  ContactPhone,
  ContactSupport,
  CreditCard,
  Dashboard,
  Delete,
  DirectionsCar,
  DuoRounded,
  EastRounded,
  Edit,
  Elderly,
  Email,
  ExpandLess,
  ExpandMore,
  Facebook,
  FactCheck,
  FileUpload,
  FireExtinguisher,
  Flight,
  FormatListNumbered,
  Group,
  Handshake,
  Help,
  Home,
  House,
  Instagram,
  Key,
  Keyboard,
  LinkedIn,
  ListAlt,
  LocalHospital,
  LocalMall,
  LockOutlined,
  Logout,
  Menu,
  MenuBook,
  Message,
  MonitorHeart,
  MoreVert,
  NorthEastRounded,
  Notifications,
  NotificationsNone,
  Person,
  Phone,
  PostAdd,
  PriceCheck,
  Quiz,
  Redeem,
  RemoveRedEye,
  Report,
  Save,
  Savings,
  School,
  Search,
  Send,
  ShoppingCart,
  SouthEastRounded,
  SouthWestRounded,
  SportsScore,
  Storefront,
  Storage,
  SupportAgent,
  ThumbDown,
  ThumbUp,
  TrendingDown,
  TrendingUp,
  Twitter,
  Unsubscribe,
  WhatsApp,
} from '@mui/icons-material';

export const ProfileIcon = Person;
export const MenuIcon = Menu;
export const DashboardIcon = Dashboard;
export const DebtsIcon = AccountBalanceWallet;
export const GoalsIcon = FactCheck;
export const BudgetsIcon = Calculate;
export const ContactIcon = ContactSupport;
export const LogoutIcon = Logout;
export const CloseIcon = Close;
export const BlogIcon = MenuBook;
export const AdminsIcon = AdminPanelSettings;
export const FAQsIcon = Quiz;
export const ChatIcon = Message;
export const SupportIcon = SupportAgent;
export const EmailIcon = Email;
export const WhatsAppIcon = WhatsApp;
export const PostAddIcon = PostAdd;
export const BackIcon = ArrowBackIosNew;
export const ForwardIcon = ArrowForwardIos;
export const SaveIcon = Save;
export const EditIcon = Edit;
export const StoreIcon = Storefront;
export const InstitutionIcon = AccountBalance;
export const DeleteIcon = Delete;
export const LinkedInIcon = LinkedIn;
export const FacebookIcon = Facebook;
export const InstagramIcon = Instagram;
export const TwitterIcon = Twitter;
export const NotificationsIcon = Notifications;
export const NotificationsNoneIcon = NotificationsNone;
export const ProblemIcon = Report;
export const AddIcon = Add;
export const ListIcon = ListAlt;
export const EyeIcon = RemoveRedEye;
export const ClockIcon = AccessTime;
export const HomeIcon = Home;
export const ArticleIcon = Article;
export const EntertainmentIcon = Attractions;
export const ChildIcon = ChildCare;
export const GoalIcon = SportsScore;
export const IncomeIcon = TrendingUp;
export const ExpenseIcon = TrendingDown;
export const SavingsIcon = Savings;
export const ExpandMoreIcon = ExpandMore;
export const ExpandLessIcon = ExpandLess;
export const LikeIcon = ThumbUp;
export const UploadIcon = FileUpload;
export const KeyIcon = Key;
export const KeyboardIcon = Keyboard;
export const HelpIcon = Help;
export const ShoppingIcon = ShoppingCart;
export const TripIcon = Flight;
export const CarIcon = DirectionsCar;
export const RetirementIcon = Elderly;
export const MoreIcon = MoreVert;
export const CheckIcon = Check;
export const ProfileCircleIcon = AccountCircle;
export const LockIcon = LockOutlined;
export const NumberedListIcon = FormatListNumbered;
export const ArrowRightIcon = ArrowCircleRight;
export const EmergenciesIcon = FireExtinguisher;
export const OutwardsIcon = NorthEastRounded;
export const InwardsIcon = SouthWestRounded;
export const RefreshIcon = Cached;
export const MoneyIcon = AttachMoney;
export const PersonalMailIcon = ContactMail;
export const UnsubscribeIcon = Unsubscribe;
export const DislikeIcon = ThumbDown;
export const SearchIcon = Search;
export const SendIcon = Send;
export const MallIcon = LocalMall;
export const HouseIcon = House;
export const HospitalIcon = LocalHospital;
export const CreditCardIcon = CreditCard;
export const SchoolIcon = School;
export const HandshakeIcon = Handshake;
export const DownwardIcon = SouthEastRounded;
export const EqualIcon = EastRounded;
export const PriceCheckIcon = PriceCheck;
export const GiftIcon = Redeem;
export const CompanyIcon = Apartment;
export const ApartmentIcon = Apartment;
export const PhoneIcon = Phone;
export const MonitorHeartIcon = MonitorHeart;
export const VideoCallIcon = DuoRounded;
export const StorageIcon = Storage;
export const GroupIcon = Group;
export const ProjectsIcon = AccountTree;
export const ClientsIcon = ContactPhone;
