import { SignInBody, User } from '../types/user';
import baseApi from './baseApi';

const signUpApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    signInUser: build.mutation({
      query: (body: SignInBody) => ({
        url: '/auth',
        method: 'POST',
        body,
      }),
      transformResponse: (response: User): User => response,
    }),
    getUser: build.query({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
      transformResponse: (response: User): User => response,
    }),
  }),
  overrideExisting: false,
});

export const { useSignInUserMutation, useGetUserQuery } = signUpApi;
