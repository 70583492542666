import { styled, TextField } from '@mui/material';

const RoundedInput = styled(TextField)(({ theme }) => ({
  '& input': { paddingLeft: theme.spacing(2) },
  '& fieldset': {
    borderRadius: '25px',
  },
}));

export default RoundedInput;
