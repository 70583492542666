import React, { useEffect } from 'react';

import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';

import ProjectSelector from 'components/common/ProjectSelector';
import useCurrentUser from 'hooks/useCurrentUser';
import type { Project } from 'services/types/project';

import NewPromptSectionButton from './components/NewPromptSectionButton';
import PromptSectionsForProject from './components/PromptSectionsForProject';

const PromptSections = () => {
  const [projectId, setProjectId] = React.useState<number | null>(null);
  const [refetchFlag, setRefetchFlag] = React.useState<number>(0);
  const currentUser = useCurrentUser();
  useEffect(() => {
    if (currentUser && currentUser.projectId) {
      setProjectId(currentUser.projectId);
    }
  }, [currentUser]);
  return (
    <Box>
      <Box display="flex" justifyContent="space-between" m={2} alignItems="center">
        <Box>
          <Typography variant="h4">Información que contiene Lidz</Typography>
          <Typography variant="subtitle1">
            (Por detrás hay más información disponible no visible en esta tabla)
          </Typography>
        </Box>
        {currentUser?.isAdmin && (
          <Box display="flex" alignItems="center">
            {projectId && (
              <NewPromptSectionButton projectId={projectId} refetch={() => setRefetchFlag(refetchFlag + 1)} />
            )}
            <ProjectSelector
              projectId={projectId}
              onlyMasterProjects
              onSelect={(project: Project | null) => project && setProjectId(project.id)}
            />
          </Box>
        )}
      </Box>
      <Box m={2}>{projectId && <PromptSectionsForProject refetchFlag={refetchFlag} projectId={projectId} />}</Box>
      {!projectId && (
        <Box display="flex" justifyContent="center" alignItems="center" height="50vh">
          <Typography variant="h5">Selecciona un proyecto para ver la información que Lidz tiene disponible</Typography>
        </Box>
      )}
    </Box>
  );
};

export default PromptSections;
