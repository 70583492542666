import React, { useState } from 'react';

import { Box, CircularProgress, IconButton, IconButtonProps, Typography } from '@mui/material';
import Button, { ButtonProps } from '@mui/material/Button';
import { styled } from '@mui/material/styles';

import { AddIcon, CheckIcon } from 'assets/icons';

const PrimaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.primary.main,
  backgroundColor: theme.palette.primary.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  paddingRight: '12px',
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.primary.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const SecondaryBtn = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.secondary.contrastText,
  backgroundColor: theme.palette.secondary.main,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.secondary.contrastText,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

interface BtnProp extends ButtonProps {
  icon?: React.ReactNode;
  verb?: string;
  text: string;
}

const SectionHeaderBtn: React.FC<BtnProp> = ({ icon, verb, text, ...props }) => {
  return (
    <PrimaryBtn {...props}>
      {icon}
      {verb}
      <Typography variant="inherit" sx={{ display: { xs: 'none', lg: 'block' }, ml: 0.8 }}>
        {text}
      </Typography>
    </PrimaryBtn>
  );
};

SectionHeaderBtn.defaultProps = {
  verb: 'Agregar',
  icon: <AddIcon />,
};

const EditBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const EditBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.warning.main,
  backgroundColor: theme.palette.warning.light,
  borderRadius: '50px',
  paddingLeft: '16px',
  paddingRight: '16px',
  '&:hover': {
    backgroundColor: theme.palette.warning.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  [theme.breakpoints.up('lg')]: {
    borderRadius: '20px',
  },
}));

const DeleteBtn = styled(IconButton)<IconButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  '&:hover': {
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
}));

const DeleteBtnLarge = styled(Button)<ButtonProps>(({ theme }) => ({
  color: theme.palette.error.main,
  backgroundColor: theme.palette.error.light,
  boxShadow: 'none',
  transition: 'all 0.15s ease-in-out',
  borderRadius: '20px',
  height: '43px',
  minWidth: '125px',
  fontSize: '15px',
  lineHeight: '22px',
  fontWeight: 600,
  textTransform: 'uppercase',
  '&:disabled': {
    backgroundColor: '#F3F3F5',
    color: '#CDCDCD',
  },
  '&:hover': {
    boxShadow: 'none',
    backgroundColor: theme.palette.error.main,
    color: '#fff',
  },
  [theme.breakpoints.up('xl')]: {
    minWidth: '201px',
  },
}));

const CancelBtn = styled(Button)<ButtonProps>(() => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: '1px solid #E7233B',
  color: '#E7233B',
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#FBDADE',
  },
}));

const BlackBtn = styled(Button)<ButtonProps>(() => ({
  borderRadius: '20px',
  padding: '0px 27px',
  lineHeight: '22px',
  backgroundColor: 'transparent',
  border: '1px solid #1C1C3A',
  color: '#1C1C3A',
  height: '43px',
  textTransform: 'uppercase',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '15px',
  '&:hover': {
    backgroundColor: '#EDF1FA',
  },
}));

const StatusButtonStyle = styled(Button)<ButtonProps>(() => ({
  borderRadius: '10px',
  padding: '0px 10px',
  lineHeight: '15px',
  height: '30px',
  textTransform: 'none',
  fontWeight: 600,
  transition: 'all 0.15s ease-in-out',
  fontSize: '12px',
  width: '100%',
  boxShadow: 'none',
  textAlign: 'left',
  margin: '0px 5px',
}));

interface StatusButtonProps {
  text: string;
  onClick: () => void;
  color: 'inherit' | 'primary' | 'secondary' | 'success' | 'error' | 'info' | 'warning';
  value: number;
}
const StatusButton: React.FC<StatusButtonProps> = ({ text, onClick, color, value, ...props }) => {
  const [isOnHover, setIsOnHover] = useState(false);
  return (
    <StatusButtonStyle
      {...props}
      variant="contained"
      onClick={onClick}
      color={color}
      sx={{
        bgcolor: value === 100 ? `${color}.main` : `${color}.light`,
        color: value === 100 ? `${color}.contrastText` : `${color}.main`,
        display: 'flex',
        justifyContent: 'flex-start',
        '&:hover': {
          backgroundColor: value === 100 ? `${color}.light` : `${color}.main`,
          color: value === 100 ? `${color}.contrastText` : '#fff',
        },
        textTransform: 'uppercase',
        fontWeight: 400,
        // if value is 100, then make it twinkling
        ...(value === 100 && {
          animation: 'twinkling 1.5s ease-in-out infinite',
        }),
        // set twinkling animation
        '@keyframes twinkling': {
          '0%': {
            opacity: 0.7,
          },
          '50%': {
            opacity: 1,
            boxShadow:
              '0px 2px 4px -1px rgba(0,0,0,0.2), 0px 4px 5px 0px rgba(0,0,0,0.14), 0px 1px 10px 0px rgba(0,0,0,0.12)',
          },
          '100%': {
            opacity: 0.7,
          },
        },
      }}
      onMouseEnter={() => setIsOnHover(true)}
      onMouseLeave={() => setIsOnHover(false)}
    >
      {value < 100 ? (
        <Box
          sx={{
            position: 'relative',
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 0.5,
          }}
        >
          {/* foreground progress */}
          <CircularProgress
            thickness={7}
            color={color}
            size={15}
            value={value}
            variant="determinate"
            sx={{
              ...(isOnHover && {
                color: '#fff',
              }),
            }}
          />
          {/* background progress */}
          <CircularProgress
            thickness={7}
            size={15}
            variant="determinate"
            value={100}
            color={color}
            sx={{
              position: 'absolute',
              opacity: 0.3,
              ...(isOnHover && {
                color: '#fff',
              }),
              bottom: 0,
              left: 0,
            }}
          />
        </Box>
      ) : (
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            mr: 0.5,
            borderRadius: '50%',
            border: '2px solid',
            borderColor: `${color}.contrastText`,
          }}
        >
          <CheckIcon sx={{ fontSize: '15px' }} />
        </Box>
      )}
      {text}
    </StatusButtonStyle>
  );
};

export default PrimaryBtn;
export {
  PrimaryBtn,
  SecondaryBtn,
  EditBtn,
  DeleteBtn,
  CancelBtn,
  BlackBtn,
  EditBtnLarge,
  DeleteBtnLarge,
  SectionHeaderBtn,
  StatusButton,
  StatusButtonStyle,
};
