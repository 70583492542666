import { User } from '../types/user';
import baseApi from './baseApi';

const usersApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getUsers: build.query({
      query: () => ({
        url: '/admin/users',
      }),
      transformResponse: (response: User[]): User[] => response,
    }),
    getUser: build.query({
      query: (id: number) => ({
        url: `/admin/users/${id}`,
      }),
      transformResponse: (response: User): User => response,
    }),
  }),
});

export const { useGetUsersQuery, useGetUserQuery } = usersApi;
