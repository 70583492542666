import React, { FC, useEffect, useRef, useState } from 'react';

import DownloadIcon from '@mui/icons-material/Download';
import InsertDriveFileIcon from '@mui/icons-material/InsertDriveFile';
import Send from '@mui/icons-material/Send';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Grid from '@mui/material/Grid';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { CheckIcon, ClockIcon } from 'assets/icons';
import useCurrentUser from 'hooks/useCurrentUser';
import { usePostMessageMutation } from 'services/api/clients';
import { Message } from 'services/types/message';
import prettifyMessage from 'utils/prettifyMessage';

import TemplateFormModal from './TemplateFormModal';

const filterOutStatuses = ['neverSent', 'deleted', 'discarded'];

interface Props {
  messages: Message[];
  refetch: () => void;
  clientId: number;
  isFilteredConversation: boolean;
  fullHeight?: boolean;
  isClientView?: boolean;
}

const Chat: FC<Props> = ({
  messages: unsortedMessages,
  refetch,
  clientId,
  isFilteredConversation,
  fullHeight,
  isClientView,
}) => {
  const currentUser = useCurrentUser();
  const [messageToSend, setMessageToSend] = useState('');
  const [postMessageMutation, { data: newMessage }] = usePostMessageMutation();

  let messages = unsortedMessages.map((x) => x);
  messages.sort((a, b) => Number(a.id) - Number(b.id));
  if (!currentUser?.isAdmin) {
    messages = messages.filter((message) => message.messageId !== null);
  }
  if (isFilteredConversation) {
    messages = messages.filter((message) => !filterOutStatuses.includes(message.status));
  }
  const yesterday = new Date();
  yesterday.setDate(yesterday.getDate() - 1);
  const active = messages.some((message) => message.role === 'user' && new Date(message.createdAt) > yesterday);

  const messagesEndRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    messagesEndRef.current?.scrollIntoView();
  }, [messages]);

  useEffect(() => {
    if (newMessage) {
      refetch();
    }
  }, [newMessage, refetch]);

  const handleSendMessage = () => {
    if (messageToSend) {
      // eslint-disable-next-line no-console
      postMessageMutation({ clientId, text: messageToSend }).catch(console.error);
      setMessageToSend('');
    }
  };

  const getExtension = (fileUrl: string): string => {
    return fileUrl.toLowerCase().split(';')[0].split('.').at(-1) as string;
  };

  const getSenderName = (message: Message) => {
    if (!currentUser || !currentUser.id) {
      return '';
    }
    if (message.sender === 'system') {
      return 'LIDZ';
    }
    if (message.user) {
      return message.user.name ?? `Usuario ${message.user.id}`;
    }
    if (message.sender !== 'client' && message.sender) {
      return `(${message.sender})`;
    }
    return '';
  };

  const getMessageHTML = (message: Message) => {
    let text = '';
    // if (message.fileUrl) {
    //   text += message.fileUrl; // url
    // }
    // if (message.mediaId) {
    //   text += `-Archivo: ${message.mediaId}`;
    // }
    if (message.role === 'assistant' && message.functionName && message.functionName !== 'getMessagesNextAction') {
      text += `Asistente: ${message.functionName}(${message.functionArguments?.replaceAll('\n', '')})`;
    } else if (message.role === 'function' || message.role === 'tool') {
      text += `Función: ${message.functionName}(${message.functionArguments})\n\nResultado: ${message.text?.replaceAll('\\', '').replace(/(^")|("$)/g, '')}`;
    } else {
      text += message.text ?? '';
    }
    text += '\n';
    if (!currentUser?.isAdmin && message.fileUrl) {
      text = '';
    }

    const isSpecialStatus = ['failed', 'deleted', 'warning', 'neverSent', 'discarded'].includes(message.status);
    const isImageText = message.text?.startsWith('Imagen enviada:');

    return (
      <>
        {['system', 'none'].includes(message.sender) && currentUser?.isAdmin && (
          <div
            style={{
              position: 'relative',
              float: 'right',
              top: '0px',
            }}
            title="Replicar este mensaje en el playground"
          >
            <a
              href={`/playground/${message.id}`}
              style={{ textDecoration: 'none', margin: 0, padding: 0 }}
              target="_blank"
              rel="noreferrer"
            >
              &gt;
            </a>
          </div>
        )}
        {message.fileUrl && ['png', 'jpeg', 'webp', 'jpg'].includes(getExtension(message.fileUrl)) && (
          <img
            src={message.fileUrl}
            alt="user-file"
            style={{ maxWidth: '100%', maxHeight: message.fileUrl.includes('webp') ? '120px' : '250px' }}
          />
        )}
        {text.trim() !== '' && (!text.includes('pdf') || !message.fileUrl) && (
          <Typography
            px={1}
            fontSize={isSpecialStatus ? 14 : undefined}
            sx={{
              whiteSpace: 'pre-line',
              textAlign: 'left',
              color: isImageText || isSpecialStatus ? 'grey' : '#111b21',
              lineHeight: isSpecialStatus ? '1.2em' : '1.5em',
              wordBreak: 'break-word',
            }}
          >
            {prettifyMessage(text)}
          </Typography>
        )}
        {message.fileUrl && getExtension(message.fileUrl) === 'ogg' && (
          // eslint-disable-next-line jsx-a11y/media-has-caption
          <audio controls src={message.fileUrl} />
        )}
        {message.fileUrl && !['png', 'jpeg', 'jpg', 'webp', 'ogg'].includes(getExtension(message.fileUrl)) && (
          <Box
            p={1}
            sx={{
              backgroundColor: message.role === 'user' ? '#f3f5f5' : '#d8f3ce',
              borderRadius: '10px',
            }}
          >
            <Button
              href={message.fileUrl}
              target="_blank"
              rel="noreferrer"
              variant="text"
              color="inherit"
              sx={{ textTransform: 'none', width: '100%', display: 'block' }}
            >
              {getExtension(message.fileUrl) === 'pdf' && (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                    width: '100%',
                    height: '60vh',
                  }}
                >
                  <embed
                    src={`${message.fileUrl}#view=fitH`}
                    type="application/json"
                    style={{ width: '100vw', height: '60vh' }}
                  />
                </div>
              )}
              <Box display="flex" alignItems="center" justifyContent="space-between">
                <Box display="flex" alignItems="center">
                  <InsertDriveFileIcon
                    sx={{
                      height: '1.8em',
                      width: '1.8em',
                    }}
                  />
                  <Stack pr={10} pl={1} textAlign="left">
                    <Typography variant="h4" sx={{ color: '#111b21' }}>
                      {message.text && message.text.includes('Documento enviado')
                        ? message.text
                            .split(':')
                            .pop()
                            ?.replace(/[a-z0-9]*-/g, '')
                        : message.fileUrl.split('/').pop()?.split('document-').pop()}
                    </Typography>
                    <Typography variant="h1" sx={{ color: '#111b21' }} fontSize={15}>
                      {getExtension(message.fileUrl).toLocaleUpperCase()}
                    </Typography>
                  </Stack>
                </Box>
                <DownloadIcon
                  sx={{
                    border: 'solid',
                    borderRadius: '100%',
                    borderWidth: 'thin',
                    opacity: '80%',
                    padding: '2px',
                    height: '1.6em',
                    width: '1.6em',
                  }}
                />
              </Box>
            </Button>
          </Box>
        )}
        <Box display="inline-flex" alignItems="center" color="gray">
          {getSenderName(message) && (
            <Typography variant="h5" pl={1} color={getSenderName(message) === 'LIDZ' ? 'primary' : 'gray'}>
              {getSenderName(message)}
            </Typography>
          )}
          <Typography variant="h5" pl={1} alignItems="center">
            {new Date(message.createdAt).toLocaleTimeString('es-CL', {
              day: 'numeric',
              month: 'numeric',
              year: 'numeric',
              hour: '2-digit',
              minute: '2-digit',
              hour12: false,
            })}
          </Typography>
          {message.status === 'sent' && <CheckIcon />}
          {message.status === 'sent to api' && currentUser && <ClockIcon color="inherit" />}
          {(message.status === 'delivered' || message.status === 'received') && (
            <>
              <CheckIcon sx={{ marginRight: '-16px' }} />
              <CheckIcon />
            </>
          )}
          {currentUser?.isAdmin && message.sender !== 'client' && message.sender !== 'system' && (
            <Typography variant="h5">*</Typography>
          )}
          {message.status === 'read' && (
            <>
              <CheckIcon color="primary" sx={{ marginRight: '-16px' }} />
              <CheckIcon color="primary" />
            </>
          )}
          {isSpecialStatus && <Typography variant="h5">{message.status}</Typography>}
          {currentUser?.isAdmin && !isFilteredConversation && !!message.cost && message.cost > 0 && (
            <Typography variant="h5" pl={1} sx={{ fontSize: '0.6em' }}>
              ${message.cost?.toFixed(2)} {message.AIModel}
            </Typography>
          )}
        </Box>
        {message.emoji && (
          <div
            style={{
              height: '26px',
              width: '30px',
              position: 'relative',
              float: 'right',
              bottom: '-15px',
              backgroundColor: 'white',
              borderRadius: '50px',
              textAlign: 'center',
              border: '1px solid rgba(0,0,0,0.05)',
            }}
          >
            {message.emoji}
          </div>
        )}
        {currentUser?.isAdmin && message.error && (
          <div
            style={{
              position: 'relative',
              float: 'left',
              bottom: '5px',
              backgroundColor: 'white',
              borderRadius: '5px',
              textAlign: 'center',
            }}
          >
            {message.error}
          </div>
        )}
      </>
    );
  };

  const getMessageButtons = (buttons: Message['buttons']) => (
    <Stack py={1} spacing={1} sx={{ marginLeft: '45%' }}>
      {buttons &&
        buttons.map((button) => (
          <Box
            sx={{
              p: '4px',
              backgroundColor: '#d9fdd2',
              borderRadius: '12px',
              boxShadow: '0 1px 0.5px rgba(11,20,26,.13)',
              textAlign: 'center',
            }}
            key={button.title}
          >
            {button.title}
          </Box>
        ))}
    </Stack>
  );

  const chatFooter = () => {
    if (isClientView) {
      return null;
    }
    if (!active) {
      return (
        <Grid display="grid" style={{ paddingTop: '20px' }} justifyContent="center" alignItems="center">
          {messages.length === 0 ? (
            <Typography>No se ha hablado con este usuario</Typography>
          ) : (
            <Typography>Chat no está activo, pasaron más de 24 horas desde el último mensaje del cliente</Typography>
          )}
          <TemplateFormModal clientId={clientId} refetch={refetch} />
        </Grid>
      );
    }
    return (
      <Grid container style={{ padding: '20px', paddingTop: '10px' }} justifyContent="flex-start">
        <TextField
          id="outlined-basic-email"
          label="Mensaje"
          multiline
          placeholder="Hola! ¿Qué necesitas?"
          sx={{
            width: 'calc(100% - 4em)',
            backgroundColor: '#fff',
            borderRadius: '40px',
            opacity: 1,
            mr: 1,
            '& .MuiOutlinedInput-root': { borderRadius: '40px' },
          }}
          value={messageToSend}
          onChange={(e) => setMessageToSend(e.target.value)}
        />
        <IconButton
          sx={{
            backgroundColor: '#05a884',
            height: '2.5em',
            width: '2.5em',
            '&:hover': {
              backgroundColor: '#05a884',
              opacity: 0.6,
            },
            '&:disabled': {
              backgroundColor: '#05a884',
              opacity: 0.3,
            },
          }}
          onClick={handleSendMessage}
        >
          <Send
            sx={{
              color: '#fff',
              height: '1.4em',
              width: '1.4em',
            }}
          />
        </IconButton>
      </Grid>
    );
  };

  return (
    <Box
      sx={{
        backgroundImage: 'url("/whatsapp-background.png")',
        backgroundColor: '#efeae2',
        backgroundRepeat: 'repeat',
        backgroundSize: '412.5px 749.25px',
        width: '100%',
        flex: 1,
        overflow: 'scroll',
        height: fullHeight ? '100vh' : undefined,
      }}
    >
      <Box height="100%" display="flex" flexDirection="column">
        {messages?.map((message) => (
          <Box
            key={message.id}
            m={1}
            mb={0}
            p={1}
            pb={0}
            sx={{
              maxWidth: '80%',
              borderRadius: '12px',
              boxShadow: '0 1px 0.5px rgba(11,20,26,.13)',
              ...((!isClientView && message.role !== 'user') || (message.role === 'user' && isClientView)
                ? {
                    backgroundColor: '#d9fdd2',
                    textAlign: 'end',
                    alignSelf: 'flex-end',
                  }
                : {
                    backgroundColor: '#fff',
                    textAlign: 'start',
                    alignSelf: 'flex-start',
                  }),
            }}
          >
            {getMessageHTML(message)}
            {message.buttons && getMessageButtons(message.buttons)}
          </Box>
        ))}
        {chatFooter()}
        <div ref={messagesEndRef} style={{ paddingBottom: '2px' }} />
      </Box>
    </Box>
  );
};

Chat.defaultProps = {
  fullHeight: true,
  isClientView: false,
};

export default Chat;
