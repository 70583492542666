/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';

const tab = 6; // Margin left for nested lists
const inlineTab = 2; // Margin left for inline nested lists

const TermsConditions: FC = () => {
  return (
    <Box sx={{ px: { xs: 8, xl: 5 } }}>
      <SectionHeader text="Términos y Condiciones" button={null} />

      <Typography align="justify" variant="h6" pb="25px" mt="100px">
        I. TÉRMINOS GENERALES.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        El presente Aviso Legal contiene los Términos y Condiciones Generales (en adelante “Términos y Condiciones”) de
        Contratación de LIDZ SPA (en adelante LIDZ), que detallan los derechos y obligaciones generales entre las partes
        cuando contratan y utilizan cualquier servicio de LIDZ o de sus empresas, lo que constituye un acuerdo legal.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        II. CONCEPTOS CLAVES.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          LIDZ: Se refiere a la empresa prestadora del servicio o a cualquiera de las empresas del grupo.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Cliente: Persona natural o jurídica y las empresas relacionadas cargadas en el Software que contratan los
          servicios de LIDZ.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Contrato: Significa a estos Términos y Condiciones Generales de Contratación, así como su respectiva propuesta
          comercial, anexos y cualquier otro documento modificatorio.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.4)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Implementación: Proceso de acompañamiento y apoyo al Cliente en la puesta en marcha del Software, sus
          funciones y módulos contratados.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.5)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Soporte: Apoyo técnico y asistencia proporcionada al Cliente, a través del área de Satisfacción al Cliente, en
          adelante “SAC”.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.6)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          SaaS: Uso de la Plataforma con la modalidad Software como un Servicio.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.7)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Software: Plataforma y/o plataformas digitales de LIDZ.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.8)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Inicio del Servicio o Kick Off: Corresponde a la fecha de la primera reunión con el jefe de proyecto para dar
          inicio a la Implementación.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.9)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Resumen de Propuesta: Corresponde a la propuesta comercial, sección de su denominación, la cual contiene las
          funcionalidades y módulos del Software contratados por el Cliente y objeto del presente Contrato.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          2.10)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab - 1}>
          Estructura de Precios Propuesta: Corresponde a la propuesta comercial, sección de su denominación, la cual
          contiene la tarifa de los servicios prestados por LIDZ según la cantidad de prospectos procesados en el mes en
          curso.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          2.11)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab - 1}>
          Carta Gantt: Corresponde a una representación escrita o gráfica que define las actividades necesarias para
          completar el trabajo de un proyecto en un plazo determinado, y la relación lógica de ejecución entre ellas.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        Las cláusulas y condiciones específicas correspondientes a cada servicio se detallan en la propuesta comercial
        y/o anexos según fuera el caso de cada cliente, los que, junto a los presentes Términos y Condiciones Generales
        de Contratación, conforman el contrato de servicios que suscribe el Cliente con LIDZ, en adelante “el contrato”.
      </Typography>
      <Typography align="justify" variant="h6" pb="25px">
        III. RELACIÓN CONTRACTUAL, ACEPTACIÓN Y MODIFICACIONES.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        La relación contractual entre LIDZ y el Cliente se perfeccionará tan pronto; el Cliente acepte por medio de un
        enlace o link que se le enviará para estos efectos su intención de hacer uso de los servicios de LIDZ; y, acepte
        los presentes Términos y Condiciones Generales de Contratación y Propuesta Comercial respectiva.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          3.1)
        </Typography>

        <Typography align="justify" variant="body1" pl={inlineTab}>
          Aceptación de las condiciones: La aceptación debe ser expresa y se formaliza al firmar este documento.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        Es fundamental que los datos y la información proporcionada por el Cliente al momento de registrarse sea veraz,
        oportuna, suficiente, fehaciente y actualizada.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Por tanto, es recomendable que el Cliente realice una lectura detallada de los presentes Términos y Condiciones
        previo a la contratación de los servicios ofrecidos por LIDZ.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        En caso de que el Cliente no “Acepte” expresamente los presentes Términos y Condiciones, no se perfeccionará
        relación contractual alguna.
      </Typography>

      <Typography align="justify" variant="body1">
        Mediante la aceptación de los presentes Términos y Condiciones el Cliente manifiesta que:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.1.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Los ha leído y comprendido;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.1.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Se obliga a cumplirlos;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.1.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Reconoce y declara ser mayor de edad y capaz legalmente para obligarse por sí, y en representación de la
          persona jurídica o empresa que representa;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          3.1.4)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Garantiza, de ser el caso, que está debidamente facultado para representar a la persona jurídica o empresa.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Modificaciones y Mantención: LIDZ puede modificar los presentes Términos y Condiciones, en los siguientes
          casos:
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.2.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Para reflejar las alteraciones que se producen en sus servicios o en su forma de hacer negocios; por ejemplo,
          cuando añade funciones, tecnologías y servicios;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          3.2.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Por motivos legales, regulatorios o de seguridad; y
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          3.2.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Para evitar perjuicios o abusos por parte de terceros.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        Si LIDZ modifica de forma sustancial los presentes Términos y Condiciones, se dará aviso al Cliente con la
        debida antelación, dando la oportunidad de revisar los cambios, salvo que se trate de situaciones urgentes, como
        en las que se busca prevenir abusos continuados o responder a requisitos legales.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        En caso de que el Cliente, no esté conforme con los nuevos Términos y Condiciones, deberá solicitar el término
        de los servicios conforme lo expresado en la cláusula VII.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        En caso de mantención de los servicios, LIDZ dará aviso previo si dichas mantenciones pudieran llegar a afectar
        su funcionamiento o causar algún impacto significativo.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        IV. ACCESO Y CONDICIONES DE USO DEL SOFTWARE.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          4.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Acceso: Una vez registrado el Cliente, se creará su cuenta en LIDZ y tendrá acceso a ella. Dicha cuenta será
          personal e intransferible a otra persona natural o jurídica, salvo con autorización expresa de LIDZ.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        LIDZ no tendrá responsabilidad alguna por el uso indebido de la cuenta tanto del Cliente, como de un tercero.
        Tampoco se responsabilizará por la pérdida de información, daños o perjuicios, como consecuencia de
        incumplimiento de las disposiciones de los presentes Términos y Condiciones.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Es responsabilidad exclusiva del Cliente, quién será el titular de la cuenta, custodiar y mantener la
        confidencialidad de sus credenciales de autenticación, contraseñas, como también los demás datos personales que
        se utilicen para su ingreso y que hayan sido registrados. El Cliente se compromete a dar aviso de forma
        inmediata a LIDZ en caso de que llegase a tener cualquier razón para creer que la seguridad de su cuenta ha sido
        comprometida.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          {'  '}
          4.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Uso: El Cliente deberá utilizar los servicios de manera correcta y para fines lícitos. LIDZ se reserva todos
          los derechos que no se hayan otorgado al Cliente de manera explícita en el Contrato.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        {'  '}
        El Software no podrá ser utilizado para ninguna actividad prohibida, que incluye, entre otras:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          {'  '}
          4.2.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Utilizar el Software con el fin de causar un perjuicio a LIDZ, ya sea, con el fin de alentarlo, colapsarlo,
          dañarlo y/o similar;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          4.2.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Actividades delictivas, circulación de virus o cualquier otro archivo dañino, robo o divulgación de derechos y
          secretos industriales o comerciales, infracción de derechos de autor, infracción de patentes;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          4.2.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Para un propósito contrario al orden público, a la moral o buenas costumbres, y en general actuaciones que
          puedan afectar cualquier derecho, pudiendo LIDZ ejercitar las acciones que estime necesarias para corregir o
          impedir dichas actividades;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          4.2.4)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Disponer el Software o materiales derivados a disposición de terceros (a excepción del contenido de propiedad
          del Cliente), salvo que LIDZ lo haya permitido expresamente;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          4.2.5)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Modificar, elaborar, desensamblar, descompilar, o efectuar procesos de ingeniería de reversa;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          4.2.6)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Reproducir, distribuir, sublicenciar, vender, explotar, arrendar, ceder, compartir y/o publicar todo o parte
          del Software y del Servicio a terceros;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          4.2.7)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Intentar obtener acceso no autorizado a cuentas de LIDZ, sistemas o redes informáticas de cualquier otro
          cliente a través de extracción de contraseñas, piratería o cualquier otro medio.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        En general se prohíbe el uso de los servicios cuando se realicen con el fin de perjudicar o dañar a LIDZ o a
        terceros a través de este.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        V. OBJETO DEL CONTRATO Y REQUISITOS DE FUNCIONALIDAD DEL SERVICIO.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          5.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Objeto: En este acto y por el presente instrumento, el Cliente contrata de LIDZ el uso del Software, en
          adelante el “Servicio”, el cual no es transferible a terceros y debe ser usado únicamente por el Cliente
          dentro del giro de su negocio, quedando expresamente prohibida su replicación y/o uso por terceros, salvo
          previa autorización de LIDZ.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        El Servicio contiene las funcionalidades, módulos, addons y servicios adicionales seleccionados por el Cliente
        en el Resumen de Propuesta. Además, incluye:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1" pl={tab}>
          5.1.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          100 GB de almacenamiento de información requerida para el funcionamiento e históricos durante la ejecución del
          contrato. Se generará un cargo mensual de $100 CLP por GB adicional, si el cliente supera los 100 GB incluidos
          en el Servicio.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1" pl={tab}>
          5.1.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Todas las actualizaciones que vayan surgiendo en los módulos y addons contratados durante la ejecución del
          contrato.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          5.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Requisitos para la funcionalidad del servicio: El Cliente debe contar con internet, los dispositivos
          necesarios y requisitos técnicos mínimos para el uso de cualquiera de las Plataformas de LIDZ.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        LIDZ se reserva el derecho de poder recurrir a proveedores y subcontratistas, si fuera necesario, para requerir
        una mejora en el sistema y en los servicios (en el procesamiento, almacenamiento de información, seguridad
        etc.), por lo que es posible que algunos datos del Cliente deban conocerse, almacenarse, organizarse o
        procesarse, etc.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        VI. PRECIO Y FORMA DE PAGO.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          6.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Precio: El servicio tendrá un período de prueba de 30 días sin cobro alguno de parte de Lidz a la Empresa a
          partir del Kick Off, a modo de piloto de prueba. Luego de este período, el precio mensual a pagar por el
          Servicio se encuentra detallado en el Resumen de Propuesta. No obstante, podrá variar mes a mes según la
          cantidad de leads procesados mensualmente y cargados en el Software acorde a la tabla de tarifas contenida en
          la Estructura de Precios Propuesta. En caso de que no corresponda al primer día del mes, el precio a pagar
          será proporcional a los días del mes en los cuales se prestó el Servicio. Se deja expresa constancia que al
          precio del Servicio se debe agregar el correspondiente impuesto que pudiera afectarle.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          6.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Forma de Pago: El Cliente se compromete a pagar el Servicio dentro de los 30 días corridos siguientes a la
          recepción de la factura respectiva. El Cliente debe mantener actualizados sus datos e informar a LIDZ
          oportunamente de cualquier cambio en sus datos de facturación.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        El pago se realizará mediante depósito o transferencia electrónica a:
      </Typography>
      <Typography align="justify" variant="body1" pb="25px" pl={tab}>
        ● Nombre: LIDZ SpA <br />
        ● Rut: 76.713.281-6 <br />
        ● Banco: BICE <br />
        ● Tipo Cuenta: Corriente <br />
        ● Número de Cuenta: 21-00964-4 <br />
        ● Mail: facturacion@lidz.ai <br />
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Para tales efectos, Lidz emitirá la correspondiente factura por períodos vencidos, dentro de los primeros 3 días
        hábiles del siguiente mes al facturado, y deberá ser pagada dentro del plazo máximo de 15 días corridos de la
        fecha de emisión. En caso de incumplimiento del plazo establecido para el pago, la Empresa deberá pagar al
        Prestador, además del valor de la factura, el interés máximo convencional que la ley permita estipular, por cada
        día de atraso.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Por su parte, en caso que la Unidad de Fomento fuera eliminada, reemplazada por otro mecanismo de
        reajustabilidad o alterada su equivalencia en moneda nacional, las cantidades que en este contrato se indican
        deberán transformarse al valor en pesos que tenga la Unidad de Fomento al último día de vigencia de dicho
        sistema y, desde esa fecha hasta el día del pago efectivo, las cantidades se reajustarán de acuerdo a la
        variación que experimente el Índice de Precios al Consumidor determinado por el Instituto Nacional de
        Estadísticas o por el organismo que lo suceda.
      </Typography>
      <Typography align="justify" variant="h6" pb="25px">
        VII. ETAPA DE IMPLEMENTACIÓN DEL SERVICIO.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        A partir de la aceptación de estos Términos y Condiciones, LIDZ tiene la obligación de asignar una persona, como
        jefe de proyecto, quién estará a cargo de la etapa de Implementación. En caso de que se cambie a la persona
        designada, LIDZ deberá dar aviso oportunamente al Cliente, indicando quién asumirá el reemplazo respectivo.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        El jefe de proyecto tiene la función de liderar el proceso de Implementación definiendo la metodología a seguir,
        asegurando los estándares de calidad y orientado a cumplir las etapas definidas en la Carta Gantt. De acuerdo a
        la metodología definida para el Cliente, a través de sesiones grupales o personalizadas se realizará
        capacitaciones remotas que cubrirá todas las funcionalidades del Software. En dichas sesiones el Cliente deberá
        asegurar la asistencia de todos los usuarios administradores que vayan a necesitarla.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        El Cliente tiene la obligación de nombrar a un responsable entre sus colaboradores como persona de contacto con
        LIDZ. Dicho colaborador deberá estar capacitado y contar con los conocimientos técnicos adecuados para manejar
        un Software básico.
      </Typography>
      <Typography align="justify" variant="body1" pb="30px" sx={{ textDecoration: 'underline' }}>
        El Cliente tiene la obligación de recopilar, realizar y revisar la carga de datos contando con el apoyo del jefe
        de proyecto y, deberá dar cumplimiento a las tareas y plazos pactados en la Carta Gantt.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        LIDZ facilita una capacitación final al Cliente que engloba todos los puntos relevantes del Software y una
        instancia para resolver sus dudas técnicas. Está capacitación final es el hito de cierre de la Implementación.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        VIII. OBLIGACIONES.
      </Typography>
      <Typography align="justify" variant="body1" sx={{ fontWeight: 'bold' }}>
        8.1) Obligaciones de LIDZ:
      </Typography>
      <Typography align="justify" variant="body1">
        8.1.1)
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Apoyar al Cliente en la Implementación del Servicio.
        </Typography>
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.1.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Mantener confidencialidad y reserva, o no utilizar, para fines distintos al cumplimiento del Contrato, toda la
          información confidencial del Cliente, excepto para los fines expresados en la cláusula XII.
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.1.3)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Emplear esfuerzos técnicamente razonables para mantener disponible los servicios las 24 horas del día, los 7
          días de la semana. Sin embargo, las partes reconocen que, en atención a la naturaleza de Internet, puedan
          ocurrir interrupciones programadas y no programadas del Servicio que no impliquen responsabilidad para LIDZ.
          Con todo, LIDZ se obliga a emplear sus mejores esfuerzos para que los tiempos de interrupción causen el mínimo
          tiempo de paralización o suspensión. Sin perjuicio de lo anterior, LIDZ no será responsable frente a eventos
          de caso fortuito o fuerza mayor que ocasionen un incumplimiento del presente Contrato;
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.1.4)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Emplear esfuerzos técnicamente razonables, dentro de los estándares de la industria, incluyendo redundancia de
          datos, fail-over y prácticas de respaldo, para proveer los servicios en caso de interrupciones y evitar
          pérdidas de los datos del Cliente;
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.1.5)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Mantener respaldos actualizados de los datos del Cliente dentro y fuera del Data Center;
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          8.1.6)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Actualizar y mantener vigente el Software según se requiera para dar cabal cumplimiento al Servicio.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" sx={{ fontWeight: 'bold' }}>
        8.2) Obligaciones del Cliente
      </Typography>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.1)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Hacer uso del Servicio para los fines que fue contratado;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.2)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Responsabilizarse de todas las acciones que se realicen desde su cuenta, a modo de ejemplo, carga y descarga
          de datos;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.3)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Manejar y actualizar el Software con aquellos datos esenciales para el desarrollo del Servicio de LIDZ;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.4)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Cumplir con las leyes vigentes y contar con las autorizaciones necesarias que pudieren aplicarse en relación
          con el uso del Software y del Servicio;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.5)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Nombrar a un colaborador como persona de contacto directo;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.6)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Resguardar su contraseña y dar aviso de inmediato en caso de que sea vea o tenga motivos para creer que pueda
          verse comprometida;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.7)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          No introducir modificaciones o alteraciones de cualquier clase al Software, ya sea en su infraestructura o
          funcionamiento;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          8.2.8)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          No ceder a terceros a cualquier título el Contrato, ya sea total o parcialmente, sin previa autorización de
          LIDZ.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          8.2.9)
        </Typography>
        <Typography align="justify" variant="body1" display="inline" px={inlineTab}>
          Pagar el precio en tiempo y forma: En caso de que el Cliente no haya pagado el Servicio durante dos meses
          consecutivos y/o tres meses indistintos en un año calendario, LIDZ se reserva el derecho de suspender sin
          responsabilidad alguna el acceso al Software. La reposición del Servicio se hará una vez que LIDZ haya sido
          notificado al correo electrónico facturacion@lidz.ai y haya ratificado el pago del servicio. Sin perjuicio de
          lo anterior, el Cliente faculta a LIDZ para iniciar la cobranza respectiva y enviar los antecedentes que
          estime necesario a las empresas y/o entidades encargadas de esta.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        Se deja expresa constancia que, por inactividad en la URL en un plazo superior a 2 meses, LIDZ se reserva el
        derecho a desactivarla.
      </Typography>
      <Typography align="justify" variant="h6" pb="25px">
        IX. PLAZO Y TÉRMINO DEL SERVICIO.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        <b>9.1) Plazo:</b> El Servicio tendrá una duración de doce meses contados a partir del Inicio del Servicio.
        Dicho plazo se renovará automáticamente por períodos iguales de doce meses sin necesidad de que el Cliente, ni
        LIDZ den aviso previo.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        <b>9.2) Término del Servicio:</b> En caso de que LIDZ o el Cliente decidan no renovar o poner término al
        Servicio, deberán dar aviso a la otra, con al menos dos meses de anticipación. El aviso por parte del Cliente
        deberá enviarse al correo electrónico soporte@lidz.ai
      </Typography>
      <Typography align="justify" variant="body1" sx={{ textDecoration: 'underline' }}>
        Excepciones:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          El Cliente tendrá derecho a poner término al contrato de manera unilateral y sin expresión de causa dentro de
          los primeros 30 días desde esta fecha, solo mediante el envío de un correo electrónico al mismo correo
          expresado en la cláusula anterior.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          9.2.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Si durante la etapa de Implementación el Cliente decide poner término al presente Contrato, deberá pagar a
          LIDZ el monto correspondiente a los meses en los cuales efectivamente se prestó el Servicio luego de los 30
          días iniciales sin cobro.
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        Sin perjuicio de lo anterior, LIDZ podrá poner término inmediato al presente Contrato, sin necesidad de trámite
        ni declaración judicial alguna y sin derecho a pago ni indemnización de ninguna especie para el Cliente, en los
        siguientes casos:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Por no pago o atraso en el pago del precio o tarifa convenida.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.4)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Por inactividad del Cliente, esto es, por no dar respuesta a través de los canales usados entre las Partes por
          un plazo de 2 meses.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.5)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Por incumplimiento grave a las demás obligaciones que impone el presente Contrato.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.6)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          En caso de que el Cliente haga uso del Servicio en términos prohibidos por el presente instrumento.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          9.2.7)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Si el Cliente incurriera en cesación de pagos, notoria insolvencia, o se solicitare o declarare su
          reorganización o liquidación;
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          9.2.8)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Si por caso fortuito o fuerza mayor, o actos de autoridad, LIDZ quedare imposibilitado de prestar el Servicio;
        </Typography>
      </Box>

      <Typography align="justify" variant="body1" pb="25px">
        No obstante, lo anterior, el Cliente estará obligado al pago de todas las sumas que adeudare a LIDZ en virtud
        del Servicio efectivamente prestado.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Por último, LIDZ dispone expresamente que el Cliente no podrá ejercer el derecho a retracto contenido en el
        artículo 3° bis de la Ley 19.496, u otros similares, conforme a la legislación local aplicable.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        En caso de término al Contrato, cualquiera sea la causal, LIDZ deberá restituir los datos entregando al Cliente
        todos aquellos que sean de su propiedad en un plazo no mayor a un mes en formato SQL y/o entregando los
        antecedentes a través de una planilla.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Con todo, LIDZ mantendrá almacenada la respectiva información del Cliente por un plazo máximo de 1 mes, contados
        desde la fecha en que se ha dado término efectivo al Servicio.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        X. SOPORTE.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Posterior a la etapa de Implementación, y durante toda la vigencia del Contrato, LIDZ prestará servicio y
        atención a través del equipo Satisfacción al Cliente (SAC). Este servicio consiste en la asesoría oportuna para
        la resolución de los inconvenientes que pueda presentar el Cliente con el uso del Software. Será el equipo de
        SAC el encargado de aclarar y resolver dudas o consultas acerca de las nuevas funcionalidades o modificaciones
        que puedan impactar el uso del software. Este servicio estará disponible de lunes a viernes entre las 9:00 y
        18:00 horas.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        El Cliente contará con dos canales de comunicación con el equipo de SAC, estos son: contacto telefónico ó correo
        electrónico. La información de contacto de los distintos canales se encuentra disponible en el Software del
        Cliente. En caso de ser necesario, el Cliente deberá entregar a LIDZ acceso a su cuenta (página) para contar con
        mayor detalle y apoyar al desarrollo del caso o intermitencia levantada por el Cliente.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XI. PROPIEDAD.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Toda la información procesada por el Cliente en el Software se presume que es de propiedad y responsabilidad
        exclusiva del Cliente, por lo que éste libera a LIDZ de cualquier responsabilidad legal derivada o relacionada
        con dicha información o su procesamiento.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        LIDZ conserva la plena propiedad y los derechos de propiedad intelectual sobre todas las funcionalidades y
        servicios provistos por el Software.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Incluso si dicho derecho se ha adquirido durante la ejecución del presente Contrato, como por ejemplo, pero sin
        limitarse a ellos, marcas, know-how, herramientas digitales, herramientas de desarrollo, softwares y otros
        programas y tecnologías de base que use durante el desarrollo y que compongan, sean necesarios o surjan del
        desempeño de los servicios (en adelante la “Propiedad Intelectual de Base“), permanecerán como propiedad de LIDZ
        y no se podrán entender cómo transferidos al Cliente en virtud de la suscripción del presente contrato.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        El Cliente, bajo este Contrato adquiere únicamente el derecho de usar el Software, de conformidad con los
        términos y condiciones de la misma, sin que ello implique en ninguna medida la adquisición por parte del Cliente
        de derecho alguno de propiedad o título sobre el Software y demás derechos de propiedad intelectual o industrial
        de LIDZ o de las empresas del grupo.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XII. CONFIDENCIALIDAD.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        LIDZ mantendrá estricta confidencialidad de toda la información del Cliente, estando prohibida la divulgación de
        cualquier tipo de información a terceros no relacionados a LIDZ.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Sin perjuicio de lo anterior, el Cliente autoriza a LIDZ el acceso a la información para fines técnicos de buen
        servicio y éste conservará los informes y resultados que sean generados a partir del uso del Servicio, ya sea
        para fines técnicos de buen servicio y para fines de estudio o estadísticos, sin que ello implique el uso de los
        datos concretos cargados en los sistemas o una vulneración a la cláusula VIII 8.1.2) de los presentes Términos y
        Condiciones. Para lo anterior, LIDZ utilizará la información de forma anónima y se obliga a no revelar ningún
        tipo de información en forma desagregada que permita identificar a las personas o las empresas.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Las partes se obligan a que toda la información que a partir de la fecha del contrato reciban y se compartan
        será única y exclusivamente a través de la plataforma de LIDZ, y que la misma tiene el carácter reservada y
        confidencial. En consecuencia, tomarán todas las medidas necesarias para que esta información no llegue a manos
        de terceros en ninguna circunstancia. De igual forma se obligan a mantener la debida reserva profesional sobre
        la información a la que cada uno tenga acceso manteniendo la ética profesional y se comprometen a no hacer uso
        de ninguna información que pudiera comprometer la seguridad de la otra parte ni dar ventajas competitivas a
        terceros.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        No obstante lo anterior, y a modo de excepción, en caso que el Cliente decida compartir o enviar información
        confidencial por un medio distinto al Software de LIDZ, reconoce y acepta que este último no será responsable
        por los hurtos, robos o extravíos que pudiera sufrir dicha información confidencial, salvo que estos hechos se
        hayan producido por dolo de LIDZ o de alguno de sus colaboradores.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Por último, se deja expresa constancia que la confidencialidad no tendrá aplicación para aquella información
        que:
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          12.2.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Es de acceso o conocimiento público con anterioridad al momento en que se suscribió el presente contrato;
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          12.2.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Se convierte en información generalmente disponible al público sin mediar acto, hecho u omisión de la parte
          receptora;
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex' }}>
        <Typography align="justify" variant="body1">
          12.2.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          La que hubiera sido independientemente generada por una de las partes sin la utilización directa o indirecta
          de la información confidencial recibida de la otra.
        </Typography>
      </Box>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          12.2.4)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          La que deba divulgarse por la parte receptora en cumplimiento de una obligación legal u orden emanada de una
          autoridad administrativa o judicial con facultades para requerir la información del caso, debiendo, no
          obstante, la parte receptora informar inmediatamente y por escrito a la parte reveladora, del requerimiento
          que ha recibido para la entrega de la información confidencial correspondiente, salvo que por disposición
          legal o de la autoridad esto le fuere prohibido.
        </Typography>
      </Box>

      <Typography align="justify" variant="h6" pb="25px">
        XIII. LIMITE DE REPONSABILIDAD.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        LIDZ no será responsable para con el Cliente por daños indirectos y/o consecuenciales, y en todo caso el límite
        máximo, total y global de la responsabilidad de LIDZ para con el Cliente en virtud de este contrato y sus
        documentos integrantes o en virtud de alguna pretensión relacionada con el objeto materia de este contrato, ya
        sea en una responsabilidad contractual, responsabilidad extracontractual, responsabilidad estricta,
        responsabilidad de productos, responsabilidad profesional, indemnización, contribución o cualquier otra
        pretensión, en ningún caso, podrá exceder el valor total anual del presente Contrato.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Las partes declaran que el Servicio contratado genera una relación únicamente comercial, por lo tanto, no quedan
        sujetos a normativas laborales ni de subcontratación.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XIV. CASO FORTUITO O FUERZA MAYOR.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Ninguna de las Partes será responsable ante la otra por demora o incumplimiento de sus obligaciones, o pérdida o
        daño, en la medida que ellos hubieren sido causados por eventos de fuerza mayor, eventos imprevistos o caso
        fortuito. A modo de ejemplo y no taxativo, atentados de hackers, cortes del suministro eléctrico provocados por
        las compañías eléctricas, mal uso de las cuentas de registro, desastres naturales, entre otros, que afecten la
        ejecución del servicio.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        En todo caso, la parte que padezca directamente el caso fortuito o fuerza mayor deberá notificar a la otra parte
        dentro de los 5 días siguientes a su ocurrencia. Cada una de las partes hará todos los esfuerzos razonables para
        atenuar el impacto del evento y mitigar los daños.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XV. PRIVACIDAD Y PROTECCIÓN DE DATOS.
      </Typography>
      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          15.1)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Protección De Datos: Aceptado los presentes Términos y Condiciones, LIDZ deberá proteger todos aquellos datos
          de carácter personal del Cliente. El Cliente declara que podrán realizarse tratamientos de información
          personal dentro de los términos expresados en la Ley de Protección de Datos, Ley N°21.096 de 2018 y en la Ley
          sobre Protección de la Vida Privada, Ley N°19.628 de 1999, y/o las normas locales aplicables, únicamente para
          fines de la ejecución y objeto del presente contrato.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          15.2)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Autorización Tratamiento De Datos: Con la suscripción de los presentes Términos y Condiciones, el Cliente
          autoriza expresamente a LIDZ o sus empresas relacionadas y/o filiales para que pueda realizar tratamientos de
          los datos personales suministrados, para efectos de llevar a cabo el servicio contratado, con finalidades de
          marketing, de la propia prestación del Servicio, y/o con el objeto de entregar información y/o beneficios. Los
          referidos datos personales podrán en casos concretos ser comunicados a terceros, sólo para cumplir con las
          finalidades recién mencionadas.
        </Typography>
      </Box>

      <Box flexDirection="row" sx={{ display: 'flex', pb: '25px' }}>
        <Typography align="justify" variant="body1">
          15.3)
        </Typography>
        <Typography align="justify" variant="body1" pl={inlineTab}>
          Autorización Uso De Marca Y Logo: El Cliente autoriza expresamente a LIDZ para publicitar la prestación del
          Servicio a favor del Cliente, y el uso de sus logos para tales efectos.
        </Typography>
      </Box>
      <Typography align="justify" variant="h6" pb="25px">
        XVI. CESIÓN.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        LIDZ se reserva la facultad de ceder a terceros el derecho de cobrar los precios pactados y/o los demás derechos
        que para ella emana de los contratos suscritos, e inclusive los contratos mismos, ya sea en dominio o en
        garantía, o bien para designar a un tercero para su cobro.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Por su parte, el Cliente no podrá ceder a terceros el Servicio contratado a LIDZ, a ningún título, ni en todo o
        en parte, a menos que cuente con la autorización previa y por escrito de LIDZ.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XVII. INTEGRIDAD
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        En caso de que cualquier cláusula contenida en los presentes Términos y Condiciones sea declarada como ilegal,
        nula, inaplicable o inexigible, entonces en la medida que la legislación lo permita, dicha ilegalidad, nulidad,
        inaplicabilidad, invalidez o inexigibilidad no afectará la validez de las demás cláusulas establecidas en los
        Términos y Condiciones que continuarán siendo exigibles entre las partes.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Las Partes reconocen que todos los documentos Anexos y/o adjuntos a los presentes Términos y Condiciones forman
        parte integrante del mismo para todos los efectos, y, por tanto, son totalmente vinculantes para las partes.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XVIII. LEGISLACIÓN APLICABLE Y SOLUCIÓN DE CONTROVERSIAS.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Las leyes de Chile rigen cualquier disputa que se derive de estos Términos y Condiciones o esté relacionada con
        ellos, u otros servicios relacionados, con independencia de las normas de conflicto de leyes.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        Tanto LIDZ como el Cliente aceptan someterse a la jurisdicción exclusiva para Mediación, conforme al Reglamento
        Procesal de Mediación vigente del centro de Arbitraje y Mediación de Santiago de Chile vigente al momento de
        solicitarlo. En caso de que la Mediación no prospere, la dificultad o controversia se resolverá mediante
        Arbitraje con arreglo al Reglamento Procesal de Arbitraje del mismo centro que se encuentre vigente a esta
        fecha. Las partes confieren poder especial irrevocable a la Cámara de Comercio de Santiago A.G., para que, a
        petición escrita de cualquiera de ellas, designe a un árbitro mixto de entre los integrantes del cuerpo arbitral
        del Centro de Arbitraje y Mediación de Santiago. El árbitro queda especialmente facultado para resolver todo
        asunto relacionado.
      </Typography>
      <Typography align="justify" variant="body1" pb="25px">
        En la medida en que la legislación local aplicable prohíba resolver ciertas disputas en la forma anteriormente
        mencionada o en un tribunal de Chile en su defecto, el Cliente podrá accionar antes los tribunales locales.
        Asimismo, si la legislación local aplicable prohíbe a sus tribunales locales aplicar la ley de Chile para
        resolver las disputas, estas se regirán por la legislación local aplicable del país del Cliente, estado u otro
        lugar de residencia.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XIX. DOMICILIO Y COMPETENCIA.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Para todos los efectos legales derivados del otorgamiento del presente contrato, las partes fijan su domicilio
        en la comuna y ciudad de Santiago y se someten a la jurisdicción y competencia de sus tribunales ordinarios de
        justicia.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XX. REPRESENTACIÓN.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        Los firmantes declaran que detentan facultades suficientes para obligar a sus respectivas representadas en los
        términos del presente instrumento, asumiendo su responsabilidad personal por la veracidad de tal declaración y
        por los perjuicios que la falsedad de esta irrogue a las involucradas.
      </Typography>

      <Typography align="justify" variant="h6" pb="25px">
        XXI. FIRMA ELECTRÓNICA.
      </Typography>

      <Typography align="justify" variant="body1" pb="25px">
        El presente contrato se firma electrónicamente y es válido según lo establecido en la Ley número 19.799 sobre
        Documentos Electrónicos, Firma Electrónica y Servicios de Certificación de dicha Firma y los reglamentos
        correspondientes dictados por el Ministerio de Economía, Fomento y Turismo, enviándose, como respaldo, una copia
        del documento firmado vía mail electrónico a los comparecientes indicados en el presente documento.
      </Typography>
    </Box>
  );
};

export default TermsConditions;
