import React, { FC, useState } from 'react';

import { Box, TextField, Typography } from '@mui/material';

import { useCreateMockPortalInmobiliarioProspectMutation } from 'services/api/prospects.api';

const PortalInmobiliarioMock: FC = () => {
  const [createProspect, { isLoading, isSuccess }] = useCreateMockPortalInmobiliarioProspectMutation();
  const [phone, setPhone] = useState<string>('569');

  const setPhoneString = (phoneInput: string) => {
    let phoneString = phoneInput.replace(' ', '');
    phoneString = phoneString.replace(/[a-zA-Z]/g, ''); // remove letters
    if (phoneString === '56') {
      setPhone('569');
      return;
    }
    if (phoneString === '5') {
      setPhone('569');
      return;
    }
    if (!phoneString.startsWith('569')) {
      phoneString = `569${phoneString}`;
    }
    setPhone(phoneString);
  };

  const handleSubmit = () => {
    if (!phone) {
      return;
    }
    createProspect({
      phone,
      // eslint-disable-next-line no-console
    }).catch(console.warn);
    setPhone('569');
    // eslint-disable-next-line no-console
    console.log(isLoading, isSuccess);
  };
  return (
    <Box
      flex={1}
      sx={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        width: '100%',
        height: '100vh',
        // background image
        backgroundImage: 'url(/macul-portal-inmobiliario.png)',
        backgroundSize: 'cover',
        backgroundPosition: 'center',
        backgroundRepeat: 'no-repeat',
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          right: '17vw',
          bottom: '15vh',
          width: '33vw',
          height: '15vh',
          backgroundColor: 'white',
        }}
      >
        <Box width="80%" margin="auto" mb={1}>
          <TextField
            fullWidth
            prefix="569"
            label="Teléfono"
            variant="outlined"
            value={phone}
            onChange={(e) => setPhoneString(e.target.value)}
          />
        </Box>
        <Box width="80%" margin="auto">
          <button
            style={{
              borderRadius: '6px',
              fontFamily: 'Proxima Nova,-apple-system,Roboto,Arial,sans-serif',
              fontSize: '16px',
              fontWeight: '600',
              height: '48px',
              lineHeight: '48px',
              padding: '0 24px',
              textAlign: 'center',
              boxSizing: 'border-box',
              display: 'block',
              width: '100%',
              boxShadow: '0 0 0 0 #fff',
              cursor: 'pointer',
              transition: 'box-shadow .25s ease-out,background-color .2s ease-out',
              backgroundColor: '#3483fa',
              color: '#fff',
              border: '0',
              overflow: 'hidden',
              position: 'relative',
              marginBottom: '8px',
            }}
            type="submit"
            onClick={handleSubmit}
          >
            Quiero que me contacten
          </button>
          {isSuccess && (
            <Typography variant="body1" pb="25px" color="red">
              Solicitud enviada. Estaremos en contacto
            </Typography>
          )}
        </Box>
      </Box>
    </Box>
  );
};

export default PortalInmobiliarioMock;
