import { Message } from '../types/message';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPublicClientMessages: build.query({
      query: (props: { clientId: number; token: string }) => ({
        url: `/publicAccess/messages?clientId=${props.clientId}&token=${props.token}`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
  }),
  overrideExisting: false,
});
// eslint-disable-next-line import/prefer-default-export
export const { useGetPublicClientMessagesQuery } = publicAccessApi;
