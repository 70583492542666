import React, { FC, useEffect, useState } from 'react';

import { Box, Paper, Stack, Modal } from '@mui/material';
import Button from '@mui/material/Button';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import PrimaryBtn from 'components/UI/Buttons';
import RoundedInput from 'components/UI/RoundedInput';
import { useGetTemplatesQuery, usePostTemplateMessageMutation } from 'services/api/clients';
import { SendTemplate, TemplateResponse } from 'services/types/message';

declare type Parameter = { text: string; type: 'text' };
declare type Parameters = { HEADER: Parameter[]; BODY: Parameter[]; FOOTER: Parameter[]; BUTTON: Parameter[] };
const getEmptyParameters = (): Parameters => ({
  HEADER: [],
  BODY: [],
  FOOTER: [],
  BUTTON: [],
});

interface Props {
  clientId: number;
  refetch: () => void;
}

const TemplateFormModal: FC<Props> = ({ clientId, refetch }) => {
  const { data: templates } = useGetTemplatesQuery({ clientId });
  const [messageToSend, setMessageToSend] = useState({
    HEADER: '',
    BODY: '',
    FOOTER: '',
    BUTTONS: [
      { text: '', url: '' },
      { text: '', url: '' },
    ],
  });
  const [selectedTemplate, setSelectedTemplate] = useState<TemplateResponse | null>(null);
  const [postTemplate] = usePostTemplateMessageMutation();
  const [parameters, setParameters] = useState<Parameters>(getEmptyParameters());
  const [open, setOpen] = useState(false);

  const emptyParameters = () => {
    setParameters(getEmptyParameters());
    setSelectedTemplate(null);
    setMessageToSend({
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    });
  };

  useEffect(() => {
    // build Form
    if (selectedTemplate) {
      const tempParameters: Parameters = getEmptyParameters();
      selectedTemplate?.components.forEach((component) => {
        if (component.type !== 'BUTTONS') {
          const count = (component.text?.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters[component.type].push({ text: '', type: 'text' });
          }
        } else if (component.buttons) {
          // There can only be 2 buttons but only one with a url
          let url;
          if (component.buttons.length === 2) {
            url = component.buttons[0].url || component.buttons[1].url || '';
          } else {
            url = component.buttons[0].url || '';
          }
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            tempParameters.BUTTON.push({ text: '', type: 'text' });
          }
        }
      });
      setParameters(tempParameters);
    }
  }, [selectedTemplate]);

  const getTemplateMessage = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        const count = (component.text.match(/{{[1-9]}}/g) || []).length;
        let newText = component.text;
        for (let i = 0; i < count; i += 1) {
          const re = new RegExp(`{{[${i + 1}]}}`, 'g');
          newText = newText.replace(re, parameters[component.type][i].text);
        }
        tempMessageToSend[component.type] = newText;
      } else if (component.buttons) {
        // There can only be 2 buttons but only one with a url
        let buttonWithUrl;
        if (component.buttons[0].url) {
          buttonWithUrl = 0;
        } else if (component.buttons.length === 2 && component.buttons[1].url) {
          buttonWithUrl = 1;
        }
        if (buttonWithUrl !== undefined) {
          let url = component.buttons[buttonWithUrl].url || '';
          const count = (url.match(/{{[1-9]}}/g) || []).length;
          for (let i = 0; i < count; i += 1) {
            const re = new RegExp(`{{[${i + 1}]}}`, 'g');
            url = url.replace(re, parameters.BUTTON[i].text);
          }
          tempMessageToSend.BUTTONS[buttonWithUrl].url = url;
        }
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  const getTemplateMessageWithoutParameters = () => {
    const tempMessageToSend = {
      HEADER: '',
      BODY: '',
      FOOTER: '',
      BUTTONS: [
        { text: '', url: '' },
        { text: '', url: '' },
        { text: '', url: '' },
      ],
    };
    selectedTemplate?.components.forEach((component) => {
      if (component.text && component.type !== 'BUTTONS') {
        tempMessageToSend[component.type] = component.text;
      } else if (component.buttons) {
        component.buttons.forEach((button, index) => {
          tempMessageToSend.BUTTONS[index].text = button.text;
          tempMessageToSend.BUTTONS[index].url = button.url || '';
        });
      }
    });
    setMessageToSend(tempMessageToSend);
  };

  const getMessageToSend = () => {
    let message = '';
    Object.keys(messageToSend).forEach((key) => {
      if (key !== 'BUTTONS' && messageToSend[key as keyof Omit<Parameters, 'BUTTON'>] !== '') {
        message += `${messageToSend[key as keyof Omit<Parameters, 'BUTTON'>]}\n`;
      }
    });
    return message;
  };

  useEffect(() => {
    if (
      parameters.HEADER.length > 0 ||
      parameters.BODY.length > 0 ||
      parameters.FOOTER.length > 0 ||
      parameters.BUTTON.length > 0
    ) {
      getTemplateMessage();
    } else {
      getTemplateMessageWithoutParameters();
    }
  }, [parameters]);

  const handleFormChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    type: keyof Parameters,
    index: number
  ) => {
    const data = [...parameters[type]];
    data[index].text = event.target.value;
    setParameters({ ...parameters, [type]: data });
  };

  const handleFormSubmit = (e: React.FormEvent) => {
    e.preventDefault();
    let components: SendTemplate['components'] = [];
    Object.keys(parameters).forEach((key) => {
      if (parameters[key as keyof Parameters].length > 0 && components) {
        components.push({
          type: key.toLowerCase() as 'header' | 'body' | 'footer' | 'button',
          parameters: parameters[key as keyof Parameters],
          sub_type: key === 'BUTTON' ? ('url' as const) : undefined,
          index: key === 'BUTTON' ? 0 : undefined,
        });
      }
    });
    if (components.length === 0) {
      components = undefined;
    }
    if (selectedTemplate) {
      postTemplate({
        clientId,
        text: getMessageToSend(),
        languageCode: selectedTemplate.language,
        templateName: selectedTemplate.name,
        components,
      })
        .then(() => refetch())
        .catch((err) => {
          // eslint-disable-next-line no-console
          console.error(err);
        });
    }
    emptyParameters();
    setOpen(false);
  };

  return (
    <>
      <PrimaryBtn onClick={() => setOpen(true)}>Enviar Template</PrimaryBtn>
      <Modal open={open} onClose={() => setOpen(false)}>
        <Paper
          sx={{
            position: 'absolute',
            top: '50%',
            left: '50%',
            transform: 'translate(-50%, -50%)',
            width: { xs: '100%', lg: '700px' },
            bgcolor: 'background.paper',
            borderRadius: '20px',
            boxShadow: 24,
            p: 4,
            display: 'flex',
          }}
        >
          <Box p={2} minWidth="250px">
            <FormControl fullWidth>
              <InputLabel id="select-template">Seleccionar plantilla</InputLabel>
              <Select
                labelId="select-template"
                id="select-template"
                value={selectedTemplate?.name || ''}
                label="Seleccionar plantilla"
                onChange={(e) => {
                  const targetTemplate = templates?.find((template) => template.name === e.target.value);
                  if (targetTemplate) {
                    setSelectedTemplate(targetTemplate);
                  }
                }}
              >
                {templates?.map((template) => (
                  <MenuItem key={template.id} value={template.name}>
                    {template.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <form onSubmit={handleFormSubmit}>
              <Stack spacing={1} width="100%" justifyContent="center">
                {Object.keys(parameters).map((key) => {
                  if (parameters[key as keyof Parameters].length > 0) {
                    return (
                      <Box key={key}>
                        <Typography variant="body1">Parámetros {key}</Typography>
                        {parameters[key as keyof Parameters].map((form, index) => (
                          <div key={`${key}-${index.toString()}`}>
                            <RoundedInput
                              name={`text-${index.toString()}`}
                              onChange={(event) => handleFormChange(event, key as keyof Parameters, index)}
                              value={form.text}
                              size="small"
                            />
                          </div>
                        ))}
                      </Box>
                    );
                  }
                  return null;
                })}
                {selectedTemplate && (
                  <Button type="submit" variant="contained">
                    Enviar Plantilla
                  </Button>
                )}
              </Stack>
            </form>
          </Box>
          <Box p={1}>
            {messageToSend && (
              <Stack spacing={2} width="100%">
                <Typography variant="h2" width="100%">
                  Mensaje a enviar:
                </Typography>
                <Box border="1px solid black" borderRadius={5} p={2}>
                  {messageToSend.HEADER && (
                    <Typography variant="h3" sx={{ whiteSpace: 'pre-line' }} width="100%">
                      {messageToSend.HEADER}
                    </Typography>
                  )}
                  {messageToSend.BODY && (
                    <Typography variant="body1" sx={{ whiteSpace: 'pre-line' }}>
                      {messageToSend.BODY}
                    </Typography>
                  )}
                  {messageToSend.FOOTER && (
                    <Typography variant="body2" sx={{ whiteSpace: 'pre-line' }} width="100%">
                      {messageToSend.FOOTER}
                    </Typography>
                  )}
                  {messageToSend.BUTTONS.some((b) => b.text !== '') && (
                    <Stack spacing={1}>
                      <Typography variant="body1" fontWeight={600}>
                        Botones:
                      </Typography>
                      {messageToSend.BUTTONS.map(
                        (button) =>
                          button.text && (
                            <Typography
                              key={button.text}
                              variant="body2"
                              sx={{ whiteSpace: 'pre-line', border: '2px solid', borderRadius: '20px', p: 1 }}
                            >
                              {button.text} {button.url && <a href={button.url}>({button.url})</a>}
                            </Typography>
                          )
                      )}
                    </Stack>
                  )}
                </Box>
              </Stack>
            )}
          </Box>
        </Paper>
      </Modal>
    </>
  );
};

export default TemplateFormModal;
