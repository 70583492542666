import type { ClientQuestion } from 'services/types/clientQuestions';

import baseApi from './baseApi';

const clientQuestionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    editClientQuestion: build.mutation({
      query: (params: {
        clientQuestionId: number;
        question: string;
        answer?: string;
        generateNewMessage?: boolean;
      }) => ({
        url: `/clientQuestions/${params.clientQuestionId}`,
        method: 'PATCH',
        body: {
          attributes: {
            question: params.question,
            answer: params.answer,
          },
          generateResponseToClient: params.generateNewMessage,
        },
      }),
      transformResponse: (response: ClientQuestion): ClientQuestion => response,
    }),
    getAllClientQuestions: build.query({
      query: () => ({
        url: '/clientQuestions',
        method: 'GET',
      }),
      transformResponse: (response: ClientQuestion[]): ClientQuestion[] => response,
    }),
    getOneClientQuestions: build.query({
      query: (params: { clientQuestionId: number }) => ({
        url: `/clientQuestions/${params.clientQuestionId}`,
        method: 'GET',
      }),
      transformResponse: (response: ClientQuestion | null | undefined): ClientQuestion | null | undefined => response,
    }),
    deleteClientQuestion: build.mutation({
      query: (params: { clientQuestionId: number }) => ({
        url: `/clientQuestions/${params.clientQuestionId}`,
        method: 'DELETE',
      }),
      transformResponse: (response: void): void => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAllClientQuestionsQuery,
  useGetOneClientQuestionsQuery,
  useEditClientQuestionMutation,
  useDeleteClientQuestionMutation,
} = clientQuestionsApi;
