export const dateFormat = (date: Date | null | undefined): string => {
  if (date) {
    let dateObject = new Date(date);
    dateObject = new Date(dateObject.getTime() + dateObject.getTimezoneOffset() * 60000);
    return `${dateObject.getDate()}/${dateObject.getMonth() + 1}/${dateObject.getFullYear()}`;
  }
  return '';
};

export const longDateFormat = (date: string | undefined): string => {
  if (date) {
    const dateObject = new Date(date); // date is in format year/month/day
    let month = dateObject.toLocaleDateString('es-CL', { month: 'long' });
    month = month.charAt(0).toUpperCase() + month.slice(1);
    return `${month} ${dateObject.getDate() + 1}, ${dateObject.getFullYear()}`;
  }
  return '';
};
