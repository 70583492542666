import React, { FC } from 'react';

import { CssBaseline, ThemeProvider, Box } from '@mui/material';
import { Theme } from '@mui/material/styles';

import Sidebar, { drawerWidth, drawerWidthMD } from 'components/layout/SideBar/Sidebar';
import useCurrentRoute from 'hooks/useCurrentRoute';

import styles from './Layout.module.scss';
import DefaultTheme from './themes/DefaultTheme';

declare module '@mui/material/styles' {
  interface Palette {
    newBlue: Palette['primary'];
    newLightBlue: Palette['primary'];
    newCyan: Palette['primary'];
    newTeal: Palette['primary'];
  }
  interface PaletteOptions {
    newCyan: PaletteOptions['primary'];
    newTeal: PaletteOptions['primary'];
    newBlue: PaletteOptions['primary'];
    newLightBlue: PaletteOptions['primary'];
  }
}

const THEMES: Theme[] = [DefaultTheme];

const setThemeXLTypographySize = (theme: Theme): void => {
  // eslint-disable-next-line no-param-reassign
  theme.typography.h2[theme.breakpoints.up('xl')] = {
    fontSize: '26px',
  };
  // eslint-disable-next-line no-param-reassign
  theme.typography.h3[theme.breakpoints.up('xl')] = {
    fontSize: '22px',
  };
  // eslint-disable-next-line no-param-reassign
  theme.typography.h4[theme.breakpoints.up('xl')] = {
    fontSize: '21px',
  };
};

THEMES.forEach(setThemeXLTypographySize);

interface Props {
  children?: JSX.Element;
}

const Layout: FC<Props> = ({ children }: Props) => {
  const { private: privateRoute, noLayout } = useCurrentRoute();
  const theme = THEMES[0];

  if (!privateRoute || noLayout) {
    return (
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <div className={styles.ComponentContainer}>{children}</div>
      </ThemeProvider>
    );
  }

  return (
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <Box sx={{ display: { xs: 'block', lg: 'flex' } }}>
        <Sidebar />
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'column',
            width: {
              xs: '100%',
              lg: `calc(100% - ${drawerWidthMD}px)`,
              xl: `calc(100% - ${drawerWidth}px)`,
            },
            m: 0,
            p: 0,
            maxHeight: '100svh',
            // no scroll
            overflow: 'hidden',
          }}
        >
          <Box
            sx={{
              backgroundColor: 'secondary.light',
              borderRadius: { xs: 0, lg: '38px 0 0 30px' },
              height: '100vh',
              width: '100%',
              px: { xs: 0, lg: 3, xl: 6 },
              pt: 0,
              overflow: 'auto',
            }}
          >
            {children}
          </Box>
        </Box>
      </Box>
    </ThemeProvider>
  );
};

Layout.defaultProps = {
  children: undefined,
};

export default Layout;
