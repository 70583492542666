import React, { useEffect, useState } from 'react';

import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import Box from '@mui/material/Box';
import IconButton from '@mui/material/IconButton';
import { DataGrid, GridToolbar, GridColDef, GridRenderCellParams } from '@mui/x-data-grid';
import { esES } from '@mui/x-data-grid/locales';

import AlertDialog from 'components/UI/AlertDialog';
import SectionHeader from 'components/layout/SectionHeader';
import { useDeleteClientQuestionMutation, useGetAllClientQuestionsQuery } from 'services/api/clientQuestions.api';

import ClientQuestionModal from './ClientQuestionsModal';

const ClientQuestions = () => {
  const { data: clientQuestions, refetch } = useGetAllClientQuestionsQuery(null);

  const [deleteClientQuestion, { isSuccess: isDeleteSuccess }] = useDeleteClientQuestionMutation();
  const [openClientQuestionModal, setOpenClientQuestionModal] = useState(false);
  const [clientQuestionId, setClientQuestionId] = useState<number | null>(null);
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [clientQuestionIdToDelete, setClientQuestionIdToDelete] = useState<number | null>(null);

  useEffect(() => {
    if (!openClientQuestionModal || isDeleteSuccess) {
      // eslint-disable-next-line no-console
      refetch().catch((e) => console.error(e));
    }
  }, [openClientQuestionModal, isDeleteSuccess]);

  const columns: GridColDef[] = [
    { field: 'question', headerName: 'Pregunta', width: 650 },
    {
      field: 'answer',
      headerName: 'Respuesta',
      width: 300,
    },
    {
      field: 'clientId',
      headerName: 'Link',
      width: 50,
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      renderCell: (params) => (
        <a href={`/clients/${params.value}`} target="_blank" rel="noreferrer">
          Ver
        </a>
      ),
    },
    {
      field: 'createdAt',
      headerName: 'Fecha de creación',
      headerAlign: 'center',
      width: 190,
      valueFormatter: (value: string) => new Date(value).toLocaleString('es-CL', { hour12: false }),
    },
    {
      field: 'id',
      headerName: 'Acciones',
      width: 120,
      headerAlign: 'center',
      align: 'center',
      sortable: false,
      filterable: false,
      disableColumnMenu: true,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      renderCell: (params: GridRenderCellParams<any, number>) => (
        <>
          <IconButton
            onClick={() => {
              setClientQuestionId(params.value || null);
              setOpenClientQuestionModal(true);
            }}
            sx={{ width: '40px' }}
          >
            <EditIcon />
          </IconButton>
          <IconButton
            onClick={() => {
              setClientQuestionIdToDelete(params.value || null);
              setOpenDeleteAlert(true);
            }}
            color="error"
            sx={{ width: '40px' }}
          >
            <DeleteIcon />
          </IconButton>
        </>
      ),
    },
  ];

  if (clientQuestions && clientQuestions.length > 0 && clientQuestions[0].project) {
    const projectColumn: GridColDef = {
      field: 'project',
      headerName: 'Inmobiliaria',
      width: 150,
      valueGetter: (value) => (value as { name: string })?.name || '',
    };
    columns.unshift(projectColumn);
  }

  return (
    <Box>
      <AlertDialog
        openDialog={openDeleteAlert}
        setOpenDialog={setOpenDeleteAlert}
        header="¿Estas seguro que deseas eliminar esta pregunta?"
        msg="La pregunta será eliminada de manera permanente."
        confirmMsg="Si, eliminar"
        confirmAction={() => {
          if (clientQuestionIdToDelete) {
            deleteClientQuestion({ clientQuestionId: clientQuestionIdToDelete }).catch((e) => console.error(e)); // eslint-disable-line no-console
          }
          setOpenDeleteAlert(false);
          setOpenClientQuestionModal(false);
        }}
      />
      <SectionHeader text="Lista de Preguntas" button={null} />
      <Box
        sx={{
          backgroundColor: 'white',
          borderRadius: 4,
          p: 2,
        }}
      >
        {clientQuestions && (
          <DataGrid
            rows={clientQuestions}
            columns={columns}
            slots={{
              toolbar: GridToolbar,
            }}
            localeText={esES.components.MuiDataGrid.defaultProps.localeText}
            sx={{
              color: 'Black',
              height: 'calc(100vh - 200px)',
              '& .MuiDataGrid-row': {
                cursor: 'pointer',
              },
              '& .MuiDataGrid-row.Mui-selected': {
                backgroundColor: '#e8f0fe',
              },
              border: 'none',
            }}
          />
        )}
      </Box>
      {openClientQuestionModal && clientQuestionId && !openDeleteAlert && (
        <ClientQuestionModal setOpen={setOpenClientQuestionModal} clientQuestionId={clientQuestionId} />
      )}
    </Box>
  );
};

export default ClientQuestions;
