import React from 'react';

import { Box, Typography, Card, CardContent } from '@mui/material';

interface ThemesBeforeAndAfterProps {
  themesBeforeAndAfter: {
    [theme: string]: {
      before: { [key: string]: number };
      after: { [key: string]: number };
    };
  };
}

const ArrowBox = () => (
  <Box display="flex" alignItems="center" justifyContent="center" flex={1}>
    <Typography variant="h6">→</Typography>
  </Box>
);

const ThemesBeforeAndAfter: React.FC<ThemesBeforeAndAfterProps> = ({ themesBeforeAndAfter }) => {
  return (
    <Card>
      <CardContent>
        <Typography variant="h6" textAlign="center">
          Secuencia por tema
        </Typography>
        {/* if themesBeforeAndAfter isn't empty, then: */}
        {Object.keys(themesBeforeAndAfter).length !== 0 ? (
          <Box display="flex" flexDirection="column" alignItems="center" gap={4}>
            {Object.entries(themesBeforeAndAfter).map(([theme, { before, after }]) => (
              <Box key={theme} display="flex" width="100%" justifyContent="space-between" gap={2}>
                {/* Prev themes  */}
                <Box flex={5} display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" textAlign="center">
                    Temas Previos
                  </Typography>
                  <Box>
                    {Object.entries(before).map(([prevTheme, count]) => (
                      <Typography key={prevTheme} textAlign="start">
                        {prevTheme}: {count}
                      </Typography>
                    ))}
                  </Box>
                </Box>

                <ArrowBox />

                {/* Main theme */}
                <Box flex={5} textAlign="center" alignContent="center">
                  <Typography variant="h3">{theme}</Typography>
                </Box>

                <ArrowBox />

                {/* later themes */}
                <Box flex={5} display="flex" flexDirection="column" alignItems="center">
                  <Typography variant="h6" textAlign="center">
                    Temas Posteriores
                  </Typography>
                  <Box>
                    {Object.entries(after).map(([nextTheme, count]) => (
                      <Typography key={nextTheme}>
                        {nextTheme}: {count}
                      </Typography>
                    ))}
                  </Box>
                </Box>
              </Box>
            ))}
          </Box>
        ) : (
          <Typography variant="h6" textAlign="center" margin="50px 0 50px 0" fontSize={16} fontWeight={600}>
            No data to display
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default ThemesBeforeAndAfter;
