import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';
import { BarChart } from '@mui/x-charts/BarChart';
import { axisClasses } from '@mui/x-charts/ChartsAxis';
import { LineChart } from '@mui/x-charts/LineChart';
import { PieChart } from '@mui/x-charts/PieChart';
import { ScatterChart } from '@mui/x-charts/ScatterChart';

import type { GenerateChartToolArguments } from 'services/types/analysis';

import MessageWrapper from './MessageWrapper';

interface Props {
  args: string;
}

const GenerateChartToolComponent: FC<Props> = ({ args }: Props) => {
  const argumentsObject = JSON.parse(args) as GenerateChartToolArguments;
  let { chartType } = argumentsObject;
  const title = `Gráfico ${argumentsObject.yAxisLabel} vs ${argumentsObject.xAxisLabel}`;
  const longestLabel = argumentsObject.data.reduce(
    (acc, data) => (data.label.length > acc ? data.label.length : acc),
    5
  );
  const barAndLineSeries = [
    {
      data: argumentsObject.data.map((data) => data.value),
      label: argumentsObject.valueLabel || argumentsObject.yAxisLabel,
      stack: '1',
    },
  ];
  const scatterSeries = [
    {
      data: argumentsObject.data.map((data) => ({ id: data.label, x: Number(data.label), y: data.value })),
      label: argumentsObject.valueLabel || argumentsObject.yAxisLabel,
    },
  ];
  if (chartType === 'scatter' && scatterSeries[0].data.some((data) => Number.isNaN(data.x))) {
    // If the x-axis is not numeric, we can't use a scatter plot
    chartType = 'bar';
  }
  if (argumentsObject.data.length > 0 && argumentsObject.data.some((item) => item.secondaryValue)) {
    barAndLineSeries.push({
      data: argumentsObject.data.map((data) => data.secondaryValue),
      label: argumentsObject.secondaryValueLabel || 'Secondary Value',
      stack: chartType === 'bar' ? '1' : '2', // BarCharts should be stacked, but line charts should not
    });
    scatterSeries.push({
      data: argumentsObject.data.map((data) => ({ id: data.label, x: Number(data.label), y: data.secondaryValue })),
      label: argumentsObject.secondaryValueLabel || 'Secondary Value',
    });
  }
  return (
    <MessageWrapper caption={title} align="left">
      {chartType === 'bar' && (
        <BarChart
          title={title}
          series={barAndLineSeries}
          height={400 + longestLabel * 5}
          width={900}
          xAxis={[
            {
              data: argumentsObject.data.map((data) => data.label),
              scaleType: 'band',
              tickLabelStyle: longestLabel > 8 ? { angle: 45, textAnchor: 'start', fontSize: 12 } : undefined,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
          margin={{ bottom: 20 + longestLabel * 5, left: 70 }}
          sx={{
            [`.${axisClasses.left} .${axisClasses.label}`]: {
              transform: 'translate(-24px, 0)',
            },
          }}
        />
      )}
      {chartType === 'line' && (
        <LineChart
          title={title}
          series={barAndLineSeries}
          height={400}
          width={900}
          xAxis={[
            {
              data: argumentsObject.data.map((data) => data.label),
              scaleType: 'band',
              tickLabelStyle: longestLabel > 8 ? { angle: 45, textAnchor: 'start', fontSize: 12 } : undefined,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
          margin={{ bottom: 20 + longestLabel * 6, left: 70 }}
        />
      )}
      {chartType === 'pie' && (
        <PieChart
          skipAnomation
          label={title}
          title={title}
          series={[
            {
              data: argumentsObject.data.map((data) => ({ id: data.label, label: data.label, value: data.value })),
            },
          ]}
          width={750 + longestLabel * 5 + argumentsObject.data.length * 20}
          height={(Math.floor(argumentsObject.data.length / 5) + 1) * 200}
          margin={{ left: -200 }}
        />
      )}
      {chartType === 'scatter' && (
        <ScatterChart
          title={title}
          series={scatterSeries}
          height={400}
          width={800}
          xAxis={[
            {
              label: argumentsObject.xAxisLabel,
            },
          ]}
          yAxis={[
            {
              label: argumentsObject.yAxisLabel,
            },
          ]}
        />
      )}
      {['line', 'bar'].includes(argumentsObject.chartType) && (
        <Box justifyContent="center" display="flex">
          <Typography variant="body1">{argumentsObject.xAxisLabel}</Typography>
        </Box>
      )}
    </MessageWrapper>
  );
};

export default GenerateChartToolComponent;
