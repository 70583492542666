import * as React from 'react';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { PieChart } from '@mui/x-charts/PieChart';

import useIsMobile from 'hooks/useIsMobile';

type MetricsPieChartProps = {
  type: string;
  filteredData: Record<string, number | null>;
};

export const MetricsPieChart: React.FC<MetricsPieChartProps> = ({ type, filteredData }) => {
  const { isMobile } = useIsMobile();
  return (
    <Card>
      <CardContent>
        {filteredData ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <PieChart
              margin={{ top: 10, bottom: 10, left: 10, right: window.innerWidth / 5 }}
              series={[
                {
                  data: Object.entries(filteredData).map(([key, value]) => ({
                    id: key,
                    value: value || 0,
                    label: key,
                  })),
                },
              ]}
              slotProps={{
                legend: {
                  hidden: isMobile,
                  direction: 'column',
                  position: { vertical: 'middle', horizontal: 'right' },
                  padding: 0,
                },
              }}
              height={Object.keys(filteredData).length * 20 + 200} // Adjust the height based on the number of items
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MetricsPieChart;
