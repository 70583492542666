import React from 'react';

import { AppBar, Toolbar, Typography, Box } from '@mui/material';
import useScrollTrigger from '@mui/material/useScrollTrigger';

import useIsMobile from 'hooks/useIsMobile';

interface SectionHeaderInterface {
  text: string;
  button?: React.ReactNode | null;
}

interface ElevationScrollInterface {
  children: React.ReactElement;
}

const ElevationScroll: React.FC<ElevationScrollInterface> = ({ children }) => {
  const { isMobile } = useIsMobile();

  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
  });

  return React.cloneElement(children, {
    elevation: trigger && isMobile ? 4 : 0,
  });
};

const SectionHeader: React.FC<SectionHeaderInterface> = ({ text, button }) => {
  return (
    <ElevationScroll>
      <AppBar
        sx={{
          backgroundColor: '#F9FAFD',
          zIndex: '1',
          position: 'static',
        }}
      >
        <Toolbar
          sx={{
            pt: { xs: '18px', xl: 5 },
            pb: { xs: '20px', xl: 5 },
            pl: { xs: '65px', lg: '0 !important' },
            pr: { xl: '0 !important' },
            minHeight: { xs: '80px', xl: '138px' },
            display: 'flex',
            justifyContent: { xs: 'start', lg: 'space-between' },
            alignItems: 'center',
          }}
        >
          <Box
            sx={{
              display: 'flex',
              alignItems: { xs: 'flex-start', lg: 'center' },
              flexDirection: { xs: 'column', lg: 'row' },
              width: { xs: '100%', lg: 'auto' },
              justifyContent: 'space-between',
            }}
          >
            {text !== '' && (
              <Typography
                variant="h2"
                sx={{ mr: { xs: 2, lg: 6 }, mb: { xs: 1, lg: 0 }, color: '#1C1C3A', fontWeight: 500 }}
              >
                {text}
              </Typography>
            )}
            {button}
          </Box>
          <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
            <Typography
              variant="h4"
              sx={{
                display: { xs: 'none', lg: 'block' },
                lineHeight: '40px',
                color: '#1C1C3A',
              }}
            >
              {new Date().toLocaleDateString('es-CL')}
            </Typography>
          </Box>
        </Toolbar>
      </AppBar>
    </ElevationScroll>
  );
};

SectionHeader.defaultProps = {
  button: null,
};

export default SectionHeader;
