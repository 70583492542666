import { useLocation } from 'react-router-dom';

import routes from 'routes/routes';

import { Route } from '../routes/routes';

const useCurrentRoute = (): Route => {
  const location = useLocation();

  let currentRoute = routes[location.pathname];
  if (!currentRoute) {
    currentRoute = routes[`/${location.pathname.split('/')[1]}`];
  }
  if (!currentRoute) {
    return routes.error;
  }
  return currentRoute;
};

export default useCurrentRoute;
