import React, { FC, useEffect, useState } from 'react';

import Box from '@mui/material/Box';
import Checkbox from '@mui/material/Checkbox';
import Modal from '@mui/material/Modal';
import Paper from '@mui/material/Paper';
import Stack from '@mui/material/Stack';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { PrimaryBtn } from 'components/UI/Buttons';
import { useEditClientQuestionMutation, useGetOneClientQuestionsQuery } from 'services/api/clientQuestions.api';

interface Props {
  setOpen: (open: boolean) => void;
  clientQuestionId: number;
}

const ClientQuestionModal: FC<Props> = ({ setOpen, clientQuestionId }) => {
  const [question, setQuestion] = useState('');
  const [answer, setAnswer] = useState('');
  const [generateNewMessage, setGenerateNewMessage] = useState(false);

  const [editClientQuestion, { data: clientQuestionEditResponse }] = useEditClientQuestionMutation();

  const { data: clientQuestionToEdit } = useGetOneClientQuestionsQuery({ clientQuestionId });

  useEffect(() => {
    if (clientQuestionToEdit) {
      setQuestion(clientQuestionToEdit.question);
      setAnswer(clientQuestionToEdit.answer || '');
    }
  }, [clientQuestionToEdit]);

  const allowGenerateNewMessage =
    clientQuestionToEdit?.createdAt &&
    new Date(clientQuestionToEdit.createdAt) > new Date(Date.now() - 24 * 60 * 60 * 1000);

  return (
    <Modal open onClose={() => setOpen(false)}>
      <Paper
        sx={{
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          width: '50%',
          maxHeight: '95%',
        }}
      >
        <Stack spacing={2} p={2}>
          {clientQuestionEditResponse ? (
            <>
              <Typography variant="h5">Se ha editado la pregunta con éxito!</Typography>
              <PrimaryBtn onClick={() => setOpen(false)}>Cerrar</PrimaryBtn>
            </>
          ) : (
            <>
              <TextField
                value={question}
                onChange={(e) => setQuestion(e.target.value)}
                label="Pregunta"
                placeholder={question}
                multiline
                maxRows={10}
              />
              <TextField
                value={answer}
                onChange={(e) => setAnswer(e.target.value)}
                label="Respuesta"
                multiline
                maxRows={10}
              />
              <Box>
                <Checkbox
                  checked={generateNewMessage}
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => setGenerateNewMessage(e.target.checked)}
                  disabled={!allowGenerateNewMessage}
                />
                Ocupar esta respuesta para generar un nuevo mensaje al cliente (solo se puede hacer para preguntas
                creadas hace menos de 24 horas)
              </Box>
              <Typography variant="caption" color="textSecondary">
                Esta respuesta se ocupará para responder a futuros clientes que hagan esta misma pregunta.
              </Typography>
              <PrimaryBtn
                disabled={question === null}
                onClick={() => {
                  if (question !== null) {
                    if (clientQuestionToEdit && clientQuestionId) {
                      editClientQuestion({
                        clientQuestionId,
                        question,
                        answer,
                        generateNewMessage: true,
                        // eslint-disable-next-line no-console
                      }).catch((e) => console.error(e));
                    }
                  }
                }}
              >
                Guardar
              </PrimaryBtn>
            </>
          )}
        </Stack>
      </Paper>
    </Modal>
  );
};

export default ClientQuestionModal;
