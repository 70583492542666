import React from 'react';
import { Link } from 'react-router-dom';

import { Box, Typography } from '@mui/material';

import useQuery from 'hooks/useQuery';
import Conversation from 'pages/Client/components/Chat';
import { useGetPublicClientMessagesQuery } from 'services/api/publicAccess.api';

const PublicChat = () => {
  const query = useQuery();
  const clientId = query.get('clientId');
  const token = query.get('token');
  if (!clientId || !token)
    return (
      <div>
        <p>Client ID or token not found</p>
      </div>
    );
  const { data: messages, error } = useGetPublicClientMessagesQuery({
    clientId: Number(clientId),
    token: String(token),
  });
  if (error) {
    return (
      <Box
        sx={{
          height: '100vh',
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <Typography variant="h4">
          El link al que estás tratando de acceder no es válido. Puede que haya vencido. Puedes intentar iniciando
          sesión
        </Typography>
        <Link to={`/clients/${clientId}`}>
          <Typography>Iniciar sesión</Typography>
        </Link>
      </Box>
    );
  }
  return (
    <Box sx={{ height: { xs: '93vh', md: '100vh' }, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
      <Box
        sx={{
          backgroundColor: '#007e69',
          borderBottom: '1px solid #e0e0e0',
          display: 'flex', // flex
          flexDirection: 'column',
          width: { xs: '100%', md: '60%' },
          flex: 1,
          p: 2,
        }}
      >
        <Box sx={{ display: 'flex', justifyContent: 'space-between' }}>
          <Box sx={{ flex: 10, alignItems: 'center' }}>
            <Typography color="white" variant="h6" textAlign="center">
              Chat del cliente
            </Typography>
          </Box>
          <Box sx={{ flex: 1 }}>
            <Link to={`/clients/${clientId}`} style={{ textDecoration: 'none' }}>
              <Typography color="white">Ver detalles</Typography>
            </Link>
          </Box>
        </Box>
      </Box>
      <Box
        sx={{
          backgroundImage: 'url("/whatsapp-background.png")',
          backgroundColor: '#efeae2',
          backgroundRepeat: 'repeat',
          backgroundSize: '412.5px 749.25px',
          width: { xs: '100%', md: '60%' },
          flex: 20,
          overflow: 'scroll',
        }}
      >
        <Box height="100%">
          <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between', height: '100%' }}>
            <Conversation
              messages={messages || []}
              isClientView
              refetch={() => {}}
              isFilteredConversation
              clientId={0}
            />
          </div>
        </Box>
      </Box>
    </Box>
  );
};

export default PublicChat;
