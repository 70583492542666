import React, { FC } from 'react';

import { Typography } from '@mui/material';

import type { AnalysisHistoryMessage, AskAssistantsResponse, SQLQueryResponse } from 'services/types/analysis';

import AskAssistantsResponseComponent from './AskAssistantsResponseComponent';
import ExecuteSQLResponseComponent from './ExecuteSQLResponseComponent';

interface Props {
  message: AnalysisHistoryMessage;
  history: AnalysisHistoryMessage[];
}

const ToolResponseComponent: FC<Props> = ({ message, history }: Props) => {
  const toolId = message.tool_call_id;
  if (!toolId) {
    return null;
  }
  const historyMessageWithToolCall = history.find((historyMessage) =>
    historyMessage.tool_calls?.find((toolCall) => toolCall.id === toolId)
  );
  if (!historyMessageWithToolCall) {
    return null;
  }
  const toolCallName = historyMessageWithToolCall.tool_calls?.find((toolCall) => toolCall.id === toolId)?.function.name;
  if (!toolCallName) {
    return null;
  }
  if (toolCallName === 'generateChart') {
    // The chart is generated using the tool call arguments in the previous message
    return null;
  }
  if (toolCallName === 'askAssistantsToReviewConversations') {
    let assistantData: AskAssistantsResponse;
    try {
      assistantData = JSON.parse(message.content) as AskAssistantsResponse;
    } catch (error) {
      return <Typography>Parece que hubo un error al procesar la respuesta</Typography>;
    }

    return <AskAssistantsResponseComponent assistantData={assistantData} />;
  }
  if (toolCallName === 'executeSQL' || toolCallName === 'searchMessages') {
    let queryData: SQLQueryResponse[];
    try {
      queryData = JSON.parse(message.content) as SQLQueryResponse[];
    } catch (error) {
      return <Typography>Parece que hubo un error al procesar la respuesta</Typography>;
    }
    return <ExecuteSQLResponseComponent queryData={queryData} />;
  }

  // Should not get here
  return <Typography>Parece que hubo un error al procesar la respuesta</Typography>;
};

export default ToolResponseComponent;
