import { PromptSection, PromptSectionDraft, PromptSuggestEditDraft } from 'services/types/promptSections';

import baseApi from './baseApi';

const promptSectionsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getPromptSections: build.query({
      query: (params: { projectId: number }) => ({
        url: `/promptSections?projectId=${params.projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: PromptSection[]) => response,
    }),
    createPromptSection: build.mutation({
      query: (params: { projectId: number; promptSection: PromptSectionDraft }) => ({
        url: `/promptSections?projectId=${params.projectId}`,
        method: 'POST',
        body: params.promptSection,
      }),
      transformResponse: (response: PromptSection) => response,
    }),
    suggestEditPromptSection: build.mutation({
      query: (params: { projectId: number; promptSection: PromptSuggestEditDraft }) => ({
        url: `/promptSections/suggestion?projectId=${params.projectId}`,
        method: 'POST',
        body: params.promptSection,
      }),
      transformResponse: (response: PromptSection) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetPromptSectionsQuery, useCreatePromptSectionMutation, useSuggestEditPromptSectionMutation } =
  promptSectionsApi;
