import React, { FC, useEffect, useState } from 'react';

import { Box, Typography, TextField, Button, Select, MenuItem, Checkbox } from '@mui/material';

import { useUpdateEditableAttributesMutation } from 'services/api/clients';
import { CLIENT_STATUSES, Client, EditableClientAttributes } from 'services/types/client';

interface Props {
  client: Client;
  refetch: () => void;
}

const ClientEditableAttributes: FC<Props> = ({ client, refetch }) => {
  const [clientDraft, setClientDraft] = useState<EditableClientAttributes>({
    notes: client.notes ?? '',
    status: client.status,
  });

  const [updateClientAttributes, { isLoading, error }] = useUpdateEditableAttributesMutation();

  const handleUpdateClientAttributes = (body: EditableClientAttributes) => {
    updateClientAttributes({ id: client.id, body })
      .then(() => refetch())
      .catch(() => {
        alert('Ocurrió un error al actualizar los atributos del cliente'); // eslint-disable-line no-alert
      });
  };

  useEffect(() => {
    if (error) {
      console.log(error, 'error'); // eslint-disable-line no-console
      alert('Ocurrió un error al actualizar los atributos del cliente'); // eslint-disable-line no-alert
    }
  }, [error]);
  return (
    <Box>
      <Typography variant="subtitle1" fontWeight="bold" mb={1}>
        Feedback vendedor
      </Typography>
      <Box display="flex" flexDirection="row" alignItems="center">
        <Typography variant="subtitle2" pr={1} flex={1}>
          Sin IA
        </Typography>
        <Checkbox
          checked={client.disabledAutomaticResponses}
          onChange={(e) => {
            handleUpdateClientAttributes({ disabledAutomaticResponses: e.target.checked });
          }}
        />
      </Box>
      {client.project.name === 'Buydepa' && (
        <>
          <Typography variant="subtitle2" pr={1} flex={1}>
            Estado (manual):
          </Typography>
          <Select
            fullWidth
            type="select"
            value={clientDraft.status ?? ''}
            onChange={(e) =>
              setClientDraft({ ...clientDraft, status: e.target.value as EditableClientAttributes['status'] })
            }
          >
            {CLIENT_STATUSES.map((status) => (
              <MenuItem key={status} value={status}>
                {status || '-- No definido --'}
              </MenuItem>
            ))}
          </Select>
        </>
      )}
      <Typography variant="subtitle2" pr={1} flex={1}>
        Comentarios
      </Typography>

      <TextField
        multiline
        rows={4}
        fullWidth
        value={clientDraft.notes}
        onChange={(e) => setClientDraft({ ...clientDraft, notes: e.target.value })}
      />
      <Box display="flex" flexDirection="row" justifyContent="flex-end" alignItems="center" mt={2} mb={1}>
        <Button
          onClick={() => handleUpdateClientAttributes(clientDraft)}
          sx={{
            width: 100,
            height: 30,
            alignSelf: 'flex-end',
            border: '1px solid #1C1C3A',
            borderRadius: '20px',
            mt: 1,
          }}
          disabled={isLoading}
        >
          Guardar
        </Button>
      </Box>
    </Box>
  );
};

export default ClientEditableAttributes;
