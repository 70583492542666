import { EditProjectBody, Project, MetricsObject } from '../types/project';
import baseApi from './baseApi';

const projectApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getProject: build.query({
      query: () => ({
        url: '/user/project',
        method: 'GET',
      }),
      transformResponse: (response: Project | null): Project | null => response,
    }),
    adminGetProject: build.query({
      query: (projectId: number) => ({
        url: `/admin/projects/${projectId}`,
        method: 'GET',
      }),
      transformResponse: (response: Project | null): Project | null => response,
    }),
    getProjects: build.query({
      query: () => ({
        url: `/admin/projects`,
        method: 'GET',
      }),
      transformResponse: (response: Project[]): Project[] =>
        response
          .map((project) => ({
            project,
            sortValue1: project.masterProject ? project.masterProject.name : project.name,
            sortValue2: project.masterProjectId ? project.name : '',
          }))
          .sort((a, b) => {
            if (a.sortValue1 === b.sortValue1) {
              return a.sortValue2 > b.sortValue2 ? 1 : -1;
            }
            return a.sortValue1 > b.sortValue1 ? 1 : -1;
          })
          .map((project) => project.project),
    }),
    getProjectMetrics: build.query({
      query: (params: {
        fromDate: string;
        toDate: string;
        specificProjectId?: number | null;
        specificSubProjectId?: number | null;
      }) => ({
        url: 'user/project/metrics',
        method: 'GET',
        params,
      }),
      transformResponse: (response: MetricsObject): MetricsObject => response, // Should I specify the type here?
    }),
    adminGetProjectWithId: build.mutation({
      query: (id: number) => ({
        url: `/admin/projects/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Project): Project => response,
    }),
    editProject: build.mutation({
      query: (props: { id: number; body: EditProjectBody }) => ({
        url: `/admin/projects/${props.id}`,
        method: 'PATCH',
        body: props.body,
      }),
      transformResponse: (response: Project): Project => response,
    }),
    adminGetProjectAnalytics: build.mutation({
      query: (id: number) => ({
        url: `/admin/projects/${id}/analytics`,
        method: 'GET',
      }),
      transformResponse: (response: {
        totalMessagesCost: number;
        totalFloidRequestsCost: number;
        totalCost: number;
        totalClients: number;
      }): { cost: number; clientsCount: number } => ({ cost: response.totalCost, clientsCount: response.totalClients }),
    }),
    deleteProjectImage: build.mutation({
      query: (props: { projectId: number; fileName: string }) => ({
        // replace spaces in file name with %20
        url: `/admin/projects/${props.projectId}/images/${props.fileName.replace(/ /g, '%20')}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
    }),
    uploadProjectImages: build.mutation({
      query: (props: { projectId: number; files: File[] }) => {
        const formData = new FormData();
        props.files.forEach((file) => {
          formData.append('file', file);
        });
        return {
          url: `/admin/projects/${props.projectId}/images`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => response,
    }),
    deleteProjectQuote: build.mutation({
      query: (props: { projectId: number; fileName: string }) => ({
        url: `/admin/projects/${props.projectId}/quotes/${props.fileName}`,
        method: 'DELETE',
      }),
      transformResponse: (response) => response,
    }),
    uploadProjectQuotes: build.mutation({
      query: (props: { projectId: number; files: File[] }) => {
        const formData = new FormData();
        props.files.forEach((file) => {
          formData.append('file', file);
        });
        return {
          url: `/admin/projects/${props.projectId}/quotes`,
          method: 'POST',
          body: formData,
        };
      },
      transformResponse: (response) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetProjectQuery,
  useGetProjectsQuery,
  useGetProjectMetricsQuery,
  useAdminGetProjectQuery,
  useAdminGetProjectWithIdMutation,
  useEditProjectMutation,
  useAdminGetProjectAnalyticsMutation,
  useDeleteProjectImageMutation,
  useUploadProjectImagesMutation,
  useDeleteProjectQuoteMutation,
  useUploadProjectQuotesMutation,
} = projectApi;
