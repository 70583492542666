import React, { FC, useState, useEffect } from 'react';

// import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';
import { Add, Delete } from '@mui/icons-material';
import { Box, TextField, IconButton, Grid } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import type { EditProjectBody, Project, PropertyType } from 'services/types/project';
import * as XLSX from 'xlsx';

interface PropertyTypesEditorProps {
  project: Project | null;
  // onSave recibe un id de un project y un body de tipo que tiene un array de objetos
  handleEditProject: (projectId: number, data: EditProjectBody) => void;
}

const PropertyTypesEditor: FC<PropertyTypesEditorProps> = ({ project, handleEditProject }) => {
  const [propertyTypesDraft, setPropertyTypesDraft] = useState<PropertyType[]>(project?.propertyTypes || []);

  useEffect(() => {
    setPropertyTypesDraft(project?.propertyTypes || []);
  }, [project]);

  const handleAddPropertyType = () => {
    setPropertyTypesDraft([...propertyTypesDraft, { name: '', ufPrice: 0, description: '' }]);
  };

  const handleDeletePropertyType = (index: number) => {
    const updatedPropertyTypes = [...(propertyTypesDraft || [])];
    updatedPropertyTypes.splice(index, 1);
    setPropertyTypesDraft(updatedPropertyTypes);
  };

  const handlePropertyTypeChange = (index: number, key: keyof PropertyType, value: string | number) => {
    const updatedPropertyTypes = [...propertyTypesDraft];
    updatedPropertyTypes[index] = {
      ...updatedPropertyTypes[index],
      [key]: value ?? (typeof value === 'number' ? 0 : ''),
    };
    setPropertyTypesDraft(updatedPropertyTypes);
  };

  const handleSavePropertyTypes = () => {
    if (project) {
      // Delete propertyTypes with empty fields
      let propertyTypesDraftFiltered = propertyTypesDraft.filter(
        (propertyType) => propertyType.name && propertyType.ufPrice && propertyType.description
      ) as PropertyType[] | null;
      if (propertyTypesDraftFiltered?.length === 0) {
        propertyTypesDraftFiltered = null;
      }
      handleEditProject(project.id, { propertyTypes: propertyTypesDraftFiltered });
    }
  };

  const handleFileUpload = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files?.[0];
    if (!file) {
      return;
    }
    const reader = new FileReader();
    reader.onload = (event) => {
      const data = new Uint8Array(event.target?.result as ArrayBuffer);
      const workbook = XLSX.read(data, { type: 'array' });
      const sheetName = workbook.SheetNames[0];
      const worksheet = workbook.Sheets[sheetName];

      // Get headers to check if the file has the correct format
      const rows = XLSX.utils.sheet_to_json(worksheet, { header: 1 });
      const headers = (rows[0] as string[]) || [];
      const expectedHeaders = ['name', 'ufPrice', 'description'];
      const isValidFormat = expectedHeaders.every((header, index) => header === headers[index]);
      if (!isValidFormat) {
        // eslint-disable-next-line no-alert
        alert(
          "Archivo no tiene el formato correcto. Asegúrate de incluir las columnas 'name', 'ufPrice', y 'description'."
        );
        return;
      }

      const jsonData = XLSX.utils.sheet_to_json<{ name: string; ufPrice: number; description: string }>(worksheet);
      const newPropertyTypes = jsonData.map((row) => {
        let { ufPrice } = row;
        if (typeof row.ufPrice !== 'number') {
          // eslint-disable-next-line no-alert
          alert(`El precio del tipo '${row.name}' debe ser un número.`);
          ufPrice = 0;
        }
        return {
          name: row.name || '',
          ufPrice,
          description: row.description || '',
        };
      });

      setPropertyTypesDraft(newPropertyTypes);
    };
    reader.readAsArrayBuffer(file);
  };

  return (
    <Box display="flex" flexDirection="column" gap={2} mt={1}>
      <Grid container spacing={2} alignItems="center">
        <Grid item xs={3}>
          Nombre
        </Grid>
        <Grid item xs={3}>
          Precio UF
        </Grid>
        <Grid item xs={5}>
          Descripción
        </Grid>
      </Grid>
      {propertyTypesDraft.map((propertyType, index) => (
        // eslint-disable-next-line react/no-array-index-key
        <Grid container spacing={2} alignItems="center" key={`propertyType-${index}`} maxHeight={56}>
          <Grid item xs={3}>
            <TextField
              label="Tipo"
              value={propertyType.name}
              onChange={(e) => handlePropertyTypeChange(index, 'name', e.target.value)}
              fullWidth
              inputProps={{ style: { padding: 10 } }}
            />
          </Grid>
          <Grid item xs={3}>
            <TextField
              type="number"
              value={propertyType.ufPrice}
              onChange={(e) => handlePropertyTypeChange(index, 'ufPrice', parseFloat(e.target.value))}
              fullWidth
              inputProps={{ style: { padding: 10 } }}
            />
          </Grid>
          <Grid item xs={5}>
            <TextField
              value={propertyType.description}
              onChange={(e) => handlePropertyTypeChange(index, 'description', e.target.value)}
              fullWidth
              inputProps={{ style: { padding: 10 } }}
            />
          </Grid>
          <Grid item xs={1}>
            <IconButton onClick={() => handleDeletePropertyType(index)} color="secondary">
              <Delete />
            </IconButton>
          </Grid>
        </Grid>
      ))}

      <div style={{ display: 'flex', flexDirection: 'row', alignItems: 'center', marginTop: 1 }}>
        <PrimaryBtn
          startIcon={<Add />}
          onClick={handleAddPropertyType}
          variant="contained"
          color="primary"
          sx={{ maxWidth: 200 }}
        >
          Agregar
        </PrimaryBtn>

        <PrimaryBtn
          onClick={handleSavePropertyTypes}
          variant="contained"
          color="primary"
          sx={{ marginLeft: 2, maxWidth: 200 }}
        >
          Guardar Tipos
        </PrimaryBtn>
      </div>

      <input type="file" accept=".xlsx, .xls" onChange={handleFileUpload} style={{ marginTop: '20px' }} />
    </Box>
  );
};

export default PropertyTypesEditor;
