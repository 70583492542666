/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';
import { useGetUserQuery } from 'services/api/user.api';

const Profile: FC = () => {
  const { data: user } = useGetUserQuery(null);

  return (
    <Box sx={{ px: { xs: 2, xl: 0 } }}>
      <SectionHeader text="Perfil" button={null} />

      <Typography variant="h6" pb="25px">
        Email: {user?.email}
      </Typography>
      <Typography variant="h6" pb="25px">
        {user?.isAdmin ? 'Administrador' : 'Usuario'}
      </Typography>
    </Box>
  );
};

export default Profile;
