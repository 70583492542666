import React, { FC, useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ArrowDropDown from '@mui/icons-material/ArrowDropDown';
import ArrowDropUp from '@mui/icons-material/ArrowDropUp';
import { Box, Button, Typography } from '@mui/material';

import AttributeComponent from 'components/common/AttributesComponent';
import type { Attribute } from 'components/common/AttributesComponent';
import useCurrentUser from 'hooks/useCurrentUser';
import { useAdminGetClientCostMutation, useUpdateAdminEditableAttributesMutation } from 'services/api/clients';
import { dateFormat } from 'services/format/dateFormat';
import priceFormat from 'services/format/priceFormat';
import type { Client, EditableAdminClientAttributes } from 'services/types/client';

import ClientEditableAttributes from './ClientEditableAttributes';

interface Props {
  client: Client;
  backButton: React.ReactNode;
  refreshButton: React.ReactNode;
  filterButton: React.ReactNode;
  refetch: () => void;
}

const ClientAttributeComponent = AttributeComponent<EditableAdminClientAttributes>;

const ClientAttributes: FC<Props> = ({ client, backButton, refreshButton, filterButton, refetch }) => {
  const [getClientCost, { data: clientCost }] = useAdminGetClientCostMutation();
  const formattedCost = clientCost ? priceFormat.format(clientCost) : '-';
  const currentUser = useCurrentUser();
  const [showAdminAttributes, setShowAdminAttributes] = useState<boolean>(false);
  const [updateClientAttributes, { isLoading }] = useUpdateAdminEditableAttributesMutation();

  const [isEditingAdminAttributes, setIsEditingAdminAttributes] = useState<boolean>(false);
  const [editableAdminAttributes, setEditableAdminAttributes] = useState<EditableAdminClientAttributes>({});

  const getExtension = (fileName: string): string => {
    return fileName.split(';')[0].split('.').at(-1) as string;
  };

  const clientFiles = client.messages
    ?.filter((message) => message.sender === 'client' && message.fileName && getExtension(message.fileName) !== 'ogg')
    .map((message) => message.fileName);

  const handleUpdateClientAttributes = () => {
    updateClientAttributes({ id: client.id, body: editableAdminAttributes })
      .then((value) => {
        if ('error' in value) {
          alert('Ocurrió un error al actualizar los atributos del cliente.'); // eslint-disable-line no-alert
          return;
        }
        setIsEditingAdminAttributes(false);
        refetch();
        alert('Los atributos del cliente se actualizaron correctamente'); // eslint-disable-line no-alert
      })
      .catch(() => {
        alert('Ocurrió un error al actualizar los atributos del cliente'); // eslint-disable-line no-alert
      });
  };

  let debts = '';
  if (client.floidRequestsCount === 0) {
    debts = '-';
  } else if (client.debts && client.debts.length > 0) {
    const debtsDetails = client.debts.reduce(
      (acc, debt) =>
        `${acc}\n${debt.institutionName}: ${priceFormat.format(debt.amount ?? 0)} hace ${debt.monthsBehind} meses.`,
      ''
    );
    debts = `(${client.debts.length}) ${debtsDetails}`;
  } else {
    debts = '0';
  }
  let salaryAttribute = client.salary ? priceFormat.format(client.salary) : '-';
  if (client.salarySupplement) {
    salaryAttribute = `${salaryAttribute} + ${priceFormat.format(client.salarySupplement)}`;
  }

  // boolean to store if the client's project is a masterProject
  const isFromMasterProject = client.project?.subProjects && client.project.subProjects.length > 0;

  const attributes: Attribute<EditableAdminClientAttributes>[] = [
    { name: 'Identificador', value: client.id },
    { name: 'Inmobiliaria', value: client.project?.name },
    { name: 'Proyecto asignado', value: client.subProject?.name ?? '-', show: isFromMasterProject },
    {
      name: 'Nombre',
      value: `${client.firstName} ${client.lastName ?? ''} ${client.motherLastName ?? ''}`,
    },
    { name: 'RUT', value: client.rut, editableColumn: 'rut' },
    { name: 'Email', value: client.email, editableColumn: 'email' },
    { name: 'Teléfono', value: client.phone, editableColumn: 'phone' },
    { name: 'Fecha de nacimiento', value: client.dateOfBirth?.split('T')[0], editableColumn: 'dateOfBirth' },
    { name: 'Género', value: client.gender?.toLowerCase(), editableColumn: 'gender' },
    { name: 'Nacionalidad', value: client.nationality?.toLowerCase(), editableColumn: 'nationality' },
    {
      name: 'Estado civil',
      value: client.maritalStatus?.toLowerCase(),
      editableColumn: 'maritalStatus',
    },
    { name: 'Fecha de creación', value: dateFormat(new Date(client.createdAt)) },
    { name: 'Ingresos', value: salaryAttribute },
    {
      name: 'Ahorros/Pie',
      value: client.savingsAmount ? priceFormat.format(client.savingsAmount) : '',
      editableColumn: 'savingsAmount',
      isNumber: true,
    },
    { name: 'Puntaje', value: client.score ?? 0 },
    { name: 'Deudas morosas', value: debts },
    { name: 'Cotizaciones', value: client.quote ?? '' },
    {
      name: 'Origen',
      value: client.source,
      editableColumn: 'source',
    },
    {
      name: 'Mensajes',
      value: client.messagesCount,
    },
    {
      name: 'Tipo contrato',
      show: !!client.project?.hasSaveEmployementDetailsFunctionality,
      value: client.employmentContractType,
      editableColumn: 'employmentContractType',
      placeholder: 'contrato indefinido, contrato temporal, independiente o cesante',
    },
    {
      name: 'Inicio contrato',
      show: !!client.project?.hasSaveEmployementDetailsFunctionality,
      value: client.employmentContractStartDate,
      editableColumn: 'employmentContractStartDate',
      placeholder: '2023-01',
    },
    {
      name: 'Tipo cliente',
      show: !!client.project?.hasScopeFunctionality,
      value: client.scope,
      editableColumn: 'scope',
      placeholder:
        client.project?.scopes && client.project?.scopes?.length > 0 ? client.project.scopes.join(',') : 'post-venta',
    },
  ];

  const adminAttributes: Attribute<EditableAdminClientAttributes>[] = [
    {
      name: 'Imágenes',
      value: client.imagesSent,
    },
    {
      name: 'Follow Ups',
      value: client.followUpsSent,
    },
    {
      name: 'Test',
      value: client.isTestClient ? 'Sí' : '',
      editableColumn: 'isTestClient',
      isBoolean: true,
    },
    {
      name: 'Sales Agent',
      value: client.isSalesAgent ? 'Sí' : '',
      editableColumn: 'isSalesAgent',
      isBoolean: true,
    },
    {
      name: 'Llamados Floid',
      value: client.floidRequestsCount,
    },
    {
      name: 'Detalles Floid',
      value: client.floidRequestsDetails
        ?.replace(/REGISTRO_CIVIL/g, 'RegCivil')
        .replace(/CAMARA_COMERCIO/g, 'CCS')
        .replace(/200/g, 'OK')
        .replace(/400/g, 'ERROR')
        .replace(/: /g, ':')
        .replace(/\s/g, ', '),
    },
    {
      name: 'Costos',
      value: formattedCost,
    },
    {
      name: 'Temas hablados',
      value: client.topics?.join(', '),
    },
    {
      name: 'Razón de término',
      value: client.endReason,
      editableColumn: 'endReason',
    },
    {
      name: 'Sueldo',
      value: client.salary,
      show: isEditingAdminAttributes,
      editableColumn: 'salary',
      isNumber: true,
    },
    {
      name: 'Complemento',
      value: client.salarySupplement,
      show: isEditingAdminAttributes,
      editableColumn: 'salarySupplement',
      isNumber: true,
    },
    {
      name: 'Proyecto Asignado',
      value: client.subProjectId,
      show: isEditingAdminAttributes,
      editableColumn: 'subProjectId',
      isNumber: true,
    },
    {
      name: 'Puntaje financiero',
      value: client.financialScore,
      isNumber: true,
    },
    {
      name: 'Puntaje interés',
      value: client.interestScore,
      isNumber: true,
    },
    {
      name: 'Puntaje IA',
      value: client.aiScore ? client.aiScore : '-',
    },
    {
      name: 'Puntaje Manual',
      value: client.manualScore,
      show: isEditingAdminAttributes,
      editableColumn: 'manualScore',
      isNumber: true,
    },
    {
      name: 'Nombre',
      value: client.firstName,
      show: isEditingAdminAttributes,
      editableColumn: 'firstName',
    },
    {
      name: 'Apellido Paterno',
      value: client.lastName,
      show: isEditingAdminAttributes,
      editableColumn: 'lastName',
    },
    {
      name: 'Apellido Materno',
      value: client.motherLastName,
      show: isEditingAdminAttributes,
      editableColumn: 'motherLastName',
    },
    {
      name: 'Objetivo al comprar',
      value: client.buyerType,
      editableColumn: 'buyerType',
    },
    {
      name: 'Mensaje inicial',
      value: client.prospectMessage,
      editableColumn: 'prospectMessage',
    },
    {
      name: 'Notas Administrador',
      value: client.adminNotes,
      editableColumn: 'adminNotes',
      isMultiline: true,
    },
    {
      name: 'Tipo de propiedad',
      value: client.selectedPropertyType,
    },
    {
      name: 'Puntajes recientes',
      // Leave only last two messages (for esthetic purposes)
      value: client.scoreHistory?.split(',').slice(-2).join(', '),
    },
  ];

  const viewMoreAttributesButton = (
    <Typography
      color="primary"
      alignSelf="center"
      sx={{ cursor: 'pointer', textDecoration: 'underline', display: currentUser?.isAdmin ? 'block' : 'none' }}
      onClick={() => {
        setShowAdminAttributes(!showAdminAttributes);
      }}
    >
      {showAdminAttributes ? 'Ver menos' : 'Ver más'}
    </Typography>
  );

  const [displayAttributes, setDisplayAttributes] = useState<boolean>(false);

  useEffect(() => {
    if (currentUser?.isAdmin) {
      getClientCost(client.id).catch((e) => console.error(e)); // eslint-disable-line no-console
    }
  }, [client.id]);

  return (
    <Box
      sx={{
        p: 2,
        maxWidth: { xs: '100vw', md: '40vw' },
        overflowY: 'auto',
        maxHeight: { xs: undefined, md: '100svh' },
      }}
    >
      <Box flexDirection="row" sx={{ mb: 0, ml: { xs: '40px', lg: 0 } }} display="flex" justifyContent="space-between">
        {backButton}
        {viewMoreAttributesButton}
        <Box display={{ xs: 'flex', md: 'none' }} alignItems="center" justifyContent="center">
          <Button onClick={() => setDisplayAttributes(!displayAttributes)}>
            {client.firstName} {client.lastName ?? ''}
            {displayAttributes ? <ArrowDropUp /> : <ArrowDropDown />}
          </Button>
        </Box>
        {refreshButton}
        {currentUser?.isAdmin ? filterButton : null}
      </Box>
      <Box
        flexDirection={{ xs: 'column', md: 'row' }}
        display={{ xs: displayAttributes ? 'flex' : 'none', md: 'flex' }}
        pr={1}
      >
        <Box>
          {attributes
            .filter((a) => a.show !== false)
            .map((attribute) => (
              <ClientAttributeComponent
                key={attribute.name}
                isEditing={isEditingAdminAttributes}
                attribute={attribute}
                valuesMapping={editableAdminAttributes}
                setValue={(value: string | number | boolean) => {
                  setEditableAdminAttributes({
                    ...editableAdminAttributes,
                    [attribute.editableColumn ?? '']: value,
                  });
                }}
              />
            ))}
          <ClientEditableAttributes client={client} refetch={refetch} />
          {(clientFiles?.length || 0) > 0 && (
            <Box display="flex" flexDirection="column" alignItems="flex-start" mt={2} mb={1}>
              <Typography variant="h6">Archivos</Typography>
              {clientFiles?.map((file) => (
                <Link key={file} to={`https://storage.googleapis.com/lidz-bucket/${file}`} target="_blank">
                  {file?.includes('document-') ? file?.split('document-').pop() : file?.split('-').pop()}
                </Link>
              ))}
            </Box>
          )}
        </Box>
        {showAdminAttributes && (
          <Box pl={3}>
            {adminAttributes.map((attribute) => (
              <ClientAttributeComponent
                key={attribute.name}
                isEditing={isEditingAdminAttributes}
                attribute={attribute}
                valuesMapping={editableAdminAttributes}
                setValue={(value: string | number | boolean) => {
                  setEditableAdminAttributes({
                    ...editableAdminAttributes,
                    [attribute.editableColumn ?? '']: value,
                  });
                }}
              />
            ))}

            <Box display="flex" flexDirection="row" justifyContent="space-between" alignItems="center" mt={2} mb={1}>
              <Button
                onClick={() => setIsEditingAdminAttributes(!isEditingAdminAttributes)}
                sx={{
                  width: 100,
                  height: 30,
                  alignSelf: 'flex-end',
                  border: '1px solid #1C1C3A',
                  borderRadius: '20px',
                  mt: 1,
                }}
                disabled={isLoading}
              >
                {isEditingAdminAttributes ? 'Cancelar' : 'Editar'}
              </Button>
              <Button
                onClick={handleUpdateClientAttributes}
                sx={{
                  width: 100,
                  height: 30,
                  alignSelf: 'flex-end',
                  border: '1px solid #1C1C3A',
                  borderRadius: '20px',
                  mt: 1,
                }}
                disabled={isLoading || !isEditingAdminAttributes}
              >
                Guardar
              </Button>
            </Box>
          </Box>
        )}
      </Box>
      <Box
        display={{ xs: displayAttributes ? 'flex' : 'none', md: 'none' }}
        mb={2}
        alignItems="center"
        justifyContent="center"
      >
        <Button onClick={() => setDisplayAttributes(false)}>
          <ArrowDropUp />
        </Button>
      </Box>
    </Box>
  );
};

export default ClientAttributes;
