import React, { FC, useState, useEffect } from 'react';

import { Box, Select, MenuItem, FormControl, SelectChangeEvent } from '@mui/material';

import PrimaryBtn from 'components/UI/Buttons';
import type { Project } from 'services/types/project';

interface ScopesSelectorProps {
  project: Project | null;
  onSave: (selectedScopes: string[]) => void;
}

const ScopesSelector: FC<ScopesSelectorProps> = ({ project, onSave }) => {
  const [selectedScopes, setSelectedScopes] = useState<string[]>(project?.scopes || []);
  // Don't remove scopes, unless no project has it in the scopes attrubite
  const allowedScopes = ['post-venta', 'venta', 'arriendo', 'con-subsidio', 'sin-subsidio'];

  useEffect(() => {
    setSelectedScopes(project?.scopes || []);
  }, [project]);

  const handleScopeChange = (event: SelectChangeEvent<string | string[]>) => {
    setSelectedScopes(event.target.value as string[]);
  };

  const handleSaveScopes = () => {
    onSave(selectedScopes);
  };

  return (
    <Box mt={2}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl style={{ minWidth: '200px' }}>
          <Select
            multiple
            value={selectedScopes}
            onChange={handleScopeChange}
            renderValue={() => 'Seleccione Scopes'}
            displayEmpty
          >
            {allowedScopes.map((scope) => (
              <MenuItem
                key={scope}
                value={scope}
                sx={{
                  backgroundColor: selectedScopes.includes(scope) ? 'rgba(193, 235, 245, 1) !important' : 'inherit',
                }}
              >
                {scope}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
        <PrimaryBtn onClick={handleSaveScopes} sx={{ ml: 2 }}>
          Guardar
        </PrimaryBtn>
      </div>
    </Box>
  );
};

export default ScopesSelector;
