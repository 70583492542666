import React, { FC } from 'react';

import { Box } from '@mui/material';

import useCurrentUser from 'hooks/useCurrentUser';
import { useGetProjectQuery } from 'services/api/projects.api';

const Dashboard: FC = () => {
  const { data } = useGetProjectQuery(null);
  const currentUser = useCurrentUser();
  const defaultSrc = currentUser?.isAdmin
    ? 'https://forms.monday.com/forms/embed/b4f59ae3596601458d45eeb623001cfd?r=use1'
    : 'https://forms.monday.com/forms/embed/605e993de8d36c928738b9ce90d684f3?r=use1';
  const src = data?.formRequestUrl || defaultSrc;

  return (
    <Box
      sx={{
        height: '100vh',
        width: '100%',
        justifyContent: 'center',
        alignItems: 'center',
        display: 'flex',
      }}
    >
      <iframe
        title="Formulario de contacto"
        src={src}
        width="100%"
        height="100%"
        style={{ border: 0, boxShadow: '5px 5px 56px 0px rgba(0,0,0,0.25)' }}
      />
    </Box>
  );
};

export default Dashboard;
