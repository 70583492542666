import { Analysis } from 'services/types/analysis';

import baseApi from './baseApi';

const projectsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getAnalyses: build.query({
      query: () => ({
        url: '/userAnalyses/list-project-analyses',
        method: 'GET',
      }),
      transformResponse: (response: Analysis[]) => response,
    }),
    getAnalysis: build.query({
      query: (id: number) => ({
        url: `/userAnalyses/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Analysis) => response,
    }),
    addMessageToAnalysis: build.mutation({
      query: (props: { message: string; id: number }) => ({
        url: `userAnalyses/${props.id}`,
        method: 'PATCH',
        body: { newMessage: props.message },
      }),
      transformResponse: (response: Analysis) => response,
    }),
    editTitleToAnalysis: build.mutation({
      query: (props: { query: string; id: number }) => ({
        url: `userAnalyses/${props.id}`,
        method: 'PATCH',
        body: { query: props.query },
      }),
      transformResponse: (response: Analysis) => response,
    }),
    createAnalysis: build.mutation({
      query: (props: { query: string; projectId?: number }) => ({
        url: 'userAnalyses',
        method: 'POST',
        body: { query: props.query, projectId: props.projectId },
      }),
      transformResponse: (response: Analysis) => response,
    }),
  }),
  overrideExisting: false,
});

export const {
  useGetAnalysesQuery,
  useGetAnalysisQuery,
  useAddMessageToAnalysisMutation,
  useEditTitleToAnalysisMutation,
  useCreateAnalysisMutation,
} = projectsApi;
