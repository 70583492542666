import { createSlice } from '@reduxjs/toolkit';

import { User } from '../types/user';
import { Action } from './base';

const initialState: User = {
  id: 0,
  email: '',
  accessToken: '',
  isAdmin: false,
  projectId: 0,
};

interface UserAction extends Action {
  payload: User;
}

const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    signInUser(state: User, action: UserAction) {
      return {
        ...state,
        ...action.payload,
      };
    },
    signUpUser(state: User, action: UserAction) {
      return {
        ...state,
        ...action.payload,
      };
    },
    signOutUser() {
      return {
        ...initialState,
      };
    },
  },
});

export const { signInUser, signUpUser, signOutUser } = userSlice.actions;
export default userSlice.reducer;
