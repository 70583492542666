import React, { FC, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';

import { Box, TextField } from '@mui/material';
import FormControl from '@mui/material/FormControl';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';

import { SearchIcon } from 'assets/icons';
import useCurrentUser from 'hooks/useCurrentUser';
import { useGetProjectsQuery } from 'services/api/projects.api';

interface Props {
  searchTerm: string;
  onSearchTermChange: (searchTerm: string) => void;
  projectId: string;
  onProjectIdChange: (projectId: string) => void;
}

const SearchBar: FC<Props> = ({ searchTerm, onSearchTermChange, projectId, onProjectIdChange }) => {
  const currentUser = useCurrentUser();
  const { data: projects } = currentUser?.isAdmin ? useGetProjectsQuery(null) : { data: undefined };
  const navigate = useNavigate();

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    params.set('searchTerm', searchTerm);
    params.set('projectId', projectId);
    navigate(`?${params.toString()}`);
  }, [searchTerm, projectId]);

  return (
    <Box flexDirection={{ xs: 'column', md: 'row' }} display="flex" width="100%">
      <TextField
        type="text"
        placeholder="Buscar"
        onChange={(e) => onSearchTermChange(e.target.value)}
        value={searchTerm}
        sx={{
          '& .MuiInputBase-input': {
            fontSize: '14px',
            color: 'text.secondary',
            fontWeight: 'bold',
          },
          '& .MuiInputBase-root': {
            border: '1px solid #E5E5E5',
            borderRadius: '10px',
            padding: '0 10px',
          },
          minWidth: 200,
          mr: 2,
        }}
        InputProps={{
          endAdornment: <SearchIcon />,
        }}
      />
      {currentUser?.isAdmin && (
        <FormControl sx={{ minWidth: 250, paddingRight: '20px' }}>
          <InputLabel id="project-select-label">Proyecto</InputLabel>
          <Select
            label="Proyecto"
            variant="outlined"
            onChange={(e) => onProjectIdChange(e.target.value)}
            value={projectId}
            autoWidth
          >
            <MenuItem value="">Todos los proyectos</MenuItem>
            {projects
              ?.filter((project) => project.masterProjectId === null)
              .map((project) => (
                <MenuItem key={project.id} value={project.id}>
                  {project.name}
                </MenuItem>
              ))}
          </Select>
        </FormControl>
      )}
    </Box>
  );
};

export default SearchBar;
