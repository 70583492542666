import React from 'react';

import { Grid, TextField, Typography, Checkbox, Box } from '@mui/material';

export type Attribute<T> = {
  name: string;
  value: string | number | boolean | null | undefined;
  show?: boolean;
  editableColumn?: keyof T;
  isNumber?: boolean;
  isBoolean?: boolean;
  isMultiline?: boolean;
  description?: string;
  placeholder?: string;
};

interface AttributeComponentProps<T> {
  isEditing: boolean;
  attribute: Attribute<T>;
  valuesMapping: T;
  setValue: (value: string | number | boolean) => void;
}

const AttributeComponent: <T>(props: AttributeComponentProps<T>) => React.ReactElement = ({
  isEditing,
  attribute,
  valuesMapping,
  setValue,
}) => {
  const { name, value, editableColumn, isNumber, isBoolean, show, isMultiline, description } = attribute;
  if (show === false) return <div />;
  return (
    <Grid item py="2px" flexDirection="row" display="flex" justifyContent="space-between">
      <Box>
        <Typography variant="subtitle2" pr={2} flex={1} sx={{ wordBreak: 'none' }}>
          {name}
        </Typography>
        {description ? <Typography variant="caption">{description}</Typography> : null}
      </Box>
      {isEditing && editableColumn !== undefined && isBoolean ? (
        <Checkbox
          checked={Boolean(valuesMapping[editableColumn] ?? value ?? false)}
          onChange={(e) => setValue(Boolean(e.target.checked))}
        />
      ) : null}
      {isEditing && editableColumn !== undefined && !isBoolean ? (
        <TextField
          value={valuesMapping[editableColumn] ?? value ?? ''}
          type={isNumber ? 'number' : 'text'}
          onChange={(e) => setValue(isNumber ? Number(e.target.value) : e.target.value)}
          multiline={isMultiline}
          rows={isMultiline ? 4 : 1}
          placeholder={attribute.placeholder}
        />
      ) : null}
      {!isEditing || editableColumn === undefined ? (
        <Typography variant="body1" fontWeight={600} maxWidth="100%">
          {value}
        </Typography>
      ) : null}
    </Grid>
  );
};

export default AttributeComponent;
