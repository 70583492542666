/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import jwtDecode from 'jwt-decode';

import { RootState } from './redux/store';

const deleteUser = () => {
  const persist = localStorage.getItem('persist:root');
  if (persist) {
    const parsedPersist = JSON.parse(persist);
    localStorage.setItem('persist:root', JSON.stringify({ ...parsedPersist, user: null }));
  }
};

export const getUser = () => {
  const persist = localStorage.getItem('persist:root');
  if (!persist) {
    return null;
  }
  const parsedPersist: RootState = JSON.parse(persist);
  return parsedPersist.user;
};

export const checkIfTokenIsValid = (user: RootState['user'] | null) => {
  try {
    if (user) {
      const decodedJwt: { exp: number } = jwtDecode(user.accessToken);
      if (decodedJwt.exp >= Date.now() / 1000) {
        return true;
      }
      deleteUser();
    }
    return false;
  } catch (error) {
    return false;
  }
};
