import * as React from 'react';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LineChart } from '@mui/x-charts/LineChart';

type MetricsBarChartProps = {
  type: string;
  dataSeries: Record<string, number>[];
  labels: string[];
};

export const MultipleLineChart: React.FC<MetricsBarChartProps> = ({ type, dataSeries, labels }) => {
  const series = dataSeries.map((data, index) => ({
    data: Object.values(data).filter((value) => value !== null),
    label: labels && labels[index],
  }));

  // Join keys of all dataSeries to get all categories
  const allCategories = dataSeries.reduce((acc, data) => {
    Object.keys(data).forEach((key) => {
      if (!acc.includes(key)) {
        acc.push(key);
      }
    });
    return acc;
  }, [] as string[]);
  return (
    <Card sx={{ my: 2 }}>
      <CardContent>
        {dataSeries ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <LineChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: allCategories,
                  scaleType: 'band',
                },
              ]}
              series={series}
              height={300}
              colors={[
                '#8884d8',
                '#82ca9d',
                '#ffc658',
                '#ff7300',
                '#e6194B',
                '#f58231',
                '#ffe119',
                '#bfef45',
                '#3cb44b',
                '#42d4f4',
                '#4363d8',
                '#911eb4',
                '#f032e6',
                '#a9a9a9',
                '#000000',
              ]}
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export default MultipleLineChart;
