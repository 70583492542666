import React from 'react';
import { useNavigate } from 'react-router-dom';

import Box from '@mui/material/Box';
import { DataGrid, GridColDef } from '@mui/x-data-grid';

import PrimaryBtn from 'components/UI/Buttons';
import SectionHeader from 'components/layout/SectionHeader';
import { useGetPropertiesQuery } from 'services/api/adminProperties.api';
import type { Property } from 'services/types/property';

const Properties = () => {
  const { data: properties } = useGetPropertiesQuery(null);
  const navigate = useNavigate();

  const navigateToPropertyId = (id: number, newPage = false) => {
    if (newPage) {
      window.open(`/properties/${id}`, '_blank');
      return;
    }
    navigate(`/properties/${id}`);
  };

  const columns: GridColDef[] = [
    { field: 'id', width: 70 },
    {
      field: 'updatedAt',
      width: 110,
      valueFormatter: (value: string) => new Date(value).toLocaleString().split(',')[0],
    },
    { field: 'projectId', width: 80 },
    { field: 'projectReferenceId' },
    { field: 'projectReferenceSKU', width: 120 },
    { field: 'address', width: 200 },
    { field: 'commune', width: 150 },
    { field: 'floor', width: 60 },
    { field: 'latitude' },
    { field: 'longitude' },
    { field: 'bedrooms', width: 60, headerName: 'Hab.' },
    { field: 'bathrooms', width: 60, headerName: 'Baños' },
    { field: 'parkingLotReference' },
    { field: 'storageReference' },
    { field: 'year', width: 70, headerName: 'Año' },
    { field: 'price', width: 80 },
    { field: 'parkingLotPrice' },
    { field: 'storagePrice' },
    { field: 'totalPrice' },
    { field: 'rentPrice' },
    { field: 'yearExpenses' },
    { field: 'interiorSize', width: 60, headerName: 'm2' },
    { field: 'terraceSize' },
    { field: 'builder', width: 150 },
    { field: 'orientation' },
    { field: 'description' },
    { field: 'delivery' },
    { field: 'capRate' },
    { field: 'subwayDistance' },
    { field: 'closestSubway' },
    { field: 'discountPercentage' },
    { field: 'downPaymentBonusPercentage' },
    { field: 'downPaymentInstallments' },
    { field: 'has15PercentagePromotion' },
    { field: 'reservationAmount' },
    { field: 'url' },
    { field: 'available' },
  ];

  return (
    <Box>
      <SectionHeader
        text="Lista de Propiedades"
        button={<PrimaryBtn onClick={() => navigate('/properties/new')}>Crear Propiedad</PrimaryBtn>}
      />
      <Box sx={{ height: 'calc(100vh - 100px)', color: 'black' }}>
        {properties && (
          <DataGrid
            rows={properties}
            columns={columns}
            onRowClick={(params, event) => {
              navigateToPropertyId((params.row as Property).id, event.ctrlKey || event.metaKey);
            }}
          />
        )}
      </Box>
    </Box>
  );
};

export default Properties;
