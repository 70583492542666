import { Message } from '../types/message';
import baseApi from './baseApi';

const publicAccessApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createDemoChatClient: build.mutation({
      query: (props: { companyIdentifier: string }) => ({
        url: '/demoChat/clients',
        method: 'POST',
        body: {
          companyIdentifier: props.companyIdentifier,
        },
      }),
      transformResponse: (response: { id: string }) => response,
    }),
    getDemoClientMessages: build.query({
      query: (props: { clientPhoneIdentifier: string }) => ({
        url: `/demoChat/messages?clientPhoneIdentifier=${props.clientPhoneIdentifier}`,
        method: 'GET',
      }),
      transformResponse: (response: Message[]) => response,
    }),
    createDemoChatMessage: build.mutation({
      query: (props: { clientPhoneIdentifier: string; message: string }) => ({
        url: '/demoChat/messages',
        method: 'POST',
        body: props,
      }),
      transformResponse: (response: { status: string }) => response,
    }),
  }),
  overrideExisting: false,
});

export const { useGetDemoClientMessagesQuery, useCreateDemoChatClientMutation, useCreateDemoChatMessageMutation } =
  publicAccessApi;
