import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBack, FilterAlt } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box, Grid } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { useGetClientMutation } from 'services/api/clients';

import Chat from './components/Chat';
import ClientAttributes from './components/ClientAttributes';

const ClientDetailsCard: FC = () => {
  const [getClient, { isLoading, data: client }] = useGetClientMutation();
  const { id } = useParams<{ id: string }>();
  const [isFilteredConversation, setIsFilteredConversation] = React.useState(true);

  const refetch = () => {
    if (id) {
      // eslint-disable-next-line no-console
      getClient(Number(id)).catch(console.warn);
    }
  };

  useEffect(() => {
    refetch();
  }, [getClient, id]);

  const navigate = useNavigate();
  const backButton = (
    <IconButton onClick={() => navigate(-1)}>
      <ArrowBack />
    </IconButton>
  );
  const refreshButton = (
    <IconButton onClick={() => refetch()} size="large">
      <RefreshIcon />
    </IconButton>
  );
  const filterButton = (
    <IconButton
      onClick={() => {
        setIsFilteredConversation(!isFilteredConversation);
      }}
    >
      <FilterAlt color={isFilteredConversation ? 'primary' : 'inherit'} />
    </IconButton>
  );

  if (isLoading) {
    return <Box sx={{ p: 3, pl: '60px' }}>Cargando...</Box>;
  }
  if (!client) {
    return <Box sx={{ p: 3, pl: '60px' }}>Client not found</Box>;
  }
  return (
    <Box p={0} sx={{}} textAlign={{ xs: 'left' }}>
      <Grid sx={{ display: 'flex', flexDirection: { xs: 'column', md: 'row' } }}>
        <ClientAttributes
          client={client}
          backButton={backButton}
          refreshButton={refreshButton}
          filterButton={filterButton}
          refetch={refetch}
        />
        <Chat
          messages={client.messages ?? []}
          refetch={refetch}
          clientId={client.id}
          isFilteredConversation={isFilteredConversation}
        />
      </Grid>
    </Box>
  );
};

export default ClientDetailsCard;
