import { createTheme } from '@mui/material/styles';

const defaultTheme = createTheme({
  palette: {
    primary: {
      main: '#40BCDA', // light blue
      light: '#C1EBF5',
      contrastText: '#fff',
    },
    secondary: {
      main: '#FAD000', // yellow
      light: '#F9FAFD',
      contrastText: '#1C1C3A',
    },
    newBlue: {
      A100: '#6293C8',
      A400: '#2A578B',
      A700: '#1F4168',
    },
    newCyan: {
      A100: '#6DBFD3',
      A400: '#40BCDA',
      A700: '#3CABC6',
    },
    newTeal: {
      A100: '#26859C',
      A400: '#226170',
      A700: '#1A424B',
    },
    newLightBlue: {
      A100: '#C1EBF5',
      A400: '#9EC5EC',
      A700: '#6BA5E2',
    },
    background: {
      default: '#FFFFFF',
      paper: '#FFFFFF',
    },
    text: {
      primary: '#0070c0', // main dark blue
      secondary: '#202020',
      disabled: '#A6A7A8',
    },
    success: {
      main: '#53B721', // green
      light: '#E2F4D9', // 'rgba(114, 210, 66, 0.2)',
      contrastText: '#53B721',
    },
    error: {
      main: '#E7233B', // red
      light: '#FAD4D7', // 'rgba(231, 35, 59, 0.2)',
      contrastText: '#fff',
    },
    warning: {
      main: '#CF5C09', // orange
      light: '#FEE6D7', // 'rgba(255, 141, 59, 0.2)',
      contrastText: '#CF5C09',
    },
    info: {
      main: '#40BCDA', // light blue
      light: '#C1EBF5', // 'rgba(64, 188, 218, 0.2)',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: "'Jost','Roboto','sans-serif'",
    h2: {
      fontSize: '22px',
      fontWeight: 500,
      color: '#1C1C3A',
      lineHeight: 1.2,
    },
    h3: {
      fontSize: '20px',
      fontWeight: 700,
      color: '#202020',
      lineHeight: '32px',
    },
    h4: {
      fontSize: '19px',
      fontWeight: 400,
      color: '#202020',
      lineHeight: '30px',
    },
    h5: {
      fontSize: '17px',
      fontWeight: 400,
      color: '#A6A7A8',
      lineHeight: '24px',
    },
    body1: {
      fontSize: '18px',
      color: '#202020',
      fontWeight: 400,
      lineHeight: '26px',
    },
    body2: {
      fontSize: '16px',
      color: '#40BCDA',
      fontWeight: 600,
      lineHeight: '23px',
    },
  },
  breakpoints: {
    values: {
      xs: 0,
      sm: 600,
      md: 900,
      lg: 1300,
      xl: 1800,
    },
  },
  components: {
    // Name of the component
    MuiPaper: {
      defaultProps: {
        // The props to change the default for.
        elevation: 0, // No elevation for paper!
      },
    },
    MuiAlert: {
      styleOverrides: {
        standardSuccess: {
          backgroundColor: 'green',
          color: 'white',
        },
        standardError: {
          backgroundColor: 'red',
          color: 'white',
        },
        standardWarning: {
          backgroundColor: 'rgb(255, 244, 229)',
          color: 'rgb(102, 60, 0)',
          '& .MuiAlert-icon': {
            color: '#ff9800',
          },
          marginBottom: 2,
        },
        standardInfo: {
          backgroundColor: 'grey',
          color: 'black',
        },
      },
    },
    MuiCard: {
      styleOverrides: {
        root: {
          borderRadius: '12px',
          boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.1)',
        },
      },
    },
    MuiTooltip: {
      styleOverrides: {
        tooltip: {
          fontSize: '18px',
          fontFamily: 'Jost',
          fontWeight: 400,
        },
      },
    },
  },
});

export default defaultTheme;
