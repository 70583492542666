import * as React from 'react';

import { Typography } from '@mui/material';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import { LineChart } from '@mui/x-charts/LineChart';

type MetricsLineChartProps = {
  type: string;
  filteredData: Record<string, number | null>;
};

export const HoursMetricsLineChart: React.FC<MetricsLineChartProps> = ({ type, filteredData }) => {
  return (
    <Card>
      <CardContent>
        {filteredData ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <LineChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: Object.keys(filteredData).map((key) => key.padStart(2, '0')) || ['No hay datos'],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: Object.values(filteredData),
                },
              ]}
              yAxis={[
                {
                  id: 'y-axis',
                  scaleType: 'linear',
                  min: 0,
                },
              ]}
              height={300}
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};

export const DateMetricsLineChart: React.FC<MetricsLineChartProps> = ({ type, filteredData }) => {
  return (
    <Card>
      <CardContent>
        {filteredData ? (
          <Typography component="h2" variant="h6" color="text.primary" align="center">
            {type}
            <LineChart
              xAxis={[
                {
                  id: 'barCategories',
                  data: Object.keys(filteredData).map((key) => {
                    const [, month, day] = key.split('-');
                    return `${day}/${month}`;
                  }) || ['No hay datos'],
                  scaleType: 'band',
                },
              ]}
              series={[
                {
                  data: Object.values(filteredData),
                },
              ]}
              yAxis={[
                {
                  id: 'y-axis',
                  scaleType: 'linear',
                  min: 0,
                },
              ]}
              height={300}
            />
          </Typography>
        ) : (
          <Typography component="p" variant="h4">
            No hay datos para mostrar
          </Typography>
        )}
      </CardContent>
    </Card>
  );
};
