/* eslint-disable @typescript-eslint/ban-ts-comment */

/* eslint-disable @typescript-eslint/no-unsafe-assignment */
import React, { FC, useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Paper from '@mui/material/Paper';
import TextField from '@mui/material/TextField';
import Typography from '@mui/material/Typography';

import { SecondaryBtn } from 'components/UI/Buttons';
import LoadingOverlay from 'components/UI/LoadingOverlay';
import Error from 'pages/Error/ErrorAlert';
import { useCreateProspectMutation } from 'services/api/prospects.api';
import validateEmail from 'utils/validations/validateEmail';

import styles from './Contact.module.scss';

const SignIn: FC = () => {
  const [createProspect, { data, isLoading, isSuccess, isError, error }] = useCreateProspectMutation();
  const [emailInput, setEmailInput] = React.useState<string | null>(null);
  const [invalidEmail, setInvalidEmail] = React.useState(false);

  const handleSubmit = async (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    if (!validateEmail(emailInput ?? '')) {
      setInvalidEmail(true);
      return;
    }
    await createProspect({
      email: emailInput ?? '',
      reason: 'contact',
    });
  };

  useEffect(() => {
    if (data) {
      setEmailInput(null);
      setInvalidEmail(false);
    }
  }, [data]);

  return (
    <>
      <LoadingOverlay visible={isLoading} />
      {/* @ts-ignore */}
      <Error message={error?.data} isOpen={isError} />
      <Grid className={styles.MainGridContainer} container component="main">
        <Grid item xs={12} component={Paper} elevation={6} square>
          <Box
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: { xs: '100%', lg: '60%' },
              mx: 'auto',
              height: '100vh',
              alignItems: 'center',
              pt: '60px',
            }}
          >
            <img alt="Lidz" src="/logo.webp" height="200" />
            <Typography variant="h4" sx={{ m: 3 }}>
              Ingresa tu email y te contactaremos para hablar sobre tu proyecto
            </Typography>
            <Box
              sx={{
                bgcolor: '#F9FAFD',
                p: 2,
                borderRadius: '20px',
                // mt: 2,
                border: '1px solid hsla(210,18%,87%,1)',
                width: { xs: '88%', lg: '420px' },
              }}
              component="form"
              onSubmit={(e: React.FormEvent<HTMLFormElement>) => {
                handleSubmit(e).catch((err) => {
                  // eslint-disable-next-line no-console
                  console.error(err);
                });
              }}
            >
              <TextField
                id="email"
                margin="normal"
                required
                fullWidth
                label="Email"
                name="email"
                autoComplete="email"
                autoFocus
                sx={{ bgcolor: '#fff', display: isSuccess ? 'none' : 'block' }}
                onChange={(e) => {
                  setEmailInput(e.target.value);
                }}
                error={invalidEmail}
                helperText={invalidEmail ? 'Por favor, ingresa un email válido' : ''}
              />
              <SecondaryBtn
                sx={{ mt: 2, py: 1, mb: 1, display: isSuccess ? 'none' : 'block' }}
                type="submit"
                fullWidth
                variant="contained"
                color="secondary"
              >
                Enviar
              </SecondaryBtn>
              <Typography
                sx={{
                  display: isSuccess ? 'block' : 'none',
                  color: '#000',
                  textAlign: 'center',
                }}
              >
                ¡Gracias! Te contactaremos luego
              </Typography>
            </Box>
          </Box>
        </Grid>
      </Grid>
    </>
  );
};

export default SignIn;
