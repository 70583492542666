import baseApi from './baseApi';

const claveUnicaWhatsApp = baseApi.injectEndpoints({
  endpoints: (build) => ({
    claveUnicaWhatsApp: build.mutation({
      query: (body: { clientId: number; claveUnica: string }) => ({
        url: '/claveUnicaWhatsApp',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { status: string; projectPhone: string }) => response,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useClaveUnicaWhatsAppMutation } = claveUnicaWhatsApp;
