import * as React from 'react';

import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

import { ProblemIcon } from 'assets/icons';

interface AlertDialogInterface {
  openDialog: boolean;
  setOpenDialog: React.Dispatch<React.SetStateAction<boolean>>;
  header: string;
  confirmMsg: string;
  msg: string;
  confirmAction: (() => void) | (() => Promise<void>);
  cancelMsg?: string;
}

const AlertDialog: React.FC<AlertDialogInterface> = ({
  openDialog: open,
  setOpenDialog: setOpen,
  header,
  msg,
  confirmMsg,
  confirmAction,
  cancelMsg,
}) => {
  const handleClose = () => {
    setOpen(false);
  };

  const handleConfirm = () => {
    // if confirmAction is void just execute it
    // if confirmAction is Promise<void> execute it and catch the error
    if (typeof confirmAction === 'function') {
      // cast to void to avoid TS error
      const voidConfirmAction = confirmAction as () => void;
      voidConfirmAction();
    } else {
      const promiseConfirmAction = confirmAction as () => Promise<void>;
      promiseConfirmAction().catch((err) => console.error(err)); // eslint-disable-line no-console
    }
    setOpen(false);
  };

  return (
    <Dialog
      open={open}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
    >
      <DialogTitle id="alert-dialog-title" sx={{ display: 'flex', alignItems: 'center' }}>
        <ProblemIcon color="error" sx={{ mr: 1 }} /> {header}
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">{msg}</DialogContentText>
      </DialogContent>
      <DialogActions>
        {cancelMsg === undefined && <Button onClick={handleClose}>Cancelar</Button>}
        {cancelMsg && cancelMsg !== '' && <Button onClick={handleClose}>{cancelMsg}</Button>}
        <Button onClick={handleConfirm} autoFocus color="error">
          {confirmMsg}
        </Button>
      </DialogActions>
    </Dialog>
  );
};

AlertDialog.defaultProps = {
  cancelMsg: undefined,
};

export default AlertDialog;
