import React, { FC, useEffect, useState } from 'react';

import { Box, CircularProgress, Modal } from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Typography from '@mui/material/Typography';

import type { Attribute } from 'components/common/AttributesComponent';
import AttributeComponent from 'components/common/AttributesComponent';
// import getClients mutation
import { useGetClientsQuery } from 'services/api/clients';
import { useGeneratePromptMutation } from 'services/api/playground';
import type { Client } from 'services/types/client';
import type { Project } from 'services/types/project';
import prettifyPrompt from 'utils/prettifyPrompt';

const style = {
  position: 'absolute' as const,
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1200,
  maxHeight: '80%',
  bgcolor: 'background.paper',
  border: '2px solid #000',
  boxShadow: 24,
  overflow: 'scroll',
  p: 4,
};

interface PromptPlaygroundProps {
  projectId: number;
  masterProjectId: number;
  prompt: string;
  commonPrompt: string;
  subprojects: Project[];
  close: () => void;
}

const ClientAttributeComponent = AttributeComponent<Client>;

const PromptPlayground: FC<PromptPlaygroundProps> = (props: PromptPlaygroundProps) => {
  // useGetClientsQuery
  const { projectId, prompt, commonPrompt, masterProjectId, subprojects, close } = props;
  const [generatePrompt, { data, isLoading }] = useGeneratePromptMutation();
  const { data: clients } = useGetClientsQuery({
    offset: 0,
    limit: 20,
    filterModel: JSON.stringify({
      items:
        masterProjectId !== projectId
          ? [{ field: 'subProjectId', operator: 'equals', value: projectId.toString() }]
          : [],
    }),
    sortModel: '',
    searchTerm: '',
    projectId: masterProjectId.toString(),
  });
  const [client, setClient] = useState<Client | null>(null);

  useEffect(() => {
    if (client) {
      generatePrompt({
        prompt,
        client,
        commonPrompt,
        subprojectsDescriptions: subprojects.map((project) => ({
          id: project.id,
          description: project.description ?? '',
          name: project.name,
        })),
      }).catch(console.warn); // eslint-disable-line no-console
    }
  }, [client, prompt, commonPrompt, subprojects]);

  const clientsSelector = (
    <Select
      labelId="project-select-label"
      id="project-select"
      value={client?.id.toString() ?? ''}
      label="Proyecto"
      onChange={(event) => setClient(clients?.clients.find((x) => x.id === Number(event.target.value)) ?? null)}
    >
      {clients?.clients.map((clientElement) => (
        <MenuItem key={clientElement.id} value={clientElement.id}>
          <Typography>
            {clientElement.id} - {clientElement.firstName} {clientElement.lastName}
          </Typography>
        </MenuItem>
      ))}
    </Select>
  );

  const title = (
    <Typography variant="h2" mb={2}>
      <strong>Prompt Playground</strong>
    </Typography>
  );

  if (!client) {
    return (
      <Modal open onClose={close}>
        <Box sx={style}>
          {title}
          {clientsSelector}
        </Box>
      </Modal>
    );
  }

  const htmlPrompt = prettifyPrompt(data || '');
  // eslint-disable-next-line react/no-danger
  const prettyPrompt = <div id="dummy" className="original" dangerouslySetInnerHTML={{ __html: htmlPrompt }} />;

  const attributes: Attribute<Client>[] = [
    { name: 'messagesCount', value: client.messagesCount, editableColumn: 'messagesCount', isNumber: true },
    { name: 'email', value: client.email, editableColumn: 'email' },
    { name: 'rut', value: client.rut, editableColumn: 'rut' },
    { name: 'phone', value: client.phone, editableColumn: 'phone' },
    { name: 'firstName', value: client.firstName, editableColumn: 'firstName' },
    { name: 'lastName', value: client.lastName, editableColumn: 'lastName' },
    { name: 'motherLastName', value: client.motherLastName, editableColumn: 'motherLastName' },
    { name: 'savingsAmount', value: client.savingsAmount, editableColumn: 'savingsAmount', isNumber: true },
    { name: 'scope', value: client.scope, editableColumn: 'scope' },
    { name: 'employmentContractType', value: client.employmentContractType, editableColumn: 'employmentContractType' },
    {
      name: 'employmentContractStartDate',
      value: client.employmentContractStartDate,
      editableColumn: 'employmentContractStartDate',
    },
    // { name: 'vehicleValue', value: client.vehicleValue, editableColumn: 'vehicleValue', isNumber: true },
    // { name: 'housingStatus', value: client.housingStatus, editableColumn: 'housingStatus' },
    // { name: 'educationLevel', value: client.educationLevel, editableColumn: 'educationLevel' },
    // { name: 'degree', value: client.degree, editableColumn: 'degree' },
    // { name: 'workContract', value: client.workContract, editableColumn: 'workContract' },
    { name: 'salary', value: client.salary, editableColumn: 'salary', isNumber: true },
    { name: 'salarySupplement', value: client.salarySupplement, editableColumn: 'salarySupplement', isNumber: true },
    { name: 'totalSalary', value: client.totalSalary, editableColumn: 'totalSalary', isNumber: true },
    { name: 'dateOfBirth', value: client.dateOfBirth, editableColumn: 'dateOfBirth' },
    { name: 'gender', value: client.gender, editableColumn: 'gender' },
    { name: 'nationality', value: client.nationality, editableColumn: 'nationality' },
    { name: 'maritalStatus', value: client.maritalStatus, editableColumn: 'maritalStatus' },
    { name: 'score', value: client.score, editableColumn: 'score', isNumber: true },
    { name: 'projectId', value: client.projectId, editableColumn: 'projectId', isNumber: true },
    { name: 'lastMessageDate', value: client.lastMessageDate?.toString(), editableColumn: 'lastMessageDate' },
    { name: 'quote', value: client.quote, editableColumn: 'quote' },
    {
      name: 'clientMessagesCount',
      value: client.clientMessagesCount,
      editableColumn: 'clientMessagesCount',
      isNumber: true,
    },
    { name: 'imagesSent', value: client.imagesSent, editableColumn: 'imagesSent', isNumber: true },
    { name: 'followUpsSent', value: client.followUpsSent, editableColumn: 'followUpsSent', isNumber: true },
    { name: 'templatesSent', value: client.templatesSent, editableColumn: 'templatesSent', isNumber: true },
    {
      name: 'keepAliveMessagesSent',
      value: client.keepAliveMessagesSent,
      editableColumn: 'keepAliveMessagesSent',
      isNumber: true,
    },
    {
      name: 'floidRequestsCount',
      value: client.floidRequestsCount,
      editableColumn: 'floidRequestsCount',
      isNumber: true,
    },
    { name: 'floidRequestsDetails', value: client.floidRequestsDetails, editableColumn: 'floidRequestsDetails' },
    { name: 'isTestClient', value: client.isTestClient, editableColumn: 'isTestClient', isBoolean: true },
    {
      name: 'disabledAutomaticResponses',
      value: client.disabledAutomaticResponses,
      editableColumn: 'disabledAutomaticResponses',
      isBoolean: true,
    },
    { name: 'source', value: client.source, editableColumn: 'source' },
    { name: 'status', value: client.status, editableColumn: 'status' },
    { name: 'didBookVisit', value: client.didBookVisit, editableColumn: 'didBookVisit', isBoolean: true },
    { name: 'didVisit', value: client.didVisit, editableColumn: 'didVisit', isBoolean: true },
    { name: 'userScore', value: client.userScore, editableColumn: 'userScore', isNumber: true },
    { name: 'subProjectId', value: client.subProjectId, editableColumn: 'subProjectId', isNumber: true },
    { name: 'financialScore', value: client.financialScore, editableColumn: 'financialScore', isNumber: true },
    { name: 'interestScore', value: client.interestScore, editableColumn: 'interestScore', isNumber: true },
    { name: 'manualScore', value: client.manualScore, editableColumn: 'manualScore', isNumber: true },
    { name: 'prospectMessage', value: client.prospectMessage, editableColumn: 'prospectMessage' },
    { name: 'buyerType', value: client.buyerType, editableColumn: 'buyerType' },
    { name: 'aiSafetyStatus', value: client.aiSafetyStatus, editableColumn: 'aiSafetyStatus' },
    { name: 'charge', value: client.charge, editableColumn: 'charge', isBoolean: true },
    { name: 'isSalesAgent', value: client.isSalesAgent, editableColumn: 'isSalesAgent', isBoolean: true },
    { name: 'lastMessageRole', value: client.lastMessageRole, editableColumn: 'lastMessageRole' },
    { name: 'isDuplicate', value: client.isDuplicate, editableColumn: 'isDuplicate', isBoolean: true },
    { name: 'chatType', value: client.chatType, editableColumn: 'chatType' },
    { name: 'crmLastUpdatedAt', value: client.crmLastUpdatedAt?.toString(), editableColumn: 'crmLastUpdatedAt' },
    // { name: 'dHunterIds', value: client.dHunterIds?.toString(), editableColumn: 'dHunterIds' },
    {
      name: 'dHunterLastUpdatedAt',
      value: client.dHunterLastUpdatedAt?.toString(),
      editableColumn: 'dHunterLastUpdatedAt',
    },
    { name: 'hubspotDealId', value: client.hubspotDealId, editableColumn: 'hubspotDealId', isNumber: true },
    { name: 'hubspotId', value: client.hubspotId, editableColumn: 'hubspotId' },
    { name: 'planOkId', value: client.planOkId, editableColumn: 'planOkId' },
    { name: 'planOkClientId', value: client.planOkClientId, editableColumn: 'planOkClientId' },
    {
      name: 'planOkLastUpdatedAt',
      value: client.planOkLastUpdatedAt?.toString(),
      editableColumn: 'planOkLastUpdatedAt',
    },
    { name: 'subProjectHistory', value: client.subProjectHistory, editableColumn: 'subProjectHistory' },
    { name: 'webhookBodyAtCreation', value: client.webhookBodyAtCreation, editableColumn: 'webhookBodyAtCreation' },
    { name: 'notes', value: client.notes, editableColumn: 'notes' },
    { name: 'adminNotes', value: client.adminNotes, editableColumn: 'adminNotes' },
  ];

  return (
    <Modal open onClose={close}>
      <Box sx={style} display="flex" flexDirection="row">
        <Box mr={2} flex={1}>
          {title}
          {clientsSelector}
          {attributes
            .filter((a) => a.show !== false)
            .map((attribute) => (
              <ClientAttributeComponent
                key={attribute.name}
                isEditing
                attribute={attribute}
                valuesMapping={client}
                setValue={(value: string | number | boolean) => {
                  setClient({
                    ...client,
                    [attribute.editableColumn ?? '']: value,
                  });
                }}
              />
            ))}
        </Box>
        <Box flex={2}>
          {isLoading && <CircularProgress />}
          {prettyPrompt}
        </Box>
      </Box>
    </Modal>
  );
};

export default PromptPlayground;
