import { Message } from '../types/message';
import baseApi from './baseApi';

const messagesApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    getMessage: build.query({
      query: (id: number) => ({
        url: `admin/messages/${id}`,
        method: 'GET',
      }),
      transformResponse: (response: Message): Message => response,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGetMessageQuery } = messagesApi;
