const API_URL = import.meta.env.VITE_API_URL;
const rollbarAccessToken = import.meta.env.VITE_ROLLBAR_ACCESS_TOKEN;

if (rollbarAccessToken == null) {
  throw new Error('VITE_ROLLBAR_ACCESS_TOKEN env variable is not defined');
}

if (API_URL == null) {
  throw new Error('VITE_API_URL env variable is not defined');
}

const config = {
  apiUrl: API_URL,
  environment: import.meta.env.VITE_ENVIRONMENT || 'development',
  rollbarAccessToken,
};

export default config;
