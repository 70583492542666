import React, { FC, useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { ArrowBack } from '@mui/icons-material';
import RefreshIcon from '@mui/icons-material/Refresh';
import { Box } from '@mui/material';
import IconButton from '@mui/material/IconButton';

import { useGetPropertyMutation } from 'services/api/adminProperties.api';

import PropertyAttributes from './components/PropertyAttributes';

const PropertyDetailsCard: FC = () => {
  const [getProperty, { isLoading, data: property }] = useGetPropertyMutation();
  const { id } = useParams<{ id: string }>();
  const isNewProperty = id === 'new';

  const refetch = () => {
    if (!id) {
      return;
    }
    if (isNewProperty) {
      return;
    }
    // eslint-disable-next-line no-console
    getProperty(Number(id)).catch(console.warn);
  };

  useEffect(() => {
    refetch();
  }, [getProperty, id]);

  const navigate = useNavigate();
  const backButton = (
    <IconButton onClick={() => navigate(-1)}>
      <ArrowBack />
    </IconButton>
  );
  const refreshButton = (
    <IconButton onClick={() => refetch()} size="large">
      <RefreshIcon />
    </IconButton>
  );

  if (isLoading) {
    return <div>Cargando...</div>;
  }
  if (!property && !isNewProperty) {
    return <div>Property not found</div>;
  }
  return (
    <Box p={0} textAlign={{ xs: 'left' }} width="100%">
      <PropertyAttributes
        property={property}
        backButton={backButton}
        refreshButton={refreshButton}
        isNewProperty={isNewProperty}
      />
    </Box>
  );
};

export default PropertyDetailsCard;
