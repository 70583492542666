/* eslint-disable @typescript-eslint/no-unsafe-member-access */
import React, { FC } from 'react';

import { Box, Typography } from '@mui/material';

import SectionHeader from 'components/layout/SectionHeader';

const PrivacyPolicy: FC = () => {
  return (
    <Box sx={{ px: { xs: 8, xl: 5 } }}>
      <SectionHeader text="Políticas de Privacidad" button={null} />

      <Typography variant="h6" mt="100px" align="justify">
        INFORMACIÓN QUE RECOPILAMOS Y UTILIZAMOS
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>

      <Typography variant="body1" align="justify" pb="25px">
        Los Servicios utilizan la información que se recopila para operar, mantener y brindarle las características y la
        funcionalidad de estos, para analizar cómo se utilizan los Servicios, para realizar diagnósticos de servicios o
        para verificar problemas técnicos, mantener la seguridad, personalizar el contenido, recordar información para
        ayudarlo a acceder de manera eficiente a su cuenta, supervisar las métricas agregadas, como el número total de
        visitantes, el tráfico y los patrones demográficos, y realizar un seguimiento del Contenido y los usuarios según
        sea necesario para cumplir con la Ley de derechos de autor del milenio digital [de EE. UU.] y otras leyes
        aplicables.
      </Typography>

      <Typography variant="h6" align="justify">
        INFORMACIÓN QUE USTED PROPORCIONA
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Cuando usted se registra para obtener una cuenta en el Servicio o en los Servicios, nos proporciona información
        personal, como su nombre y dirección de correo electrónico, incluso si lo hace conectándose a los Servicios a
        través de un servicio de terceros, o «siguiendo», «haciéndose fan», añadiendo la aplicación “LIDZ AI”, el Agente
        de Chat o la aplicación Supervisor, etc., en un sitio web o red de terceros. Su nombre, dirección de correo
        electrónico y otra información que decida proporcionar, podrá ser vista y descubierta por otros usuarios, de
        acuerdo con su configuración de los Servicios. Podremos utilizar su dirección de correo electrónico para
        enviarle notificaciones relacionadas con los Servicios (incluidas las notificaciones exigidas por ley, en lugar
        de la comunicación por correo postal). También podremos utilizar su información de contacto para enviarle
        mensajes de marketing. Si no desea recibir estos mensajes, puede darse de baja siguiendo las instrucciones
        descritas en el correo electrónico. Si mantiene correspondencia con nosotros por correo electrónico, podremos
        conservar el contenido de sus mensajes de correo electrónico, su dirección de correo electrónico y la nuestra.
      </Typography>

      <Typography variant="h6" align="justify">
        CONOCIMIENTO
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        A través de los Conocimientos que publica en los Servicios, también nos proporciona su información. Su
        Conocimiento y los metadatos sobre su Conocimiento pueden ser vistos por otros usuarios de acuerdo con su
        configuración de los Servicios. LIDZ puede, pero no tiene obligación de supervisar el Conocimiento que usted
        publica en los Servicios. Podemos eliminar cualquier información que publique por cualquier motivo o sin motivo
        alguno. A menos que el Conocimiento se haga visible de acuerdo con su configuración en los Servicios, LIDZ y los
        empleados de LIDZ no verán su Conocimiento excepto: (i) para mantener, proporcionar o mejorar los Servicios;
        (ii) para ayudarle a resolver sus solicitudes de soporte; o (iii) en la medida en que LIDZ considere, a su
        exclusivo juicio, que era necesario para cumplir o evitar la violación de la ley o normativa aplicable o para
        cooperar con la aplicación de la ley.
      </Typography>

      <Typography variant="h6" align="justify">
        COOKIES
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Cuando utiliza el Servicio, podemos enviar una o más «cookies», un pequeño archivo de datos, a su computador
        para identificar de forma exclusiva su navegador y permitir a LIDZ ayudarle a iniciar sesión más rápidamente y
        mejorar su navegación por el sitio. Una cookie puede transmitirnos información anónima sobre cómo navega por los
        Servicios. Una cookie persistente permanece en su disco duro después de cerrar su navegador para que éste pueda
        utilizarla en posteriores visitas a los Servicios. Las cookies persistentes pueden eliminarse siguiendo las
        instrucciones de su navegador web. Una cookie de sesión es temporal y desaparece después de cerrar el navegador.
        Puede configurar su navegador para que rechace todas las cookies o para que le indique cuándo se envía una
        cookie. Sin embargo, es posible que algunas características de los Servicios no funcionen correctamente si se
        desactiva la posibilidad de aceptar cookies.
      </Typography>

      <Typography variant="h6" align="justify">
        ARCHIVOS DE REGISTRO
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Cuando utiliza el Servicio, podemos enviar una o más «cookies», un pequeño archivo de datos, a su ordenador para
        identificar de forma exclusiva su navegador y permitir que LIDZ le ayude a iniciar sesión más rápidamente y a
        mejorar su navegación por el sitio. Una cookie puede transmitirnos información anónima sobre cómo navega por los
        Servicios. Una cookie persistente permanece en su disco duro después de cerrar su navegador para que éste pueda
        utilizarla en posteriores visitas a los servicios. Las cookies persistentes se pueden eliminar siguiendo las
        instrucciones de su navegador web. Una cookie de sesión es temporal y desaparece después de cerrar su navegador.
        Puede restablecer su navegador web para rechazar todas las cookies o para indicar cuándo se envía una cookie.
        Sin embargo, es posible que algunas características de los Servicios no funcionen correctamente si la capacidad
        de aceptar cookies está deshabilitada.
      </Typography>

      <Typography variant="h6" align="justify">
        INFORMACIÓN DE UBICACIÓN GEOGRÁFICA
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Cuando utiliza los Servicios por o a través de un dispositivo móvil, podemos acceder, recopilar, monitorear y/o
        almacenar «datos de ubicación» de forma remota , que pueden incluir coordenadas de GPS (por ejemplo, latitud y/o
        longitud) o información similar con respecto a la ubicación de su dispositivo móvil. Los datos de ubicación
        pueden transmitirnos información sobre cómo navega por los Servicios. Los datos de ubicación no recopilan ni
        comparten ninguna información de identificación personal sobre usted. Los datos de ubicación pueden usarse junto
        con información de identificación personal. Es posible que algunas funciones de los Servicios, en particular los
        servicios basados en la ubicación, no funcionen correctamente si el uso o la disponibilidad de los datos de
        ubicación se ven afectados o deshabilitados.
      </Typography>

      <Typography variant="h6" align="justify">
        IDENTIFICADORES DE DISPOSITIVOS
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Cuando accede a los Servicios a través de un dispositivo móvil, podemos acceder, recopilar, monitorear y/o
        almacenar de forma remota uno o más «identificadores de dispositivos». Los identificadores de dispositivos son
        pequeños archivos de datos o estructuras de datos similares almacenadas o asociadas con su dispositivo móvil,
        que identifican de manera única su dispositivo móvil y se utilizan para mejorar los Servicios. Un identificador
        de dispositivo pueden ser datos almacenados en relación con el hardware del dispositivo, datos almacenados en
        relación con el sistema operativo del dispositivo u otro software, o datos enviados al dispositivo por nosotros.
        Un identificador de dispositivo puede transmitirnos información sobre cómo utiliza usted los Servicios. Un
        identificador de dispositivo no recoge ni comparte ninguna información personal identificable sobre usted. Un
        identificador de dispositivo puede utilizarse junto con información de identificación personal. Un identificador
        de dispositivo puede permanecer de forma persistente en su dispositivo, para ayudarle a iniciar sesión más
        rápidamente y mejorar su navegación a través de los Servicios. Algunas características de los Servicios pueden
        no funcionar correctamente si el uso o la disponibilidad de los identificadores de dispositivo se ven afectados
        o desactivados. LIDZ puede acceder, recopilar y/o almacenar identificadores de dispositivo al habilitar los
        Servicios de LIDZ.
      </Typography>

      <Typography variant="h6" align="justify">
        HERRAMIENTAS DE TERCEROS
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Nos preocupamos por la privacidad de nuestros usuarios. Recopilamos, utilizamos y compartimos información de
        identificación personal e información de identificación no personal como se describe en nuestra Política de
        Privacidad. Usted acepta que al utilizar los Servicios, acepta la recopilación de dicha información, y que sus
        datos personales sean recopilados, utilizados, transferidos y procesados.
      </Typography>

      <Typography variant="h6" align="justify">
        SU USO
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Mostraremos su Información Personal en su página de perfil y en otras partes de los Servicios de acuerdo con las
        preferencias que establezca en su cuenta. Cualquier información que elija proporcionar debe reflejar cuánto
        desea que otros sepan sobre usted. Por favor, considere cuidadosamente qué información desea que se revele en su
        página de perfil y su nivel deseado de anonimato. Puede solicitar en cualquier momento que se revise la
        información de su perfil. También podemos compartir o divulgar su información con su consentimiento, por ejemplo
        si utiliza una aplicación de terceros para acceder a su cuenta.
      </Typography>

      <Typography variant="h6" align="justify">
        TRANSFERENCIAS DE EMPRESAS
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        A medida que desarrollamos nuestro negocio, podemos comprar o vender activos u ofertas comerciales. La
        información sobre clientes, correos electrónicos y visitantes suele ser uno de los activos empresariales
        transferidos en este tipo de transacciones. También podemos transferir o ceder dicha información en el curso de
        desinversiones corporativas, fusiones o disoluciones. En tal caso, LIDZ adoptará medidas razonables para
        garantizar que la información se utilice de acuerdo con los términos aquí contenidos.
      </Typography>

      <Typography variant="h6" align="justify">
        CUMPLIMIENTO DE LAS LEYES Y SOLICITUDES DE APLICACIÓN DE LA LEY
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        LIDZ puede revelar su información personal si así lo requiere la ley o si lo consideramos razonable o necesario
        para cumplir con una ley, reglamento o solicitud legal; para proteger la seguridad de cualquier persona; para
        hacer frente al fraude, la seguridad o problemas técnicos; o para proteger los derechos o la propiedad de LIDZ o
        para evitar responsabilidades legales.
      </Typography>

      <Typography variant="h6" align="justify">
        INFORMACIÓN DE IDENTIFICACIÓN NO PERSONAL
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Podremos divulgar su información no privada, agregada o no identificable personalmente, como datos de uso
        anónimos, tipos de plataforma, etc., a terceros interesados para ayudarles a comprender los patrones de uso de
        ciertos Servicios LIDZ. No venderemos ni comercializaremos ninguna información de identificación personal a
        terceros para ningún fin, excepto los descritos expresamente en el presente documento.
      </Typography>

      <Typography variant="h6" align="justify">
        CÓMO PROTEGEMOS SU INFORMACIÓN
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        La seguridad de su información es importante para nosotros. Cuando usted introduce información sensible (como un
        número de tarjeta de crédito) como parte de nuestro servicio, encriptamos la transmisión de esa información
        utilizando tecnología de capa de conexión segura (SSL). LIDZ utiliza medidas de seguridad físicas,
        administrativas y técnicas comercialmente razonables para preservar la integridad y seguridad de su información
        personal, una vez que la recibimos. Por ejemplo, realizamos copias de seguridad de sus datos de forma continua y
        periódica para evitar la pérdida de datos y facilitar su recuperación. También nos protegemos contra los
        vectores de ataque habituales en la web, alojamos los datos en centros de datos seguros y aplicamos cortafuegos
        y restricciones de acceso a nuestros servidores para asegurar nuestra red y proteger mejor su información.
        Ningún método de transmisión o almacenamiento electrónico es seguro al 100%, por lo que no podemos asegurar ni
        garantizar la seguridad absoluta de la información que usted transmita o almacene en los Servicios. En el caso
        de que la información personal se vea comprometida como resultado de una violación de seguridad, LIDZ notificará
        de inmediato a las personas cuya información personal haya sido comprometida, de acuerdo con los procedimientos
        de notificación establecidos en esta Política de privacidad, o según lo exija la ley aplicable. El acceso a los
        datos personales está restringido a aquellos empleados, contratistas y agentes de LIDZ que necesitan que esos
        datos sean divulgados para cumplir con sus funciones y desarrollar o mejorar los servicios.
      </Typography>

      <Typography variant="h6" align="justify">
        SUS OPCIONES SOBRE SU INFORMACIÓN
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Por supuesto, puede negarse a enviar información de identificación personal a través del Servicio, en cuyo caso
        es posible que LIDZ no pueda brindar ciertos servicios. Puede actualizar o corregir la información de su cuenta
        en cualquier momento enviándonosla y/o iniciando sesión en su cuenta, cualquiera que sea la opción disponible en
        el momento en que decida enviar.
      </Typography>

      <Typography variant="h6" align="justify">
        BLOG Y COMUNIDAD DE LIDZ
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Nuestro Servicio podría ofrecer servicios comunitarios de acceso público, incluidos blogs y foros. En tal caso,
        debe tener en cuenta que cualquier información que proporcione en estas áreas podría ser leída, recopilada y
        utilizada por otros. Sus publicaciones pueden permanecer incluso después de cancelar su cuenta.
      </Typography>

      <Typography variant="h6" align="justify">
        PRIVACIDAD DE LOS NIÑOS
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Proteger la privacidad de los niños pequeños es especialmente importante. Nuestros Servicios no están dirigidos
        a niños menores de 13 años. LIDZ no recopila ni solicita a sabiendas información personal de ninguna persona
        menor de 13 años ni permite intencionalmente que dichas personas se registren en los Servicios. Si nos damos
        cuenta de que hemos recopilado, por error, información personal de un niño menor de 13 años sin verificación del
        consentimiento de los padres, tomaremos las medidas necesarias para eliminar esa información. Si cree que
        podríamos tener información de un niño menor de 13 años o sobre él, comuníquese con nosotros.
      </Typography>

      <Typography variant="h6" align="justify">
        ENLACES A OTROS SITIOS WEB
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        No somos responsables de las prácticas empleadas por los sitios web vinculados hacia o desde los Servicios, ni
        de la información o el contenido que contienen. LIDZ utiliza herramientas analíticas de terceros para ayudar a
        comprender el uso de los Servicios. Muchas de estas herramientas recopilan la información enviada por su
        navegador como parte de una solicitud de página web, incluidas las cookies y su dirección IP. Por favor recuerde
        que cuando utiliza un enlace para ir de los Servicios a otro sitio web, nuestra Política de privacidad ya no
        está vigente. Su navegación e interacción en cualquier otro sitio web, incluidos aquellos que tienen un enlace
        en nuestro sitio web, están sujetos a las reglas y políticas propias de ese sitio web. Recomendamos, alentamos y
        apoyamos enfáticamente a los usuarios para que se informen sobre dichas reglas y políticas. El usuario debe
        familiarizarse con las prácticas de privacidad de cualquier sitio web que visite para comprender qué información
        recopila el sitio, cómo se usa la información, qué opciones tiene el usuario, cómo acceder a la información y
        cómo se protege.
      </Typography>

      <Typography variant="h6" align="justify">
        CAMBIOS A NUESTRA POLÍTICA DE PRIVACIDAD
      </Typography>
      <Typography variant="subtitle1" pb="15px" align="justify">
        FECHA DE ENTRADA EN VIGOR: ENERO DE 2024
      </Typography>
      <Typography variant="body1" align="justify" pb="25px">
        Si cambiamos nuestra Política de privacidad, publicaremos esos cambios en esta página para mantenerlo informado
        sobre qué información recopilamos, cómo la usamos y en qué circunstancias podemos divulgarla. Los cambios a esta
        Política de privacidad entran en vigencia a partir del momento en que se publican en esta página. Por favor
        contáctenos a hello@lidz.ai con cualquier pregunta sobre esta Política de Privacidad. Esta Política de
        privacidad se actualizó por última vez el: 18 de enero de 2024.
      </Typography>
    </Box>
  );
};

export default PrivacyPolicy;
