import baseApi from './baseApi';

const prospectsApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    createProspect: build.mutation({
      query: (body: { email: string; reason: string }) => ({
        url: '/prospects',
        method: 'POST',
        body,
      }),
      transformResponse: (response: unknown): unknown => response,
    }),
    createMockPortalInmobiliarioProspect: build.mutation({
      query: (body: { phone: string }) => ({
        url: '/prospects/portal-inmobiliario',
        method: 'POST',
        body: {
          id: 'mock',
          lead: {
            email: 'test@lidz.ai',
            first_name: 'Test',
            last_name: 'Lead',
            phone: body.phone,
            rut: '11.111.111-1',
          },
          project: {
            name: '',
            project_id: 78,
          },
        },
      }),
      transformResponse: (response: unknown): unknown => response,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useCreateProspectMutation, useCreateMockPortalInmobiliarioProspectMutation } = prospectsApi;
