import React, { FC, useState } from 'react';

import { Box, Button } from '@mui/material';

import AttributeComponent from 'components/common/AttributesComponent';
import type { Attribute } from 'components/common/AttributesComponent';
import SectionHeader from 'components/layout/SectionHeader';
import { useUpdatePropertyMutation, useCreatePropertyMutation } from 'services/api/adminProperties.api';
import { dateFormat } from 'services/format/dateFormat';
import { Property, PropertyDraft } from 'services/types/property';

interface Props {
  property?: Property;
  backButton: React.ReactNode;
  refreshButton: React.ReactNode;
  isNewProperty?: boolean;
}

const PropertyAttributeComponent = AttributeComponent<PropertyDraft>;

const PropertyAttributes: FC<Props> = ({ property, backButton, refreshButton, isNewProperty }) => {
  const [updatePropertyAttributes, { isLoading }] = useUpdatePropertyMutation();
  const [createProperty, { isLoading: isLoadingCreate }] = useCreatePropertyMutation();

  const [valuesMapping, setValuesMapping] = useState<PropertyDraft>({});

  const handleSave = () => {
    if (!isNewProperty && property) {
      updatePropertyAttributes({ id: property.id, body: valuesMapping })
        .then((value) => {
          if ('error' in value) {
            alert('Ocurrió un error al actualizar los atributos del propiedad.'); // eslint-disable-line no-alert
            return;
          }
          alert('Los atributos de la propiedad se actualizaron correctamente. Debes actualizar la propiedad'); // eslint-disable-line no-alert
        })
        .catch(() => {
          alert('Ocurrió un error al actualizar los atributos de la propiedad'); // eslint-disable-line no-alert
        });
    }
    if (isNewProperty) {
      createProperty({ body: valuesMapping })
        .then((value) => {
          if ('error' in value) {
            alert(`Ocurrió un error al crear la propiedad. ${JSON.stringify(value.error)}`); // eslint-disable-line no-alert
            return;
          }
          alert('La propiedad se creó correctamente. Debes actualizar la propiedad'); // eslint-disable-line no-alert
        })
        .catch(() => {
          alert('Ocurrió un error al crear la propiedad'); // eslint-disable-line no-alert
        });
    }
  };

  const attributes: Attribute<PropertyDraft>[] = [
    { name: 'Identificador', value: property?.id ?? undefined },
    { name: 'Fecha de creación', value: property?.createdAt ? dateFormat(new Date(property?.createdAt)) : undefined },
    {
      name: 'Fecha de actualización',
      value: property?.updatedAt ? dateFormat(new Date(property?.updatedAt)) : undefined,
    },
    { name: 'projectId', value: property?.projectId, editableColumn: 'projectId', isNumber: true },
    { name: 'subprojectId', value: property?.subprojectId, editableColumn: 'subprojectId', isNumber: true },
    { name: 'projectReferenceSKU', value: property?.projectReferenceSKU, editableColumn: 'projectReferenceSKU' },
    { name: 'address', value: property?.address, editableColumn: 'address' },
    { name: 'commune', value: property?.commune, editableColumn: 'commune' },
    { name: 'floor', value: property?.floor, editableColumn: 'floor', isNumber: true },
    { name: 'latitude', value: property?.latitude, editableColumn: 'latitude', isNumber: true },
    { name: 'longitude', value: property?.longitude, editableColumn: 'longitude', isNumber: true },
    { name: 'bedrooms', value: property?.bedrooms, editableColumn: 'bedrooms', isNumber: true },
    { name: 'bathrooms', value: property?.bathrooms, editableColumn: 'bathrooms', isNumber: true },
    { name: 'parkingLotReference', value: property?.parkingLotReference, editableColumn: 'parkingLotReference' },
    { name: 'storageReference', value: property?.storageReference, editableColumn: 'storageReference' },
    { name: 'year', value: property?.year, editableColumn: 'year', isNumber: true },
    { name: 'price', value: property?.price, editableColumn: 'price', isNumber: true, description: 'UF' },
    { name: 'parkingLotPrice', value: property?.parkingLotPrice, editableColumn: 'parkingLotPrice', isNumber: true },
    { name: 'storagePrice', value: property?.storagePrice, editableColumn: 'storagePrice', isNumber: true },
    { name: 'totalPrice', value: property?.totalPrice, editableColumn: 'totalPrice', isNumber: true },
    { name: 'rentPrice', value: property?.rentPrice, editableColumn: 'rentPrice', isNumber: true, description: 'CLP' },
    {
      name: 'yearExpenses',
      value: property?.yearExpenses,
      editableColumn: 'yearExpenses',
      isNumber: true,
      description: 'CLP',
    },
    {
      name: 'interiorSize',
      value: property?.interiorSize,
      editableColumn: 'interiorSize',
      isNumber: true,
      description: 'm²',
    },
    {
      name: 'terraceSize',
      value: property?.terraceSize,
      editableColumn: 'terraceSize',
      isNumber: true,
      description: 'm²',
    },
    { name: 'builder', value: property?.builder, editableColumn: 'builder' },
    { name: 'orientation', value: property?.orientation, editableColumn: 'orientation' },
    { name: 'description', value: property?.description, editableColumn: 'description' },
    { name: 'delivery', value: property?.delivery, editableColumn: 'delivery' },
    { name: 'capRate', value: property?.capRate, editableColumn: 'capRate', isNumber: true },
    { name: 'subwayDistance', value: property?.subwayDistance, editableColumn: 'subwayDistance', isNumber: true },
    { name: 'closestSubway', value: property?.closestSubway, editableColumn: 'closestSubway' },
    {
      name: 'discountPercentage',
      value: property?.discountPercentage,
      editableColumn: 'discountPercentage',
      isNumber: true,
    },
    {
      name: 'downPaymentBonusPercentage',
      value: property?.downPaymentBonusPercentage,
      editableColumn: 'downPaymentBonusPercentage',
      isNumber: true,
    },
    {
      name: 'downPaymentInstallments',
      value: property?.downPaymentInstallments,
      editableColumn: 'downPaymentInstallments',
      isNumber: true,
    },
    {
      name: 'has15PercentagePromotion',
      value: property?.has15PercentagePromotion,
      editableColumn: 'has15PercentagePromotion',
      isBoolean: true,
    },
    {
      name: 'available',
      value: property?.available,
      editableColumn: 'available',
      isBoolean: true,
    },
    {
      name: 'reservationAmount',
      value: property?.reservationAmount,
      editableColumn: 'reservationAmount',
      isNumber: true,
    },
    { name: 'url', value: property?.url, editableColumn: 'url', isMultiline: true },
    {
      name: 'contributions',
      description: 'Monto contribuciones, cada 3 meses',
      value: property?.contributions,
      editableColumn: 'contributions',
      isNumber: true,
    },
    {
      name: 'monthlyExpenses',
      description: 'CLP',
      value: property?.monthlyExpenses,
      editableColumn: 'monthlyExpenses',
      isNumber: true,
    },
    {
      name: 'closePlaces',
      description: 'Ej: lider (450m), Inacap (800m)',
      value: property?.closePlaces,
      editableColumn: 'closePlaces',
      isMultiline: true,
    },
    { name: 'Imágenes', value: `${Object.keys(property?.images ?? {}).length} imágenes. Devs saben editarlas` },
  ];

  return (
    <Box>
      <SectionHeader
        text="Propiedad"
        button={
          <div style={{ display: 'flex', flexDirection: 'row' }}>
            {backButton}
            {refreshButton}
          </div>
        }
      />
      <Box
        flexDirection="row"
        display="flex"
        sx={{
          maxHeight: { md: 'calc(100vh - 180px)' },
          backgroundColor: 'background.paper',
          overflowY: 'auto',
          p: 4,
          borderRadius: 4,
        }}
      >
        <Box width="100%">
          {attributes
            .filter((a) => a.show !== false)
            .map((attribute) => (
              <PropertyAttributeComponent
                key={attribute.name}
                isEditing
                attribute={attribute}
                valuesMapping={valuesMapping}
                setValue={(value: string | number | boolean) => {
                  setValuesMapping({
                    ...valuesMapping,
                    [attribute.editableColumn ?? '']: value,
                  });
                }}
              />
            ))}
        </Box>
      </Box>
      <Button
        onClick={handleSave}
        sx={{
          width: 100,
          height: 30,
          alignSelf: 'flex-start',
          border: '1px solid #1C1C3A',
          borderRadius: '20px',
          mt: 1,
        }}
        disabled={isLoading || isLoadingCreate}
      >
        Guardar
      </Button>
    </Box>
  );
};

PropertyAttributes.defaultProps = {
  property: undefined,
  isNewProperty: false,
};

export default PropertyAttributes;
