import { Client } from 'services/types/client';

import { OpenAIMessage, OpenAIFunction } from '../types/message';
import baseApi from './baseApi';

const playgroundApi = baseApi.injectEndpoints({
  endpoints: (build) => ({
    generateRequest: build.mutation({
      query: (body: {
        conversation: OpenAIMessage[];
        functions: OpenAIFunction[];
        prompt: string;
        model?: string;
      }) => ({
        url: 'admin/playground/request',
        method: 'POST',
        body,
      }),
      transformResponse: (response: OpenAIMessage): OpenAIMessage => response,
    }),
    generatePrompt: build.mutation({
      query: (body: {
        prompt: string;
        commonPrompt: string;
        subprojectsDescriptions: { id: number; description: string; name: string }[];
        client: Client;
      }) => ({
        url: 'admin/playground/prompt-generate',
        method: 'POST',
        body,
      }),
      transformResponse: (response: { generatedPrompt: string }): string => response.generatedPrompt,
    }),
  }),
  overrideExisting: false,
});

// eslint-disable-next-line import/prefer-default-export
export const { useGenerateRequestMutation, useGeneratePromptMutation } = playgroundApi;
