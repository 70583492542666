import { useSelector } from 'react-redux';

import { RootState } from 'redux/store';
import { User } from 'redux/types/user';

const useCurrentUser = (): User | null => {
  const user = useSelector<RootState, RootState['user']>((state) => state.user);
  // return { ...user, isAdmin: false }; // Use this to mock a regular user
  return user;
};

export default useCurrentUser;
