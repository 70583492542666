import React, { FC } from 'react';

import { Box } from '@mui/material';

const PromptHelperText: FC = () => (
  <Box>
    Dentro del prompt se pueden usar las siguientes variables:
    <br />
    <b>{'<COMMON_PROMPT>'}</b>: Prompt común de la Inmobiliaria
    <br />
    <b>{'<CLIENT>'}</b>: Información del cliente
    <br />
    <b>{'<CLIENT:attribute>'}</b>: Incluir un atributo del cliente. (Ej: {'<CLIENT:Rut>'})
    <br />
    <b>{'<SUB_PROJECTS>'}</b>: Información de los proyectos de la Inmobiliaria
    <br />
    <b>{'<CURRENT_DATE>'}</b>: Fecha actual
    <br />
    <b>{'<UF_PRICE>'}</b>: Valor actual de la UF
    <br />
    <b>{'<IF:attr>Texto</IF:attr>'}</b>: Incluye el &quot;Texto&quot; solo si existe el atributo
    <br />
    <b>{'<IF:!attr>Texto</IF:!attr>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo no es válido, es falso o es
    cero.
    <br />
    <b>{'<IF:attr_operador_valor>Texto</IF:attr_operador_valor>'}</b>: Incluye el &quot;Texto&quot; solo si el atributo
    cumple la comparación.
    <br />
    <Box padding="20px">
      En <b>attr</b> puede ir <i>rut</i>, <i>email</i>, <i>salary</i>, <i>savingsAmount</i>, etc
      <br />
      En <b>operador</b> puede ir <i>=</i> (igual), <i>!=</i> (distinto), <i>&gt;</i> (mayor), <i>&lt;</i>, <i>&gt;=</i>
      , <i>&lt;=</i>, <i>startsWith</i>, <i>endsWith</i>, <i>contains</i>
      <br />
      En <b>valor</b> puede ir un número, un string, un true (Si) o un false (No)
      <br />
      Ejemplos:
      <ul>
        <li>
          {'<IF:email_contains_@besalco>Este es un empleado de Besalco, trátalo bien</IF:email_contains_@besalco>'}
        </li>
        <li>{'<IF:messagesCount_>_4><IF:!rut>Debes pedir el rut</IF:!rut></IF:messagesCount_>_4>'}</li>
        <li>
          {
            '<IF:nationality><IF:nationality_!=_CHILENA><IF:!rut>Pregunta si tiene residencia definitiva</IF:nationality_!=_CHILENA></IF:nationality>'
          }
        </li>
        <li>
          {
            '<IF:clientMessagesCount_>_5>Es un cliente interesado, guíalo a visitar el proyecto</IF:clientMessagesCount_>_5>'
          }
        </li>
        <li>{'<IF:imagesSent_=_0>Pregúntale si quiere fotos</IF:imagesSent_=_0>'}</li>
        <li>{'<IF:imagesSent_>_0>Pregúntale si quiere más fotos</IF:imagesSent_>_0>'}</li>
        <li>
          {'<IF:buyerType_contains_invertir>Dile el cap rate de cada departamento</IF:buyerType_contains_invertir>'}
        </li>
        <li>{'<IF:floidRequestsCount_>_0>Tiene 1+ floid requests</IF:floidRequestsCount_>_0>'}</li>
        <li>{'<IF:totalSalary_>_1000000>Gana más de 1 millón (complementando renta)</IF:totalSalary_>_1000000>'}</li>
      </ul>
      En attr se puede poner uno de los siguientes atributos (debe ser exacto, considerando las mayúsculas y minúsculas,
      no lo ocupes si no sabes qué significa):
      <ul>
        <li>email</li>
        <li>rut</li>
        <li>phone</li>
        <li>firstName</li>
        <li>lastName</li>
        <li>motherLastName</li>
        <li>savingsAmount</li>
        <li>notes</li>
        <li>adminNotes</li>
        <li>dateOfBirth</li>
        <li>gender</li>
        <li>nationality</li>
        <li>maritalStatus</li>
        <li>messages</li>
        <li>salary</li>
        <li>salarySupplement</li>
        <li>totalSalary</li>
        <li>score</li>
        <li>scope</li>
        <li>employmentContractType</li>
        <li>employmentContractStartDate</li>
        <li>debts</li>
        <li>projectId</li>
        <li>lastMessageDate</li>
        <li>quote</li>
        <li>messagesCount</li>
        <li>clientMessagesCount</li>
        <li>imagesSent</li>
        <li>followUpsSent</li>
        <li>templatesSent</li>
        <li>keepAliveMessagesSent</li>
        <li>floidRequestsCount</li>
        <li>floidRequestsDetails</li>
        <li>isTestClient</li>
        <li>disabledAutomaticResponses</li>
        <li>source</li>
        <li>status</li>
        <li>userScore</li>
        <li>subProjectId</li>
        <li>financialScore</li>
        <li>interestScore</li>
        <li>manualScore</li>
        <li>prospectMessage</li>
        <li>buyerType</li>
        <li>aiSafetyStatus</li>
        <li>charge</li>
      </ul>
      <a
        href="https://www.loom.com/share/c9e2f8008df44b82bf04eedf9ff95bd8?sid=f4404379-26c6-4421-8693-68f75a62b08e"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 1
      </a>
      <br />
      <a
        href="https://www.loom.com/share/8ca6921e97c74631bcee788ad8d1ca6c?sid=d04e35a1-3c09-45c1-a482-6fde8cda3397"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 2
      </a>
      <br />
      <a
        href="https://www.loom.com/share/a2df438864f042d3a99d7c5518fd0b63?sid=4ab69b31-573e-42ee-8eb7-d8ae7d37442a"
        target="_blank"
        rel="noreferrer"
      >
        Video instrucciones 3
      </a>
    </Box>
  </Box>
);

export default PromptHelperText;
