import React, { useEffect } from 'react';
import { useNavigate, useParams } from 'react-router-dom';

import { Box, Button } from '@mui/material';

import { useCreateDemoChatClientMutation } from 'services/api/demoChat';

import ChatWrapper from './ChatWrapper';

const NewDemoChat: React.FC = () => {
  const { companyIdentifier } = useParams<{ companyIdentifier: string }>();
  const localStorageKey = `lidz-pusher-demo-${companyIdentifier}`;
  const clientPhoneIdentifier = localStorage.getItem(localStorageKey);
  const [createDemoChatClient, { data: createClientData }] = useCreateDemoChatClientMutation();
  const navigate = useNavigate();
  if (!companyIdentifier) {
    navigate('/demochat'); // Error page
    return null;
  }

  const startChat = () => {
    createDemoChatClient({ companyIdentifier }).catch((error) => {
      // eslint-disable-next-line no-console
      console.error('Error creating demo chat client', error);
    });
  };

  const navigateToChat = () => {
    navigate(`/demochat/${companyIdentifier}`);
  };

  useEffect(() => {
    if (createClientData) {
      localStorage.setItem(localStorageKey, createClientData.id);
      navigateToChat();
    }
  }, [createClientData]);

  return (
    <ChatWrapper companyIdentifier={companyIdentifier}>
      <Box
        sx={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-evenly',
          height: '100%',
          width: '100%',
          alignItems: 'center',
        }}
      >
        <Button variant="contained" color="primary" onClick={startChat}>
          Iniciar Chat
        </Button>

        {clientPhoneIdentifier && (
          <Button variant="contained" color="primary" onClick={navigateToChat}>
            Continuar chat
          </Button>
        )}
      </Box>
    </ChatWrapper>
  );
};

export default NewDemoChat;
