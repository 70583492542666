import React, { FC, useEffect, useState } from 'react';

import { Box, LinearProgress, Paper, TextField } from '@mui/material';

import LogoClaveUnica from 'assets/images/clave_unica.svg';
import useQuery from 'hooks/useQuery';
import { format } from 'rut.js';
import { useClaveUnicaWhatsAppMutation } from 'services/api/claveUnicaWhatsApp.api';

import styles from '../../components/common/ClaveUnicaModal/ClaveUnicaModal.module.scss';

const INITIAL_MESSAGES = ['Puede tomar unos segundos', 'Estamos validando tu rut', 'Estamos validando tu clave única'];
const ROTATING_MESSAGES = [
  'Estamos buscando información de tus deudas de los bancos a través de la CMF (Comisión para el Mercado Financiero)',
  'Deudas de casas comerciales y cajas de compensación no aparecen aquí porque no reportan a la CMF',
  'Solo podemos ir a buscar la información general de tus deudas, después puedes agregar los detalles de cada una',
];

const ClaveUnicaWhatsApp: FC = () => {
  const query = useQuery();
  const rut = format(query.get('rut') || '');
  const clientId = query.get('clientId') || '';
  const [claveUnica, setClaveUnica] = useState<string>('');
  const [messageIndex, setMessageIndex] = useState<number>(0);
  const [errorMessage, setErrorMessage] = useState<string>('');
  const [sendClaveUnicaWhatsApp, { isLoading, error, data }] = useClaveUnicaWhatsAppMutation();

  useEffect(() => {
    if (error) {
      const parsedError = error as { data: string };
      setErrorMessage(parsedError.data || 'Error al obtener datos de Clave Única. Intenta nuevamente');
      setMessageIndex(0);
    }
  }, [error]);

  useEffect(() => {
    if (data && data.projectPhone) {
      const redirectUrl = navigator.userAgent.match(/Android|iPhone|iPad|iPod/i)
        ? `whatsapp://send?phone=+${data.projectPhone}`
        : `https://web.whatsapp.com/send?phone=+${data.projectPhone}`;
      window.location.href = redirectUrl;
    }
  }, [data]);

  useEffect(() => {
    if (!isLoading) {
      return () => {};
    }
    const interval = setInterval(() => {
      setMessageIndex(messageIndex + 1);
    }, 10000);
    return () => clearInterval(interval);
  }, [messageIndex, setMessageIndex, isLoading]);

  return (
    <Box sx={{ width: '100vw', display: 'flex', justifyContent: 'center', alignItems: 'center', mt: { xs: 0, lg: 4 } }}>
      <Paper className={styles.ClaveUnicaContainer}>
        <div className={styles.ClaveUnicaBorder}>
          <div className={styles.ClaveUnicaHeader}>
            {/* header */}
            <div className={styles.HeaderLine}>
              <span className={styles.HeaderBlue} />
              <span className={styles.HeaderRed} />
            </div>
            <img src={LogoClaveUnica} alt="ClaveÚnica" />
            <div className={styles.HeaderTitleContainer}>
              <h2 className={styles.HeaderTitle}>Validación de información financiera</h2>
            </div>
          </div>
          <div className={styles.InputContainer}>
            <div className={styles.ClaveUnicaInput}>
              <TextField value={rut} disabled fullWidth label="Rut" />
            </div>
            <div className={styles.ClaveUnicaInput}>
              <TextField
                type="password"
                value={claveUnica}
                autoFocus
                onChange={(e) => setClaveUnica(e.target.value)}
                fullWidth
                label="Ingresa tu Clave Única"
                helperText={errorMessage}
                error={!!errorMessage}
              />
            </div>
            <a
              className={styles.ClaveUnicaRecoveryLink}
              href="https://claveunica.gob.cl/recuperar"
              target="_blank"
              rel="noreferrer"
            >
              ¿Olvidaste tu Clave Única?
            </a>
            <div className={styles.SaveButtonContainer}>
              <div>
                <button
                  type="submit"
                  className={styles.SaveButton}
                  disabled={!claveUnica || isLoading}
                  onClick={() => {
                    sendClaveUnicaWhatsApp({ clientId: Number(clientId), claveUnica }).catch((err) => {
                      // eslint-disable-next-line no-console
                      console.error(err);
                    });
                  }}
                >
                  Continuar
                </button>
              </div>
            </div>
            <div style={{ display: isLoading ? 'block' : 'none' }}>
              <LinearProgress color="primary" />
              <p>
                {messageIndex >= INITIAL_MESSAGES.length
                  ? ROTATING_MESSAGES[(messageIndex - INITIAL_MESSAGES.length) % ROTATING_MESSAGES.length]
                  : INITIAL_MESSAGES[messageIndex]}
              </p>
            </div>
          </div>
          <Box display="flex" flexDirection="row" justifyContent="space-between" p={1}>
            <a href="https://claveunica.gob.cl" target="_blank" className={styles.HelpLink} rel="noreferrer">
              ¿No tienes Clave&nbsp;Única?
            </a>
            <a
              href="tel:600 360 33 03"
              className={styles.HelpLink}
              target="_blank"
              rel="noreferrer"
              style={{ textAlign: 'right' }}
            >
              Ayuda al 600&nbsp;360&nbsp;33&nbsp;03
            </a>
          </Box>
        </div>
      </Paper>
    </Box>
  );
};

export default ClaveUnicaWhatsApp;
